import { useEffect } from 'react'
import { useIsFetching } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { UseDeclarationFormReturn } from '../../form'
import useDocumentApi from './api'
import {
  DocumentRequestWithFiles,
  parsePreviousDocumentResponse,
  parseSupportingDocumentResponse,
  parseTransportDocumentResponse,
  toConsignmentItemPreviousDocumentRequest,
  toConsignmentItemSupportingDocumentRequest,
  toConsignmentItemTransportDocumentRequest,
  toConsignmentPreviousDocumentRequest,
  toConsignmentSupportingDocumentRequest,
  toConsignmentTransportDocumentRequest,
  toFileRequest,
  toHouseConsignmentPreviousDocumentRequest,
  toHouseConsignmentSupportingDocumentRequest,
  toHouseConsignmentTransportDocumentRequest,
} from './mapper'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'
import {
  DocumentRequest, DocumentRequestTypeEnum, DocumentResponse, DocumentResponseTypeEnum, FileResponse,
} from '../../../common/models'
import { PreviousDocument, SupportingDocument, TransportDocument } from '../../form/schemas/documentSchemas'
import TransitApiConfig from '../apiConfig'
import { HouseConsignmentType } from '../../form/schemas/houseConsignmentSchema'
import { sortBySequenceNumber } from '../../services/useFieldArrayActionHelper'
import { excludeDeleted, hasText, isDeletedWithId } from '../../../common/utils/common-util'
import useFileApi, { FileFolder, SpecificFileRequest } from '../useFile/api'
import { ConsignmentItem } from '../../form/schemas/consignmentItemSchema'

type TransitDocument = TransportDocument | SupportingDocument | PreviousDocument

const {
  queryKeys: { rootPath },
} = TransitApiConfig.paths.consignmentItem

function useDocument(form: UseDeclarationFormReturn) {
  const {
    setValue,
    getValues,
    watch,
    formState: {
      isValid,
      isSubmitting,
    },
    reset,
    trigger,
  } = form

  const isFetching = useIsFetching({ queryKey: [rootPath] })
  const { t } = useTranslation()
  const transitOperationId: number | null = watch('id')
  const { postFile, deleteFile } = useFileApi()

  const {
    getDocuments: getTransportDocuments,
    postDocuments: postTransportDocuments,
    putDocuments: putTransportDocuments,
    deleteDocument: deleteTransportDocument,
  } = useDocumentApi(transitOperationId, DocumentRequestTypeEnum.TRANSPORT, isSubmitting)
  const {
    getDocuments: getPreviousDocuments,
    postDocuments: postPreviousDocuments,
    putDocuments: putPreviousDocuments,
    deleteDocument: deletePreviousDocument,
  } = useDocumentApi(transitOperationId, DocumentRequestTypeEnum.PREVIOUS, isSubmitting)
  const {
    getDocuments: getSupportingDocuments,
    postDocuments: postSupportingDocuments,
    putDocuments: putSupportingDocuments,
    deleteDocument: deleteSupportingDocument,
  } = useDocumentApi(transitOperationId, DocumentRequestTypeEnum.SUPPORTING, isSubmitting)

  // TODO convert to Array.prototype.groupToMap when supported by browsers?
  const groupBy = <T, K, E = T>(list: T[], getKey: (item: T) => K, apply?: (item: T, index: number) => E): Map<K, E[]> => {
    const map = new Map<K, E[]>()
    list.forEach((item, index) => {
      const key = getKey(item)
      if (!map.has(key)) {
        map.set(key, [])
      }
      map.get(key)?.push(apply ? apply(item, index) : item as unknown as E)
    })
    return map
  }

  const populateFormConsignmentItems = () => {
    if (getTransportDocuments.isFetching || getPreviousDocuments.isFetching || getSupportingDocuments.isFetching || isSubmitting) {
      return
    }

    const consignmentPreviousDocuments: PreviousDocument[] = []
    const consignmentTransportDocuments: TransportDocument[] = []
    const consignmentSupportingDocuments: SupportingDocument[] = []
    let housePreviousDocumentsById: Map<number, PreviousDocument[]> = new Map<number, PreviousDocument[]>()
    let houseTransportDocumentsById: Map<number, TransportDocument[]> = new Map<number, TransportDocument[]>()
    let houseSupportingDocumentsById: Map<number, SupportingDocument[]> = new Map<number, SupportingDocument[]>()
    let consignmentItemTransportDocuments: Map<number, TransportDocument[]> = new Map<number, TransportDocument[]>()
    let consignmentItemPreviousDocuments: Map<number, PreviousDocument[]> = new Map<number, PreviousDocument[]>()
    let consignmentItemSupportingDocuments: Map<number, SupportingDocument[]> = new Map<number, SupportingDocument[]>()

    if (getTransportDocuments.data?.length) {
      consignmentItemTransportDocuments = groupBy(
        getTransportDocuments.data.filter((document) => document.consignmentItemId !== null),
        (item) => item.consignmentItemId!,
        parseTransportDocumentResponse,
      )

      houseTransportDocumentsById = groupBy(
        getTransportDocuments.data.filter((document) => document.houseConsignmentId !== null),
        (document) => document.houseConsignmentId!,
        parseTransportDocumentResponse,
      )

      consignmentTransportDocuments.push(...getTransportDocuments.data
        .filter((document) => document.consignmentId !== null)
        .map(parseTransportDocumentResponse))
    }

    if (getPreviousDocuments.data?.length) {
      consignmentItemPreviousDocuments = groupBy(
        getPreviousDocuments.data.filter((document) => document.consignmentItemId !== null),
        (item) => item.consignmentItemId!,
        parsePreviousDocumentResponse,
      )

      housePreviousDocumentsById = groupBy(
        getPreviousDocuments.data.filter((document) => document.houseConsignmentId !== null),
        (document) => document.houseConsignmentId!,
        parsePreviousDocumentResponse,
      )

      consignmentPreviousDocuments.push(...getPreviousDocuments.data
        .filter((document) => document.consignmentId !== null)
        .map(parsePreviousDocumentResponse))
    }

    if (getSupportingDocuments.data?.length) {
      consignmentItemSupportingDocuments = groupBy(
        getSupportingDocuments.data.filter((document) => document.consignmentItemId !== null),
        (item) => item.consignmentItemId!,
        parseSupportingDocumentResponse,
      )

      houseSupportingDocumentsById = groupBy(
        getSupportingDocuments.data.filter((document) => document.houseConsignmentId !== null),
        (document) => document.houseConsignmentId!,
        parseSupportingDocumentResponse,
      )

      consignmentSupportingDocuments.push(...getSupportingDocuments.data
        .filter((document) => document.consignmentId !== null)
        .map(parseSupportingDocumentResponse))
    }

    const formClone: DeclarationForm = structuredClone(getValues())

    reset({
      ...formClone,
      previousDocument: consignmentPreviousDocuments.sort(sortBySequenceNumber),
      transportDocument: consignmentTransportDocuments.sort(sortBySequenceNumber),
      supportingDocument: consignmentSupportingDocuments.sort(sortBySequenceNumber),
      houseConsignment: formClone.houseConsignment.map((formHouseConsignment) => {
        const housePreviousDocuments = formHouseConsignment.id !== null ? housePreviousDocumentsById.get(formHouseConsignment.id) : []
        const houseTransportDocuments = formHouseConsignment.id !== null ? houseTransportDocumentsById.get(formHouseConsignment.id) : []
        const houseSupportingDocuments = formHouseConsignment.id !== null ? houseSupportingDocumentsById.get(formHouseConsignment.id) : []

        return {
          ...formHouseConsignment,
          previousDocument: housePreviousDocuments?.sort(sortBySequenceNumber) ?? [],
          transportDocument: houseTransportDocuments?.sort(sortBySequenceNumber) ?? [],
          supportingDocument: houseSupportingDocuments?.sort(sortBySequenceNumber) ?? [],
          consignmentItem: formHouseConsignment.consignmentItem
            .map((consignmentItem) => {
              const consignmentItemId = consignmentItem.id
              if (consignmentItemId === null) return consignmentItem

              const itemTransportDocuments = consignmentItemTransportDocuments.get(consignmentItemId)
              const itemPreviousDocuments = consignmentItemPreviousDocuments.get(consignmentItemId)
              const itemSupportingDocuments = consignmentItemSupportingDocuments.get(consignmentItemId)

              return ({
                ...consignmentItem,
                transportDocument: itemTransportDocuments?.sort(sortBySequenceNumber) ?? [],
                previousDocument: itemPreviousDocuments?.sort(sortBySequenceNumber) ?? [],
                supportingDocument: itemSupportingDocuments?.sort(sortBySequenceNumber) ?? [],
              })
            }),
        }
      }),
    })
  }

  useEffect(() => {
    populateFormConsignmentItems()
  }, [getTransportDocuments.data, getPreviousDocuments.data, getSupportingDocuments.data, isFetching])

  function findItemByCriteria(
    response: DocumentResponse[],
    document: TransitDocument,
    type: DocumentResponseTypeEnum,
    criteria: (responseItem: DocumentResponse) => boolean,
  ) {
    return response
      .find((responseItem) => (
        responseItem.type === type && (
          (document.id === null && responseItem.sequenceNumber === document.sequenceNumber) || document.id === responseItem.id
        )) && criteria(responseItem))
  }

  function refreshConsignmentDocumentIds(consignmentId: number, response: DocumentResponse[]) {
    const previousDocuments = getValues('previousDocument')
    const transportDocuments = getValues('transportDocument')
    const supportingDocuments = getValues('supportingDocument')

    previousDocuments.forEach((previousDocument, previousDocumentIndex) => {
      const savedItem = findItemByCriteria(
        response,
        previousDocument,
        DocumentResponseTypeEnum.PREVIOUS,
        (documentResponse: DocumentResponse) => documentResponse.consignmentId === consignmentId,
      )
      if (savedItem) {
        setValue(`previousDocument.${previousDocumentIndex}.id`, savedItem.id)
      }
    })

    transportDocuments.forEach((transportDocument, transportDocumentIndex) => {
      const savedItem = findItemByCriteria(
        response,
        transportDocument,
        DocumentResponseTypeEnum.PREVIOUS,
        (documentResponse: DocumentResponse) => documentResponse.consignmentId === consignmentId,
      )
      if (savedItem) {
        setValue(`transportDocument.${transportDocumentIndex}.id`, savedItem.id)
      }
    })

    supportingDocuments.forEach((supportingDocument, previousDocumentIndex) => {
      const savedItem = findItemByCriteria(
        response,
        supportingDocument,
        DocumentResponseTypeEnum.PREVIOUS,
        (documentResponse: DocumentResponse) => documentResponse.consignmentId === consignmentId,
      )
      if (savedItem) {
        setValue(`previousDocument.${previousDocumentIndex}.id`, savedItem.id)
      }
    })
  }

  function refreshHouseConsignmentDocumentIds(
    houseConsignment: HouseConsignmentType,
    response: DocumentResponse[],
    houseConsignmentIndex: number,
  ) {
    houseConsignment.transportDocument.forEach((transportDocument, transportDocumentIndex) => {
      const savedItem = findItemByCriteria(
        response,
        transportDocument,
        DocumentResponseTypeEnum.TRANSPORT,
        (documentResponse: DocumentResponse) => documentResponse.houseConsignmentId === houseConsignment.id,
      )
      if (savedItem) {
        setValue(`houseConsignment.${houseConsignmentIndex}.transportDocument.${transportDocumentIndex}.id`, savedItem.id)
      }
    })

    houseConsignment.previousDocument.forEach((previousDocument, documentIndex) => {
      const savedItem = findItemByCriteria(
        response,
        previousDocument,
        DocumentResponseTypeEnum.PREVIOUS,
        (documentResponse: DocumentResponse) => documentResponse.houseConsignmentId === houseConsignment.id,
      )
      if (savedItem) {
        setValue(`houseConsignment.${houseConsignmentIndex}.previousDocument.${documentIndex}.id`, savedItem.id)
      }
    })

    houseConsignment.supportingDocument.forEach((previousDocument, documentIndex) => {
      const savedItem = findItemByCriteria(
        response,
        previousDocument,
        DocumentResponseTypeEnum.SUPPORTING,
        (documentResponse: DocumentResponse) => documentResponse.houseConsignmentId === houseConsignment.id,
      )
      if (savedItem) {
        setValue(`houseConsignment.${houseConsignmentIndex}.supportingDocument.${documentIndex}.id`, savedItem.id)
      }
    })
  }

  function refreshConsignmentItemDocumentIds(
    consignmentItem: ConsignmentItem,
    response: DocumentResponse[],
    houseConsignmentIndex: number,
    consignmentItemIndex: number,
  ) {
    consignmentItem.transportDocument.forEach((transportDocument, transportDocumentIndex) => {
      const savedItem = findItemByCriteria(
        response,
        transportDocument,
        DocumentResponseTypeEnum.TRANSPORT,
        (documentResponse: DocumentResponse) => documentResponse.consignmentItemId === consignmentItem.id,
      )
      if (savedItem) {
        setValue(`houseConsignment.${houseConsignmentIndex}.consignmentItem.${
          consignmentItemIndex}.transportDocument.${transportDocumentIndex}.id`, savedItem.id)
      }
    })
    consignmentItem.previousDocument.forEach((previousDocument, previousDocumentIndex) => {
      const savedItem = findItemByCriteria(
        response,
        previousDocument,
        DocumentResponseTypeEnum.PREVIOUS,
        (documentResponse: DocumentResponse) => documentResponse.consignmentItemId === consignmentItem.id,
      )
      if (savedItem) {
        setValue(`houseConsignment.${houseConsignmentIndex}.consignmentItem.${
          consignmentItemIndex}.previousDocument.${previousDocumentIndex}.id`, savedItem.id)
      }
    })
    consignmentItem.supportingDocument.forEach((supportingDocument, supportingDocumentIndex) => {
      const savedItem = findItemByCriteria(
        response,
        supportingDocument,
        DocumentResponseTypeEnum.SUPPORTING,
        (documentResponse: DocumentResponse) => documentResponse.consignmentItemId === consignmentItem.id,
      )
      if (savedItem) {
        setValue(`houseConsignment.${houseConsignmentIndex}.consignmentItem.${
          consignmentItemIndex}.supportingDocument.${supportingDocumentIndex}.id`, savedItem.id)
      }
    })
  }

  function refreshSavedIds(consignmentId: number | null, houseConsignments: HouseConsignmentType[], response: DocumentResponse[]) {
    if (consignmentId !== null) {
      refreshConsignmentDocumentIds(consignmentId, response)
    }

    houseConsignments.forEach((houseConsignment, houseConsignmentIndex) => {
      refreshHouseConsignmentDocumentIds(houseConsignment, response, houseConsignmentIndex)
      houseConsignment.consignmentItem.forEach((consignmentItem, consignmentItemIndex) => {
        refreshConsignmentItemDocumentIds(consignmentItem, response, houseConsignmentIndex, consignmentItemIndex)
      })
    })
  }

  function findConsignmentItemDocumentByDocumentRequest(request: DocumentRequest): TransitDocument | undefined {
    return getValues('houseConsignment')
      .flatMap((houseConsignment) => houseConsignment.consignmentItem)
      .filter((consignmentItem) => consignmentItem.id === request.consignmentItemId)
      .flatMap((consignmentItem) => {
        switch (request.type) {
          case 'TRANSPORT':
            return consignmentItem.transportDocument
          case 'SUPPORTING':
            return consignmentItem.supportingDocument
          default:
            return []
        }
      })
      .find((document) => !document.deleted && (document.sequenceNumber === request.sequenceNumber))
  }

  function findHouseConsignmentDocumentByDocumentRequest(request: DocumentRequest): TransitDocument | undefined {
    return getValues('houseConsignment')
      .filter((houseConsignment) => houseConsignment.id === request.houseConsignmentId)
      .flatMap((houseConsignment) => {
        switch (request.type) {
          case 'TRANSPORT':
            return houseConsignment.transportDocument
          case 'SUPPORTING':
            return houseConsignment.supportingDocument
          default:
            return []
        }
      })
      .find((document) => !document.deleted && (document.sequenceNumber === request.sequenceNumber))
  }

  function findDocumentId(request: DocumentRequest) {
    let matchingDocument = findHouseConsignmentDocumentByDocumentRequest(request)
    if (matchingDocument === undefined) {
      matchingDocument = findConsignmentItemDocumentByDocumentRequest(request)
    }

    if (matchingDocument === undefined || matchingDocument.id === null) {
      return -1
    }

    return matchingDocument.id
  }

  function getConsignmentDocumentsRequests(transitId: number, consignmentId: number | null) {
    const createConsignmentDocumentRequests: DocumentRequestWithFiles[] = []
    const updateConsignmentDocumentRequests: DocumentRequestWithFiles[] = []

    const previousDocuments = getValues('previousDocument')
    const transportDocuments = getValues('transportDocument')
    const supportingDocuments = getValues('supportingDocument')

    previousDocuments.filter(excludeDeleted).forEach((previousDocument) => {
      if (previousDocument.id === null) {
        createConsignmentDocumentRequests.push(toConsignmentPreviousDocumentRequest(previousDocument, transitId, consignmentId))
      } else {
        updateConsignmentDocumentRequests.push(toConsignmentPreviousDocumentRequest(previousDocument, transitId, consignmentId))
      }
    })

    transportDocuments.filter(excludeDeleted).forEach((transportDocument) => {
      if (transportDocument.id === null) {
        createConsignmentDocumentRequests.push(toConsignmentTransportDocumentRequest(transportDocument, transitId, consignmentId))
      } else {
        updateConsignmentDocumentRequests.push(toConsignmentTransportDocumentRequest(transportDocument, transitId, consignmentId))
      }
    })

    supportingDocuments.filter(excludeDeleted).forEach((supportingDocument) => {
      if (supportingDocument.id === null) {
        createConsignmentDocumentRequests.push(toConsignmentSupportingDocumentRequest(supportingDocument, transitId, consignmentId))
      } else {
        updateConsignmentDocumentRequests.push(toConsignmentSupportingDocumentRequest(supportingDocument, transitId, consignmentId))
      }
    })

    return { createConsignmentDocumentRequests, updateConsignmentDocumentRequests }
  }

  function getConsignmentItemDocumentRequests(currentTransitOperationId: number, houseConsignments: HouseConsignmentType[]) {
    const createRequestsForConsignmentItem: DocumentRequestWithFiles[] = []
    const updateRequestsForConsignmentItem: DocumentRequestWithFiles[] = []

    houseConsignments
      .filter(excludeDeleted)
      .flatMap((house) => house.consignmentItem)
      .filter(excludeDeleted)
      .forEach((consignmentItem) => {
        const transportDocuments = groupBy(
          consignmentItem.transportDocument
            .filter(excludeDeleted),
          (item) => item.id,
          (item) => toConsignmentItemTransportDocumentRequest(item, currentTransitOperationId, consignmentItem.id),
        )
        const previousDocuments = groupBy(
          consignmentItem.previousDocument
            .filter(excludeDeleted),
          (item) => item.id,
          (item) => toConsignmentItemPreviousDocumentRequest(item, currentTransitOperationId, consignmentItem.id),
        )
        const supportingDocuments = groupBy(
          consignmentItem.supportingDocument
            .filter(excludeDeleted),
          (item) => item.id,
          (item) => toConsignmentItemSupportingDocumentRequest(item, currentTransitOperationId, consignmentItem.id),
        )

        transportDocuments.forEach((value, key) => {
          if (key === null) {
            createRequestsForConsignmentItem.push(...value)
          } else {
            updateRequestsForConsignmentItem.push(...value)
          }
        })
        previousDocuments.forEach(async (value, key) => {
          if (key === null) {
            createRequestsForConsignmentItem.push(...value)
          } else {
            updateRequestsForConsignmentItem.push(...value)
          }
        })
        supportingDocuments.forEach(async (value, key) => {
          if (key === null) {
            createRequestsForConsignmentItem.push(...value)
          } else {
            updateRequestsForConsignmentItem.push(...value)
          }
        })
      })
    return { createRequestsForConsignmentItem, updateRequestsForConsignmentItem }
  }

  function getHouseConsignmentDocumentRequests(currentTransitOperationId: number, houseConsignments: HouseConsignmentType[]) {
    const createRequestsForHouseConsignment: DocumentRequestWithFiles[] = []
    const updateRequestsForHouseConsignment: DocumentRequestWithFiles[] = []

    houseConsignments
      .filter(excludeDeleted)
      .forEach((houseConsignment) => {
        const transportDocuments = groupBy(
          houseConsignment.transportDocument.filter(excludeDeleted),
          (item) => item.id,
          (item) => toHouseConsignmentTransportDocumentRequest(item, currentTransitOperationId, houseConsignment.id),
        )
        const previousDocuments = groupBy(
          houseConsignment.previousDocument.filter(excludeDeleted),
          (item) => item.id,
          (item) => toHouseConsignmentPreviousDocumentRequest(item, currentTransitOperationId, houseConsignment.id),
        )
        const supportingDocuments = groupBy(
          houseConsignment.supportingDocument.filter(excludeDeleted),
          (item) => item.id,
          (item) => toHouseConsignmentSupportingDocumentRequest(item, currentTransitOperationId, houseConsignment.id),
        )

        transportDocuments.forEach((value, key) => {
          if (key === null) {
            createRequestsForHouseConsignment.push(...value)
          } else {
            updateRequestsForHouseConsignment.push(...value)
          }
        })
        previousDocuments.forEach(async (value, key) => {
          if (key === null) {
            createRequestsForHouseConsignment.push(...value)
          } else {
            updateRequestsForHouseConsignment.push(...value)
          }
        })
        supportingDocuments.forEach(async (value, key) => {
          if (key === null) {
            createRequestsForHouseConsignment.push(...value)
          } else {
            updateRequestsForHouseConsignment.push(...value)
          }
        })
      })

    return { createRequestsForHouseConsignment, updateRequestsForHouseConsignment }
  }

  const createOrUpdateDocuments = async (isDraft: boolean) => {
    await trigger()
    if (!isDraft && !isValid) return
    const currentTransitOperationId = getValues('id')
    if (currentTransitOperationId === null) throw Error('Missing required transit operation id for documents')

    const consignmentId = getValues('consignmentId')
    const houseConsignments = getValues('houseConsignment')

    const {
      createConsignmentDocumentRequests,
      updateConsignmentDocumentRequests,
    } = getConsignmentDocumentsRequests(currentTransitOperationId, consignmentId)

    const {
      createRequestsForConsignmentItem,
      updateRequestsForConsignmentItem,
    } = getConsignmentItemDocumentRequests(currentTransitOperationId, houseConsignments)

    const {
      createRequestsForHouseConsignment,
      updateRequestsForHouseConsignment,
    } = getHouseConsignmentDocumentRequests(currentTransitOperationId, houseConsignments)

    const asyncRequests: Array<Promise<DocumentResponse[]>> = []

    const newTransportDocumentRequests: DocumentRequestWithFiles[] = []
    const newPreviousDocumentRequests: DocumentRequestWithFiles[] = []
    const newSupportingDocumentRequests: DocumentRequestWithFiles[] = []
    const updatedTransportDocuments: DocumentRequestWithFiles[] = []
    const updatedPreviousDocuments: DocumentRequestWithFiles[] = []
    const updatedSupportingDocuments: DocumentRequestWithFiles[] = []

    newTransportDocumentRequests.push(...createConsignmentDocumentRequests
      .filter(({ request }) => request.type === DocumentRequestTypeEnum.TRANSPORT))
    newPreviousDocumentRequests.push(...createConsignmentDocumentRequests
      .filter(({ request }) => request.type === DocumentRequestTypeEnum.PREVIOUS))
    newSupportingDocumentRequests.push(...createConsignmentDocumentRequests
      .filter(({ request }) => request.type === DocumentRequestTypeEnum.SUPPORTING))
    newTransportDocumentRequests.push(...createRequestsForConsignmentItem
      .filter(({ request }) => request.type === DocumentRequestTypeEnum.TRANSPORT))
    newPreviousDocumentRequests.push(...createRequestsForConsignmentItem
      .filter(({ request }) => request.type === DocumentRequestTypeEnum.PREVIOUS))
    newSupportingDocumentRequests.push(...createRequestsForConsignmentItem
      .filter(({ request }) => request.type === DocumentRequestTypeEnum.SUPPORTING))
    newTransportDocumentRequests.push(...createRequestsForHouseConsignment
      .filter(({ request }) => request.type === DocumentRequestTypeEnum.TRANSPORT))
    newPreviousDocumentRequests.push(...createRequestsForHouseConsignment
      .filter(({ request }) => request.type === DocumentRequestTypeEnum.PREVIOUS))
    newSupportingDocumentRequests.push(...createRequestsForHouseConsignment
      .filter(({ request }) => request.type === DocumentRequestTypeEnum.SUPPORTING))

    if (newTransportDocumentRequests.length > 0) {
      asyncRequests.push(postTransportDocuments.mutateAsync(newTransportDocumentRequests.map(({ request }) => request)))
    }
    if (newSupportingDocumentRequests.length > 0) {
      asyncRequests.push(postSupportingDocuments.mutateAsync(newSupportingDocumentRequests.map(({ request }) => request)))
    }
    if (newPreviousDocumentRequests.length > 0) {
      asyncRequests.push(postPreviousDocuments.mutateAsync(newPreviousDocumentRequests.map(({ request }) => request)))
    }

    updatedTransportDocuments.push(...updateConsignmentDocumentRequests
      .filter(({ request }) => request.type === DocumentRequestTypeEnum.TRANSPORT))
    updatedPreviousDocuments.push(...updateConsignmentDocumentRequests
      .filter(({ request }) => request.type === DocumentRequestTypeEnum.PREVIOUS))
    updatedSupportingDocuments.push(...updateConsignmentDocumentRequests
      .filter(({ request }) => request.type === DocumentRequestTypeEnum.SUPPORTING))

    updatedTransportDocuments.push(...updateRequestsForConsignmentItem
      .filter(({ request }) => request.type === DocumentRequestTypeEnum.TRANSPORT))
    updatedPreviousDocuments.push(...updateRequestsForConsignmentItem
      .filter(({ request }) => request.type === DocumentRequestTypeEnum.PREVIOUS))
    updatedSupportingDocuments.push(...updateRequestsForConsignmentItem
      .filter(({ request }) => request.type === DocumentRequestTypeEnum.SUPPORTING))
    updatedTransportDocuments.push(...updateRequestsForHouseConsignment
      .filter(({ request }) => request.type === DocumentRequestTypeEnum.TRANSPORT))
    updatedPreviousDocuments.push(...updateRequestsForHouseConsignment
      .filter(({ request }) => request.type === DocumentRequestTypeEnum.PREVIOUS))
    updatedSupportingDocuments.push(...updateRequestsForHouseConsignment
      .filter(({ request }) => request.type === DocumentRequestTypeEnum.SUPPORTING))

    if (updatedTransportDocuments.length > 0) {
      asyncRequests.push(putTransportDocuments.mutateAsync(updatedTransportDocuments.map(({ request }) => request)))
    }
    if (updatedSupportingDocuments.length > 0) {
      asyncRequests.push(putSupportingDocuments.mutateAsync(updatedSupportingDocuments.map(({ request }) => request)))
    }
    if (updatedPreviousDocuments.length > 0) {
      asyncRequests.push(putPreviousDocuments.mutateAsync(updatedPreviousDocuments.map(({ request }) => request)))
    }

    const responses = await Promise.allSettled(asyncRequests)
    responses.forEach((response) => {
      if (response.status === 'fulfilled') {
        refreshSavedIds(consignmentId, houseConsignments, response.value)
      }
    })

    const asyncFileRequests: Array<Promise<FileResponse | void>> = []

    asyncFileRequests.push(...[...updatedTransportDocuments, ...updatedSupportingDocuments, ...updatedPreviousDocuments]
      .flatMap((documentWithFiles) => documentWithFiles.files)
      .filter((docFile) => !(docFile.deleted && docFile.id === null))
      .map((docFile) => {
        const { uuid, deleted } = docFile
        if (deleted && hasText(uuid)) {
          const splitUuid = uuid!.split('/')
          const request: SpecificFileRequest = {
            folder: splitUuid[0] as FileFolder,
            fileUuid: splitUuid[1],
          }
          return deleteFile.mutateAsync(request)
        }
        if (docFile.documentId !== null && docFile.id === null) {
          return postFile.mutateAsync(toFileRequest(docFile, docFile.documentId))
        }

        return Promise.resolve()
      }))

    asyncFileRequests.push(...[...newTransportDocumentRequests, ...newSupportingDocumentRequests, ...newPreviousDocumentRequests]
      .map((documentWithFiles) => documentWithFiles.files
        .filter((docFile) => !(docFile.deleted && docFile.id === null))
        .map((docFile) => {
          const {
            uuid,
            deleted,
          } = docFile
          if (deleted && hasText(uuid)) {
            const splitUuid = uuid!.split('/')
            const request: SpecificFileRequest = {
              folder: splitUuid[0] as FileFolder,
              fileUuid: splitUuid[1],
            }
            return deleteFile.mutateAsync(request)
          }
          if (docFile.documentId !== null) {
            return postFile.mutateAsync(toFileRequest(docFile, docFile.documentId))
          }

          const newDocumentId = findDocumentId(documentWithFiles.request)
          if (newDocumentId === -1) {
            toast.warn(t('declaration.p5.upload', { context: 'error' }))
            return Promise.reject(Error('Unable to find document id for file'))
          }

          return postFile.mutateAsync(toFileRequest(docFile, newDocumentId))
        })).flatMap((requests) => requests))

    const filePromises = await Promise.allSettled(asyncFileRequests)
    if (filePromises.some((result) => result.status === 'rejected')) {
      toast.warn(t('declaration.p5.upload', { context: 'error' }))
    }
  }

  function getConsignmentDeletedDocuments() {
    const previousDocuments = getValues('previousDocument')
    const transportDocuments = getValues('transportDocument')
    const supportingDocuments = getValues('supportingDocument')

    return {
      previousDocuments: previousDocuments.filter(isDeletedWithId),
      transportDocuments: transportDocuments.filter(isDeletedWithId),
      supportingDocuments: supportingDocuments.filter(isDeletedWithId),
    }
  }

  function getConsignmentItemDeletedDocuments(houseConsignments: HouseConsignmentType[]) {
    const transportDocuments: TransportDocument[] = []
    const previousDocuments: PreviousDocument[] = []
    const supportingDocuments: SupportingDocument[] = []

    houseConsignments
      .filter(excludeDeleted)
      .flatMap((house) => house.consignmentItem)
      .filter(excludeDeleted)
      .forEach((consignmentItem) => {
        transportDocuments.push(...consignmentItem.transportDocument
          .filter(isDeletedWithId))
        previousDocuments.push(...consignmentItem.previousDocument
          .filter(isDeletedWithId))
        supportingDocuments.push(...consignmentItem.supportingDocument
          .filter(isDeletedWithId))
      })

    return {
      transportDocuments,
      previousDocuments,
      supportingDocuments,
    }
  }

  function getHouseConsignmentDeletedDocuments(houseConsignments: HouseConsignmentType[]) {
    const transportDocuments: TransportDocument[] = []
    const previousDocuments: PreviousDocument[] = []
    const supportingDocuments: SupportingDocument[] = []

    houseConsignments
      .filter(excludeDeleted)
      .forEach((houseConsignment) => {
        transportDocuments.push(...houseConsignment.transportDocument
          .filter(isDeletedWithId))
        previousDocuments.push(...houseConsignment.previousDocument
          .filter(isDeletedWithId))
        supportingDocuments.push(...houseConsignment.supportingDocument
          .filter(isDeletedWithId))
      })

    return {
      transportDocuments,
      previousDocuments,
      supportingDocuments,
    }
  }

  const archiveDocuments = async (isDraft: boolean) => {
    await trigger()
    if (!isDraft && !isValid) return

    const currentTransitOperationId = getValues('id')
    if (currentTransitOperationId === null) throw Error('Missing required transit operation id for documents')

    const archivedTransportDocumentsIds: number[] = []
    const archivedPreviousDocumentIds : number[] = []
    const archivedSupportingDocumentIds : number[] = []

    const houseConsignments = getValues('houseConsignment')

    const {
      transportDocuments: consignmentTransportDocuments,
      previousDocuments: consignmentPreviousDocuments,
      supportingDocuments: consignmentSupportingDocuments,
    } = getConsignmentDeletedDocuments()

    const {
      transportDocuments: consignmentItemTransportDocuments,
      previousDocuments: consignmentItemPreviousDocuments,
      supportingDocuments: consignmentItemSupportingDocuments,
    } = getConsignmentItemDeletedDocuments(houseConsignments)

    const {
      transportDocuments: houseConsignmentTransportDocuments,
      previousDocuments: houseConsignmentPreviousDocuments,
      supportingDocuments: houseConsignmentSupportingDocuments,
    } = getHouseConsignmentDeletedDocuments(houseConsignments)

    archivedTransportDocumentsIds.push(...consignmentTransportDocuments
      .map((doc) => doc.id!))
    archivedPreviousDocumentIds.push(...consignmentPreviousDocuments
      .map((doc) => doc.id!))
    archivedSupportingDocumentIds.push(...consignmentSupportingDocuments
      .map((doc) => doc.id!))

    archivedTransportDocumentsIds.push(...consignmentItemTransportDocuments
      .map((doc) => doc.id!))
    archivedPreviousDocumentIds.push(...consignmentItemPreviousDocuments
      .map((doc) => doc.id!))
    archivedSupportingDocumentIds.push(...consignmentItemSupportingDocuments
      .map((doc) => doc.id!))

    archivedTransportDocumentsIds.push(...houseConsignmentTransportDocuments
      .map((doc) => doc.id!))
    archivedPreviousDocumentIds.push(...houseConsignmentPreviousDocuments
      .map((doc) => doc.id!))
    archivedSupportingDocumentIds.push(...houseConsignmentSupportingDocuments
      .map((doc) => doc.id!))

    const asyncRequests: Array<Promise<void>> = []

    if (archivedTransportDocumentsIds.length > 0) {
      archivedTransportDocumentsIds.forEach((id) => asyncRequests.push(deleteTransportDocument.mutateAsync(id)))
    }
    if (archivedPreviousDocumentIds.length > 0) {
      archivedPreviousDocumentIds.forEach((id) => asyncRequests.push(deletePreviousDocument.mutateAsync(id)))
    }
    if (archivedSupportingDocumentIds.length > 0) {
      archivedSupportingDocumentIds.forEach((id) => asyncRequests.push(deleteSupportingDocument.mutateAsync(id)))
    }

    await Promise.allSettled(asyncRequests)
  }

  return {
    createOrUpdateDocuments,
    archiveDocuments,
  }
}

export default useDocument
