import React, { useEffect, useMemo, useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { useTranslation } from 'react-i18next'
import {
  ConsignmentResponse, GuaranteeResponse, HouseConsignmentResponse, TransitOperationResponse,
} from '../../models'
import TransitSidebarSection from './TransitSidebarSection'
import TransitSidebarField from './TransitSidebarField'
import useCodelist from '../../hooks/useCodelist'
import TransitSidebarArrayField from './TransitSidebarArrayField'
import TransitSidebarGoodsItems from './TransitSidebarGoodsItems'
import TransitSidebarFeedbackSection from './TransitSidebarFeedbackSection'
import { sortBySequenceNumber } from '../../../TransitOperationEditor/services/useFieldArrayActionHelper'
import useConsignmentApi from '../../../TransitOperationEditor/hooks/useConsignment/api'
import useGuaranteeApi from '../../../TransitOperationEditor/hooks/useGuarantee/api'
import useHouseConsignmentApi from '../../../TransitOperationEditor/hooks/useHouseConsignment/api'
import useConsignmentItemCountApi from '../../../TransitOperationEditor/hooks/useConsignmentItemCount/api'
import { hasText } from '../../utils/common-util'
import TransitSidebarAuditSection from './TransitSidebarAuditSection'
import DECLARATION_TYPE_OPTIONS from '../../config'

export interface SidebarTransitDetailsProps {
  transitOperationId: number
  isLoading: boolean
  transitOperationData: TransitOperationResponse | undefined
}

const securityOptions = [
  {
    value: 'S0',
    label: '0 - Not used for safety and security purposes',
  },
  {
    value: 'S1',
    label: '1 - ENS',
  },
  {
    value: 'S2',
    label: '2 - EXS',
  },
  {
    value: 'S3',
    label: '3 - ENS & EXS',
  },
]

function TransitSidebarDetails(props: Readonly<SidebarTransitDetailsProps>) {
  const { t } = useTranslation()
  // const { user } = useContext(UserContext)
  const {
    transitOperationId,
    isLoading,
    transitOperationData,
  } = props

  const [consignmentData, setConsignmentData] = useState<ConsignmentResponse>()
  // const [authorisationsData, setAuthorisationsData] = useState<AuthorisationResponse[]>()
  const [guaranteeData, setGuaranteeData] = useState<GuaranteeResponse[]>()
  const [houseConsignmentsData, setHouseConsignmentsData] = useState<HouseConsignmentResponse[]>([])
  const [consignmentItemsCount, setConsignmentItemsCount] = useState<number | undefined>()
  const houseConsignmentIds = useMemo(
    () => (houseConsignmentsData ?? [])
      .filter((house) => house.id !== null)
      .map((house) => house.id!),
    [houseConsignmentsData],
  )

  // const [directoEventData, setDirectoEventData] = useState<DirectoEventResponse>()
  const [, countriesOptions] = useCodelist('COUNTRIES')
  const [, departureCustomsOfficesOptions] = useCodelist('DEPARTURE_CUSTOMS_OFFICES')
  const [, destinationCustomsOfficesOptions] = useCodelist('DESTINATION_CUSTOMS_OFFICES')

  const [, transportModeOptions] = useCodelist('TRANSPORT_MODES')
  const { fetchConsignment } = useConsignmentApi(transitOperationId, isLoading)
  const { fetchGuarantee } = useGuaranteeApi(transitOperationId, isLoading)
  const { fetchHouseConsignments } = useHouseConsignmentApi(consignmentData?.id ?? null, fetchConsignment.isLoading)
  const { fetchConsignmentItemsCount } = useConsignmentItemCountApi(houseConsignmentIds, fetchHouseConsignments.isLoading)

  // const { fetchDirectoEvent } = useDirectoEventApi(transitOperationId, user?.role === 'ADMIN')
  useEffect(() => {
    setConsignmentData(fetchConsignment.data)
  }, [fetchConsignment.data])
  useEffect(() => {
    setGuaranteeData(fetchGuarantee.data)
  }, [fetchGuarantee.data])
  useEffect(() => {
    setHouseConsignmentsData(fetchHouseConsignments.data ?? [])
  }, [fetchHouseConsignments.data])
  useEffect(() => {
    setConsignmentItemsCount(fetchConsignmentItemsCount.filter((result) => result.data)
      .map((result) => Number(result.data))
      .reduce((sum, count) => sum + count, 0))
  }, [fetchConsignmentItemsCount.reduce((sum, query) => sum + query.dataUpdatedAt, 0)])

  return (
    <Offcanvas.Body key="data-body">
      {
        hasText(transitOperationData?.auditReason) && (
          <TransitSidebarAuditSection auditReason={transitOperationData?.auditReason ?? ''} />
        )
      }
      {
        hasText(transitOperationData?.feedback) && (
          <TransitSidebarFeedbackSection feedback={transitOperationData?.feedback ?? ''} />
        )
      }
      <TransitSidebarSection
        columnId="general-info"
        headingLabel={t('declaration.navbar.general')}
      >
        <div className="col-6">
          <TransitSidebarField
            label={t('declaration.p5.security')}
            value={securityOptions.find((option) => option.value === transitOperationData?.security)?.label ?? '0'}
          />
          <TransitSidebarField
            label={t('declaration.p5.departureCustomsOffice')}
            value={departureCustomsOfficesOptions.find(
              (option) => option.value === transitOperationData?.customsOfficeOfDeparture,
            )?.label}
          />
          <TransitSidebarField
            label={t('declaration.p5.destinationCustomsOffice')}
            value={destinationCustomsOfficesOptions.find(
              (option) => option.value === transitOperationData?.customsOfficeOfDestination,
            )?.label}
          />
        </div>
        <div className="col-6">
          <TransitSidebarField
            label={t('declaration.p5.declarationType')}
            isLoading={fetchConsignment.isLoading}
            value={DECLARATION_TYPE_OPTIONS.find((option) => option.value === transitOperationData?.declarationType)?.label}
          />
          <TransitSidebarField
            label={t('declaration.p5.bindingItinerary')}
            value={transitOperationData?.bindingItinerary === false ? t('common.no') : t('common.yes')}
          />

          <TransitSidebarArrayField
            label={t('declaration.p5.countriesOfRouting')}
            isLoading={fetchConsignment.isLoading}
            items={consignmentData?.countryOfRoutings.sort(sortBySequenceNumber).map((routeCountry) => ({
              value: countriesOptions.find((option) => option.value === routeCountry.country)?.label,
              parenthesesValue: null,
            })) ?? []}
          />
        </div>
      </TransitSidebarSection>
      <TransitSidebarSection
        columnId="consignment"
        headingLabel={t('declaration.navbar.consignment')}
      >
        <div className="col-6">
          <TransitSidebarField
            label={t('declaration.p5.placeOfLoading')}
            isLoading={fetchConsignment.isLoading}
            value={consignmentData?.loading.location ?? '-'}
            parenthesesValue={countriesOptions.find((option) => option.value === consignmentData?.loading.country)?.label ?? '-'}
          />
          <TransitSidebarField
            label={t('declaration.p5.placeOfUnLoading')}
            isLoading={fetchConsignment.isLoading}
            value={consignmentData?.unloading.location ?? '-'}
            parenthesesValue={countriesOptions.find((option) => option.value === consignmentData?.unloading.country)?.label ?? '-'}
          />
          <TransitSidebarField
            label={t('declaration.p5.grossWeight')}
            isLoading={fetchConsignment.isLoading}
            value={`${consignmentData?.grossMass ?? 0}`}
          />
          <TransitSidebarField
            label={t('declaration.guaranteeAmount')}
            isLoading={fetchGuarantee.isLoading}
            value={`${guaranteeData?.at(0)?.amount ?? '-'}`}
          />
        </div>
        <div className="col-6">
          <TransitSidebarField
            label={t('declaration.p5.inlandTransportMode')}
            isLoading={fetchConsignment.isLoading}
            value={transportModeOptions.find((option) => option.value === consignmentData?.inlandModeOfTransport)?.label ?? '-'}
          />
          <TransitSidebarArrayField
            label={t('declaration.p5.departureTransportMeans')}
            isLoading={fetchConsignment.isLoading}
            items={consignmentData?.departureTransportMeans.sort(sortBySequenceNumber).map((transportMeans) => ({
              value: transportMeans.identificationNumber,
              parenthesesValue: countriesOptions.find((option) => option.value === transportMeans.nationality)?.label,
            })) ?? []}
          />
          <TransitSidebarField
            label={t('declaration.p5.borderTransportMode')}
            isLoading={fetchConsignment.isLoading}
            value={transportModeOptions.find((option) => option.value === consignmentData?.borderModeOfTransport)?.label ?? '-'}
          />
          <TransitSidebarArrayField
            label={t('declaration.p5.borderTransportMeans')}
            isLoading={fetchConsignment.isLoading}
            items={consignmentData?.activeBorderTransportMeans.sort(sortBySequenceNumber).map((transportMeans) => ({
              value: transportMeans.identificationNumber,
              parenthesesValue: countriesOptions.find((option) => option.value === transportMeans.nationality)?.label,
            })) ?? []}
          />
        </div>
      </TransitSidebarSection>
      <TransitSidebarSection
        columnId="transitParties"
        headingLabel={t('declaration.navbar.transitParties')}
      >
        <div className="col-6">
          <TransitSidebarArrayField
            label={t('declaration.p5.consignor')}
            isLoading={fetchConsignment.isLoading}
            items={[
              {
                value: consignmentData?.consignor?.name,
                parenthesesValue: consignmentData?.consignor?.identificationNumber ?? null,
              },
              {
                value: consignmentData?.consignor?.address?.street,
                parenthesesValue: consignmentData?.consignor?.address?.city,
              },
              {
                value: countriesOptions.find((option) => option.value === consignmentData?.consignor?.address?.country)?.label,
                parenthesesValue: consignmentData?.consignor?.address?.postcode,
              },
            ]}
          />
          <TransitSidebarArrayField
            label={t('declaration.p5.carrier')}
            isLoading={fetchConsignment.isLoading}
            items={[
              {
                value: consignmentData?.carrier?.carrierName,
                parenthesesValue: consignmentData?.carrier?.eori ?? null,
              },
            ]}
          />

        </div>
        <div className="col-6">
          <TransitSidebarArrayField
            label={t('declaration.p5.consignee')}
            isLoading={fetchConsignment.isLoading}
            items={[
              {
                value: consignmentData?.consignee?.name,
                parenthesesValue: consignmentData?.consignee?.identificationNumber ?? null,
              },
              {
                value: consignmentData?.consignee?.address?.street,
                parenthesesValue: consignmentData?.consignee?.address?.city,
              },
              {
                value: countriesOptions.find((option) => option.value === consignmentData?.consignee?.address?.country)?.label,
                parenthesesValue: consignmentData?.consignee?.address?.postcode,
              },
            ]}
          />
        </div>
      </TransitSidebarSection>
      <TransitSidebarSection
        columnId="goods"
        headingLabel={`${t('declaration.navbar.goods')} ${(consignmentItemsCount !== undefined ? `(${consignmentItemsCount})` : '')}`}
        initialSectionState={false}
        delayedOpenSection={consignmentItemsCount !== undefined && consignmentItemsCount < 5}
      >
        {
          consignmentData?.id !== undefined && consignmentData?.id !== null && (
            <TransitSidebarGoodsItems
              houseConsignmentIds={houseConsignmentIds}
              transitOperationId={transitOperationId}
              isLoading={fetchConsignment.isLoading}
            />
          )
        }

      </TransitSidebarSection>
    </Offcanvas.Body>
  )
}

export default TransitSidebarDetails
