import React, { useContext, useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import DeclarationTabLayout from '../../layout/DeclarationTabLayout'
import HouseConsignment from './HouseConsignment'
import AdditionalSupplyChainActors from '../transitParties/AdditionalSupplyChainActors'
import Consignor from '../transitParties/Consignor'
import Consignee from '../transitParties/Consignee'
import { isTransitionalPeriodActive } from '../../../common/utils/ncts-constants'
import DepartureTransportMeans from '../general/DepartureTransportMeans'
import TransportDocuments from '../../components/TransportDocuments'
import PreviousDocuments from '../../components/PreviousDocuments'
import SupportingDocuments from '../../components/SupportingDocuments'
import AdditionalReferences from '../../components/AdditionalReferences'
import AdditionalInformations from '../../components/AdditionalInformations'
import { TransitOperationContext } from '../../hooks/useTransitOperationContext'
import useCodelist from '../../../common/hooks/useCodelist'
import { Address } from '../../form/schemas/commonConsignmentSchemas'
import { excludeDeleted } from '../../../common/utils/common-util'

interface HouseConsignmentTableRowProps {
  index: number
  tableRowRemove: (index: number) => void
  asideAction: (targetId: number | null) => void
}

function HouseConsignmentsTableRow({
  index,
  tableRowRemove,
  asideAction,
}: HouseConsignmentTableRowProps) {
  const { t } = useTranslation()
  const [, countriesOptions] = useCodelist('COUNTRIES')
  const [isRowClosed, setIsRowClosed] = useState<boolean>(true)

  const houseConsignmentScope = `houseConsignment.${index}` as const

  const { nctsErrors } = useContext(TransitOperationContext)

  const hasError = useMemo(() => {
    const fieldError = nctsErrors
      .find((error) => error.field?.includes(houseConsignmentScope) ?? false)
    return fieldError ?? false
  }, [nctsErrors])

  const rowData = useWatch({
    name: houseConsignmentScope,
  })

  const getCountry = (address: Address): string | undefined => {
    const {
      country,
    } = address

    return countriesOptions.find((countryOption) => countryOption.value === country)?.label
  }

  return (
    <>
      <tr className={hasError ? 'invalid-row' : ''}>
        <td className="sequence-nr">
          {index + 1}
        </td>
        <td>
          {rowData.consignee !== null && rowData.consignee.name}
        </td>
        <td>
          {rowData.consignee !== null && rowData.consignee.address !== null && getCountry(rowData.consignee.address)}
        </td>
        <td>
          {rowData.consignor !== null && rowData.consignor.name}
        </td>
        <td>
          {rowData.consignor !== null && rowData.consignor.address !== null && getCountry(rowData.consignor.address)}
        </td>
        <td className="text-end">
          <Button
            type="button"
            className="btn-sm me-2"
            variant="primary font-size-sm"
            onClick={() => asideAction(index)}
            title="goods"
          >
            <i className="fas fa-cube" />
            {' '}
            {t('declaration.p5.goodsWithNumber', { numberOfGoods: rowData.consignmentItem.filter(excludeDeleted).length })}
          </Button>
          <Button
            type="button"
            className="btn-sm me-2"
            data-bs-toggle="collapse"
            data-bs-target={`#row_${index}`}
            aria-expanded={isRowClosed}
            aria-controls={`row_${index}`}
            tabIndex={0}
            variant="dark"
            onClick={() => setIsRowClosed(!isRowClosed)}
            onKeyDown={() => setIsRowClosed(!isRowClosed)}
          >
            <i className="fa fa-pencil" />
          </Button>
          <Button
            type="button"
            className="btn-sm"
            variant="danger"
            onClick={() => tableRowRemove(index)}
          >
            <i className="fa fa-trash" />
          </Button>
        </td>
      </tr>
      <tr
        className={`collapse ${isRowClosed ? '' : 'show'}`}
        id={`row_${index}`}
        key={`houseConsignmentRow_${rowData.sequenceNumber}`}
      >
        <td colSpan={6}>
          <table className="w-100">
            <tbody>
              <tr>
                <td colSpan={7}>
                  <DeclarationTabLayout
                    start={(
                      <>
                        <HouseConsignment scope={houseConsignmentScope} />
                        <DepartureTransportMeans scope={`houseConsignment.${index}.departureTransportMeans`} />
                        <AdditionalSupplyChainActors scope={`${houseConsignmentScope}.additionalSupplyChainActor`} />
                        <AdditionalReferences scope={`houseConsignment.${index}.additionalReference`} />
                        <AdditionalInformations scope={`houseConsignment.${index}.additionalInformation`} />
                      </>
                    )}
                    centerLeft={(
                      <>
                        <Consignor scope={`houseConsignment.${index}.consignor`} />
                        <Consignee scope={`houseConsignment.${index}.consignee`} />
                      </>
                    )}
                    centerRight={(
                      <SupportingDocuments scope={`houseConsignment.${index}.supportingDocument`} />
                    )}
                    end={(
                      <>
                        <TransportDocuments scope={`houseConsignment.${index}.transportDocument`} />
                        <PreviousDocuments scope={`houseConsignment.${index}.previousDocument`} />
                      </>
                    )}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </>
  )
}

export default HouseConsignmentsTableRow
