import { DeclarationForm } from '../schemas/declarationFormSchema'
import { HouseConsignmentType } from '../schemas/houseConsignmentSchema'
import { excludeDeleted } from '../../../common/utils/common-util'
import { removePrefix } from '../../../common/utils/classifier-util'

const C0153Validator = {
  validateCommodityCodeRequirement: (formData: DeclarationForm, houseConsignment: HouseConsignmentType) => {
    const {
      declarationType,
    } = formData

    if (declarationType === 'TIR' && !houseConsignment.previousDocument
      .filter(excludeDeleted)
      .some((doc) => removePrefix(doc.documentType, 'PREVIOUS_DOCUMENT_TYPE_') === 'N830')) return 'OPTIONAL'

    return 'REQUIRED'
  },
}

export default C0153Validator
