import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import FormField from '../../components/FormField'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'
import { excludeDeleted } from '../../../common/utils/common-util'

function GeneralInformation() {
  const { t } = useTranslation()
  const { getValues } = useFormContext<DeclarationForm>()
  const declarationForm = getValues()

  const numberOfGoods = declarationForm.houseConsignment
    .filter(excludeDeleted)
    .map((house) => house.consignmentItem.filter(excludeDeleted).length)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0)

  return (
    <section className="py-3">
      <CollapsibleColumn
        columnId="declaration-general-information"
        headingLabel={t('declaration.p5.generalInformation')}
      >
        <div className="row">
          <div className="col-lg-6">
            <FormField labelKey="declaration.p5.declarationType">
              <span>{declarationForm.declarationType}</span>
            </FormField>
            <FormField labelKey="declaration.p5.customsOfficeOfRouting">
              {declarationForm.countryOfRoutingOfConsignment
                .map((item) => (
                  <span className="me-2" key={item.country}>
                    {item.country?.substring(8)}
                  </span>
                ))}
            </FormField>
          </div>
          <div className="col-lg-6">
            <FormField labelKey="declaration.p5.numberOfItemsDeclaration">
              <span>{numberOfGoods}</span>
            </FormField>
          </div>
        </div>
      </CollapsibleColumn>
    </section>
  )
}

export default GeneralInformation
