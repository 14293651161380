import axios from 'axios'
import { apiService, handleApiException, handleResponseData } from 'services/api.service'

function sendEmailNotification(message: string) {
  return axios
    .post(
      apiService.getFullApiUrl('/email/notify-users'),
      {
        message,
      },
    )
    .then<void>(handleResponseData)
    .catch(handleApiException)
}
function getHeaderNotificationMessage() {
  return axios
    .get<{ data: string }>(
    apiService.getFullApiUrl('/configuration/maintenance-notification'),
  )
    .then((handleResponseData))
    .catch(handleApiException)
}

function putHeaderNotificationMessage(message: string) {
  return axios
    .put(apiService.getFullApiUrl('/configuration/maintenance-notification'), {
      message,
    })
    .then(handleResponseData)
    .catch(handleApiException)
}
function updatePolishBackupChannelBoolean(isEnabled: boolean) {
  return axios
    .put(apiService.getFullApiUrl('/configuration/pl-bcp'), {
      value: isEnabled,
    })
    .then(handleResponseData)
    .catch(handleApiException)
}
function sendConsent() {
  return axios
    .post(apiService.getFullApiUrl('/configuration/borica-consent'))
    .then(handleResponseData)
    .catch(handleApiException)
}
function getPolishBackupChannelBoolean() {
  return axios
    .get<boolean>(apiService.getFullApiUrl('/configuration/pl-bcp'))
    .catch(handleApiException)
}

const NotificationService = {
  sendEmailNotification,
  getHeaderNotificationMessage,
  putHeaderNotificationMessage,
  updatePolishBackupChannelBoolean,
  getPolishBackupChannelBoolean,
  sendConsent,
}

export default NotificationService
