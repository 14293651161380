import { AdditionalInformation } from '../../schemas/commonConsignmentSchemas'
import { ADDITIONAL_INFORMATION_CODE_PREFIX, removePrefix } from '../../../../common/utils/classifier-util'

const R3061Validator = {
  hasValidCodeForAdditionalInformation: (additionalInformation: AdditionalInformation): boolean => {
    const code = removePrefix(additionalInformation.code?.trim(), ADDITIONAL_INFORMATION_CODE_PREFIX)

    return code !== '30600'
  },
} as const

export default R3061Validator
