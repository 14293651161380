import { RuleResult } from 'types/DeclarationP5'
import { DeclarationForm } from '../schemas/declarationFormSchema'
import { HouseConsignmentType } from '../schemas/houseConsignmentSchema'
import { excludeDeleted, hasText } from '../../../common/utils/common-util'
import { isTransitionalPeriodActive } from '../../../common/utils/ncts-constants'
import B1895Validator from './transitional/B1895'
import B1031Validator from './transitional/B1031'

const C0502Validator = {
  validateHouseConsignmentUcrRequirement: (formData: DeclarationForm): RuleResult => {
    if (isTransitionalPeriodActive()) {
      return B1031Validator.validateHouseConsignmentUcrRequirement()
    }
    if (hasText(formData.referenceNumberUCR)) {
      return 'NOT_ALLOWED'
    }

    return 'OPTIONAL'
  },
  validateConsignmentItemUcrRequirement: (formData: DeclarationForm, houseConsignment: HouseConsignmentType): RuleResult => {
    if (isTransitionalPeriodActive()) {
      return B1895Validator.validateConsignmentItemUcrRequirement(formData.referenceNumberUCR)
    }
    if (hasText(formData.referenceNumberUCR)) {
      return 'NOT_ALLOWED'
    }
    if (hasText(houseConsignment.referenceNumberUCR)) {
      return 'NOT_ALLOWED'
    }
    if ((formData.transportDocument.length > 0
        && formData.transportDocument
          .filter(excludeDeleted)
          .filter((doc) => hasText(doc.documentType) || hasText(doc.referenceNumber)).length > 0)
      || (
        houseConsignment.transportDocument.length > 0
        && houseConsignment.transportDocument
          .filter(excludeDeleted)
          .filter((doc) => hasText(doc.documentType) || hasText(doc.referenceNumber)).length > 0
      )) {
      return 'OPTIONAL'
    }

    return 'REQUIRED'
  },
} as const

export default C0502Validator
