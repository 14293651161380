import { ConsignmentItem } from '../../schemas/consignmentItemSchema'
import { excludeDeleted } from '../../../../common/utils/common-util'
import { HouseConsignmentType } from '../../schemas/houseConsignmentSchema'

const R0221Validator = {
  isConsignmentGrossMassValid: (
    houseConsignment: HouseConsignmentType,
    consignmentItem: ConsignmentItem,
  ) => {
    const {
      packaging,
      goodsMeasureGrossMass,
    } = consignmentItem

    const hasPackagesWithZero = packaging.filter(excludeDeleted)
      .some((packagingItem) => packagingItem?.numberOfPackages === 0)

    if (!hasPackagesWithZero) {
      return goodsMeasureGrossMass !== 0
    }

    return goodsMeasureGrossMass === 0 && houseConsignment.consignmentItem.filter(excludeDeleted)
      .some((otherConsignmentItem) => consignmentItem !== otherConsignmentItem && otherConsignmentItem.goodsMeasureGrossMass > 0)
  },
}

export default R0221Validator
