import { useContext, useEffect } from 'react'
import { UserContext } from 'context/UserContext'
import { toast } from 'react-toastify'
import useDirectoApi from './api'
import { parseDirectoResponse, toTransitOperationDirectoRequest } from './mapper'
import { UseDeclarationFormReturn } from '../../form'
import { hasText } from '../../../common/utils/common-util'

export default function useDirectoEvent(form: UseDeclarationFormReturn) {
  const {
    getValues,
    formState: { isSubmitting },
    reset, setValue,
    watch,
  } = form
  const transitOperationId: number | null = watch('id')
  const { user } = useContext(UserContext)

  const {
    postDirecto,
    fetchDirectoEvent,
    updateDirecto,
  } = useDirectoApi(transitOperationId, user?.role === 'ADMIN', form.getValues().directoId)

  const populateDirectoEvent = () => {
    if ((fetchDirectoEvent.isFetching || isSubmitting || !transitOperationId) && form.getValues().directoId === null) {
      return
    }

    const response = parseDirectoResponse(fetchDirectoEvent.data)
    reset({
      ...structuredClone(getValues()),
      directo: response,
    })
  }

  useEffect(() => {
    populateDirectoEvent()
  }, [fetchDirectoEvent.data])

  const getDirectoEvent = () => fetchDirectoEvent.data

  const createOrUpdateDirectoEvent = async () => {
    const directo = getValues('directo')
    if (!hasText(directo.descriptionAddendum) && directo.customerId === null && directo.id === undefined) {
      return
    }
    const currentTransitOperationId = getValues('id')
    if (currentTransitOperationId === null) {
      throw Error('Missing transitOperationId')
    }
    if (directo.customerId == null) {
      toast.error('Directo not saved, missing customer')
      return
    }
    const directoEvent = toTransitOperationDirectoRequest(directo.descriptionAddendum, directo.customerId, currentTransitOperationId)
    if (directo.id === undefined) {
      const result = await postDirecto.mutateAsync(directoEvent)
      if (result.id) {
        setValue('directo.id', result.id)
      }
    } else {
      await updateDirecto.mutateAsync({
        id: directo.id,
        data: directoEvent,
      })
    }
  }

  return {
    getDirectoEvent,
    createOrUpdateDirectoEvent,
  }
}
