import { HouseConsignmentType } from '../../schemas/houseConsignmentSchema'
import { isTransitionalPeriodActive } from '../../../../common/utils/ncts-constants'
import { excludeDeleted } from '../../../../common/utils/common-util'

const R0994Validator = {
  getMinimumConsignmentGrossMass: (
    houseConsignments: HouseConsignmentType[],
  ): number => {
    if (isTransitionalPeriodActive()) {
      return 0
    }
    const sum = houseConsignments
      .filter(excludeDeleted)
      .reduce((sumAccumulator, house) => sumAccumulator + house.grossMass, 0)

    return Number.isNaN(sum) ? 0 : Number(sum.toFixed(3))
  },
} as const

export default R0994Validator
