import {
  ActiveBorderTransportMeansType,
  AdditionalSupplyChainActor,
  CountryOfRoutingOfConsignment,
  DepartureTransportMeansType,
} from '../../form/schemas/commonConsignmentSchemas'
import {
  ActiveBorderTransportMeansResponse,
  AdditionalSupplyChainActorResponse,
  ConsignmentResponse,
  CountryOfRoutingResponse,
  CreateOrUpdateActiveBorderTransportMeansRequest,
  CreateOrUpdateAdditionalSupplyChainActorRequest,
  CreateOrUpdateConsignmentRequest,
  CreateOrUpdateCountryOfRoutingRequest,
  CreateOrUpdateDepartureTransportMeansRequest,
  CreateOrUpdateTransportEquipmentRequest,
  DepartureTransportMeansResponse,
  LocationOfGoodsResponse,
  TransportEquipmentResponse,
} from '../../../common/models'
import { TransportEquipmentType } from '../../form/schemas/transportEquipmentSchema'

import { DeclarationForm } from '../../form/schemas/declarationFormSchema'
import { LocationOfGoodsType } from '../../form/schemas/locationOfGoodsSchema'
import { parseCarrierResponse } from '../useCarrier/mapper'
import { parseConsigneeConsignmentDetailResponse, parseConsignorConsignmentDetailResponse } from '../useConsignmentDetail/mapper'
import { excludeDeleted } from '../../../common/utils/common-util'
import { sortBySequenceNumber } from '../../services/useFieldArrayActionHelper'
import R0473Validator from '../../form/validators/final-rules/R0473'
import R0076Validator from '../../form/validators/final-rules/R0076'

export function toCreateOrUpdateCountryOfRoutingRequest(
  item: CountryOfRoutingOfConsignment,
  index: number,
): CreateOrUpdateCountryOfRoutingRequest {
  return {
    id: item.id,
    sequenceNumber: index,
    country: item.country ?? '',
  }
}

export function toCreateOrUpdateTransportEquipmentRequest(
  equipment: TransportEquipmentType,
  transitOperationId: number,
  index: number,
): CreateOrUpdateTransportEquipmentRequest {
  return {
    id: equipment.id,
    sequenceNumber: index,
    containerIdentificationNumber: equipment.containerIdentificationNumber,
    seals: equipment.seals
      .filter((item) => !item.deleted)
      .map((sealItem, sealIndex) => ({
        id: sealItem.id,
        sequenceNumber: sealIndex,
        identifier: sealItem.identifier,
      })),
    goodsReferences: equipment.goodsReferences
      .filter(excludeDeleted)
      .map((goodsRefItem, goodsReferenceIndex) => ({
        id: goodsRefItem.id,
        sequenceNumber: goodsReferenceIndex,
        declarationGoodsItemNumber: goodsRefItem.declarationGoodsItemNumber,
      })),
    transitOperationId,
  }
}

export function toCreateOrUpdateAdditionalSupplyChainActorRequest(
  actor: AdditionalSupplyChainActor,
  index: number,
): CreateOrUpdateAdditionalSupplyChainActorRequest {
  return {
    id: actor.id,
    sequenceNumber: index,
    role: actor.role,
    identificationNumber: actor.identificationNumber,
  }
}

export function toCreateOrUpdateActiveBorderTransportMeansRequest(
  borderMeansItem: ActiveBorderTransportMeansType,
  index: number,
): CreateOrUpdateActiveBorderTransportMeansRequest {
  const identificationNumber = R0076Validator.doesIdentificationNumberMustBeUpperCase(borderMeansItem)
    ? borderMeansItem.identificationNumber?.toUpperCase()
    : borderMeansItem.identificationNumber

  return {
    id: borderMeansItem.id,
    sequenceNumber: index,
    customsOfficeAtBorderReferenceNumber: borderMeansItem.customsOfficeAtBorderReferenceNumber,
    typeOfIdentification: borderMeansItem.typeOfIdentification,
    identificationNumber,
    nationality: borderMeansItem.nationality,
    conveyanceReferenceNumber: borderMeansItem.conveyanceReferenceNumber ?? '',
  }
}

export function toCreateOrUpdateDepartureTransportMeansRequest(
  departureMeansItem: DepartureTransportMeansType,
  index: number,
): CreateOrUpdateDepartureTransportMeansRequest {
  const identificationNumber = R0473Validator.doesIdentificationNumberMustBeUpperCase(departureMeansItem)
    ? departureMeansItem.identificationNumber?.toUpperCase()
    : departureMeansItem.identificationNumber

  return {
    id: departureMeansItem.id,
    sequenceNumber: index,
    typeOfIdentification: departureMeansItem.typeOfIdentification,
    nationality: departureMeansItem.nationality,
    identificationNumber,
  }
}

export function toCreateOrUpdateConsignmentRequest(declarationForm: DeclarationForm): CreateOrUpdateConsignmentRequest {
  if (declarationForm.id === null) throw Error('Missing transitOperationId')
  const transitOperationId = declarationForm.id

  declarationForm.countryOfRoutingOfConsignment.sort(sortBySequenceNumber)
  declarationForm.transportEquipment.sort(sortBySequenceNumber)
  declarationForm.additionalSupplyChainActor.sort(sortBySequenceNumber)
  declarationForm.activeBorderTransportMeans.sort(sortBySequenceNumber)
  declarationForm.departureTransportMeans.sort(sortBySequenceNumber)

  return {
    transitOperationId,
    countryOfDispatch: declarationForm.countryOfDispatch,
    countryOfDestination: declarationForm.countryOfDestination,
    grossMass: declarationForm.grossMass,
    containerIndicator: declarationForm.containerIndicator === 'true',
    referenceNumberUcr: declarationForm.referenceNumberUCR ?? undefined,
    borderModeOfTransport: declarationForm.borderModeOfTransport,
    inlandModeOfTransport: declarationForm.inlandModeOfTransport,
    loadingPlace: {
      id: declarationForm.placeOfLoading.id,
      location: declarationForm.placeOfLoading.location,
      country: declarationForm.placeOfLoading.country,
      unLocode: declarationForm.placeOfLoading.unLocode,
      type: declarationForm.placeOfLoading.type,
    },
    unloadingPlace: {
      id: declarationForm.placeOfUnloading.id,
      location: declarationForm.placeOfUnloading.location,
      country: declarationForm.placeOfUnloading.country,
      unLocode: declarationForm.placeOfUnloading.unLocode,
      type: declarationForm.placeOfUnloading.type,
    },
    goodsLocation: declarationForm.locationOfGoods ? {
      id: declarationForm.locationOfGoods.id ?? null,
      transitOperationId,
      typeOfLocation: declarationForm.locationOfGoods.typeOfLocation ?? '',
      qualifierOfIdentification: declarationForm.locationOfGoods.qualifierOfIdentification ?? '',
      authorisationNumber: declarationForm.locationOfGoods.authorisationNumber ?? '',
      additionalIdentifier: declarationForm.locationOfGoods.additionalIdentifier ?? '',
      unLocode: declarationForm.locationOfGoods.unLocode ?? '',
      referenceNumber: declarationForm.locationOfGoods.referenceNumber ?? '',
      economicOperatorIdentificationNumber: declarationForm.locationOfGoods.economicOperatorIdentificationNumber ?? '',
      address: {
        id: declarationForm.locationOfGoods.address?.id ?? null,
        street: declarationForm.locationOfGoods.address?.streetAndNumber ?? '',
        city: declarationForm.locationOfGoods.address?.city ?? '',
        country: declarationForm.locationOfGoods.address?.country ?? '',
        postcode: declarationForm.locationOfGoods.address?.postcode ?? '',
        transitOperationId,
      },
      contactPerson: {
        id: declarationForm.locationOfGoods.contactPerson?.id ?? null,
        name: declarationForm.locationOfGoods.contactPerson?.name ?? '',
        email: declarationForm.locationOfGoods.contactPerson?.email ?? '',
        telephone: declarationForm.locationOfGoods.contactPerson?.telephone ?? '',
        transitOperationId,
      },
      houseNumber: declarationForm.locationOfGoods.postcodeAddress?.houseNumber ?? '',
      postcode: declarationForm.locationOfGoods.postcodeAddress?.postcode ?? '',
      country: declarationForm.locationOfGoods.postcodeAddress?.country ?? '',
    } : null,
    consigneeId: declarationForm.consignee?.id ?? undefined,
    consignorId: declarationForm.consignor?.id ?? undefined,
    carrierId: declarationForm.carrier?.id ?? undefined,
    countryOfRoutings: declarationForm.countryOfRoutingOfConsignment
      .filter(excludeDeleted)
      .map(toCreateOrUpdateCountryOfRoutingRequest),
    transportEquipments: declarationForm.transportEquipment
      .filter(excludeDeleted)
      .map((equipment, index) => toCreateOrUpdateTransportEquipmentRequest(equipment, transitOperationId, index)),
    additionalSupplyChainActors: declarationForm.additionalSupplyChainActor
      .filter(excludeDeleted)
      .map(toCreateOrUpdateAdditionalSupplyChainActorRequest),
    activeBorderTransportMeans: declarationForm.activeBorderTransportMeans
      .filter(excludeDeleted)
      .map(toCreateOrUpdateActiveBorderTransportMeansRequest),
    departureTransportMeans: declarationForm.departureTransportMeans
      .filter(excludeDeleted)
      .map(toCreateOrUpdateDepartureTransportMeansRequest),
  }
}
export function parseActiveBorderTransportMeansResponse(response: ActiveBorderTransportMeansResponse, index: number):
ActiveBorderTransportMeansType {
  return {
    id: response.id,
    deleted: false,
    conveyanceReferenceNumber: response.conveyanceReferenceNumber ?? '',
    customsOfficeAtBorderReferenceNumber: response.customsOfficeAtBorderReferenceNumber ?? '',
    identificationNumber: response.identificationNumber ?? '',
    nationality: response.nationality ?? '',
    sequenceNumber: response.sequenceNumber ?? index,
    typeOfIdentification: response.typeOfIdentification ?? '',
  }
}

export function parseDepartureTransportMeansResponse(
  response: DepartureTransportMeansResponse,
  index: number,
): DepartureTransportMeansType {
  return {
    id: response.id,
    deleted: false,
    identificationNumber: response.identificationNumber ?? '',
    nationality: response.nationality ?? '',
    sequenceNumber: response.sequenceNumber ?? index,
    typeOfIdentification: response.typeOfIdentification ?? '',
  }
}

export function parseCountryOfRoutingResponse(response: CountryOfRoutingResponse, index: number): CountryOfRoutingOfConsignment {
  return {
    id: response.id,
    deleted: false,
    country: response.country,
    sequenceNumber: response.sequenceNumber ?? index,
  }
}

export function parseTransportEquipmentResponse(response: TransportEquipmentResponse, index: number): TransportEquipmentType {
  return {
    id: response.id,
    deleted: false,
    sequenceNumber: response?.sequenceNumber ?? index,
    containerIdentificationNumber: response.containerIdentificationNumber ?? '',
    goodsReferences: response.goodsReferences.map((item, itemIndex) => ({
      id: item?.id,
      deleted: false,
      sequenceNumber: item?.sequenceNumber ?? itemIndex,
      declarationGoodsItemNumber: item?.declarationGoodsItemNumber ?? -1,
    })) ?? [],
    seals: response.seals.map((item, itemIndex) => ({
      id: item.id,
      deleted: false,
      identifier: item?.identifier ?? '',
      sequenceNumber: item?.sequenceNumber ?? itemIndex,
    })),
  }
}

export function parseLocationOfGoods(response: LocationOfGoodsResponse | undefined): LocationOfGoodsType {
  return {
    id: response?.id ?? null,
    deleted: false,
    additionalIdentifier: response?.additionalIdentifier ?? '',
    address: {
      id: response?.address?.id ?? null,
      city: response?.address?.city ?? '',
      country: response?.address?.country ?? '',
      postcode: response?.address?.postcode ?? '',
      streetAndNumber: response?.address?.street ?? '',
    },
    authorisationNumber: response?.authorisationNumber ?? '',
    contactPerson: {
      deleted: false,
      email: response?.contactPerson.email ?? '',
      id: response?.contactPerson.id ?? null,
      name: response?.contactPerson.name ?? '',
      telephone: response?.contactPerson.telephone ?? '',
    },
    postcodeAddress: response !== undefined ? {
      houseNumber: response?.houseNumber ?? '',
      postcode: response?.postcode ?? '',
      country: response?.country ?? '',
    } : null,
    economicOperatorIdentificationNumber: response?.economicOperatorIdentificationNumber ?? '',
    gnss: null,
    qualifierOfIdentification: response?.qualifierOfIdentification ?? '',
    referenceNumber: response?.referenceNumber ?? '',
    typeOfLocation: response?.typeOfLocation ?? '',
    unLocode: response?.unLocode ?? '',
  }
}

export function parseAdditionalSupplyChainActorResponse(
  response: AdditionalSupplyChainActorResponse,
  index: number,
): AdditionalSupplyChainActor {
  return {
    id: response.id,
    deleted: false,
    sequenceNumber: response?.sequenceNumber ?? index,
    identificationNumber: response?.identificationNumber ?? '',
    role: response?.role ?? '',
  }
}

export type ConsignmentSubtype = 'countryOfDestination' | 'countryOfDispatch' | 'grossMass' | 'containerIndicator' | 'referenceNumberUCR'
| 'inlandModeOfTransport' | 'placeOfLoading' | 'placeOfUnloading' | 'locationOfGoods' | 'consignee' | 'consignor' | 'carrier'
| 'countryOfRoutingOfConsignment' | 'transportEquipment' | 'additionalSupplyChainActor' | 'activeBorderTransportMeans'
| 'departureTransportMeans' | 'consignmentId' | 'borderModeOfTransport'

export function parseConsignmentResponse(response?: ConsignmentResponse | null): Pick<DeclarationForm, ConsignmentSubtype> {
  return {
    carrier: parseCarrierResponse(response?.carrier),
    consignee: parseConsigneeConsignmentDetailResponse(response?.consignee),
    consignor: parseConsignorConsignmentDetailResponse(response?.consignor),
    consignmentId: response?.id ?? null,
    countryOfDispatch: response?.countryOfDispatch ?? '',
    countryOfDestination: response?.countryOfDestination ?? '',
    grossMass: (!Number.isNaN(response?.grossMass) ? response?.grossMass : null) ?? 0,
    containerIndicator: response?.containerIndicator === true ? 'true' : 'false',
    referenceNumberUCR: response?.referenceNumberUcr ?? '',
    borderModeOfTransport: response?.borderModeOfTransport ?? '',
    inlandModeOfTransport: response?.inlandModeOfTransport ?? '',
    placeOfLoading: {
      id: response?.loading?.id ?? null,
      location: response?.loading?.location ?? '',
      country: response?.loading?.country ?? '',
      unLocode: response?.loading?.unLocode ?? '',
      type: 'LOADING',
    },
    placeOfUnloading: {
      id: response?.unloading?.id ?? null,
      location: response?.unloading?.location ?? '',
      country: response?.unloading?.country ?? '',
      unLocode: response?.unloading?.unLocode ?? '',
      type: 'UNLOADING',
    },
    activeBorderTransportMeans: response?.activeBorderTransportMeans.map(parseActiveBorderTransportMeansResponse) ?? [],
    departureTransportMeans: response?.departureTransportMeans.map(parseDepartureTransportMeansResponse) ?? [],
    countryOfRoutingOfConsignment: response?.countryOfRoutings.map(parseCountryOfRoutingResponse) ?? [],
    transportEquipment: response?.transportEquipments.map(parseTransportEquipmentResponse) ?? [],
    additionalSupplyChainActor: response?.additionalSupplyChainActors.map(parseAdditionalSupplyChainActorResponse) ?? [],
    locationOfGoods: parseLocationOfGoods(response?.locationOfGoods),
  }
}
