import { RuleResult } from 'types/DeclarationP5'
import { DeclarationForm } from '../schemas/declarationFormSchema'
import { extractCountryFromDepartureOffice } from '../../../common/utils/classifier-util'
import { CL147_SECURITY_AGREEMENT_AREA_COUNTRIES, isTransitionalPeriodActive } from '../../../common/utils/ncts-constants'
import NC9043Validator from './FI/NC9043'

const C0710Validator = {
  validateLocationOfGoodsRequirement: (formData: DeclarationForm): RuleResult => {
    if (isTransitionalPeriodActive()) {
      return 'OPTIONAL' // B1804
    }

    if (formData.additionalDeclarationType === 'D') {
      return 'OPTIONAL'
    }

    const departureCountry = extractCountryFromDepartureOffice(formData.departureCustomsOffice)
    if (departureCountry && CL147_SECURITY_AGREEMENT_AREA_COUNTRIES.includes(departureCountry)) {
      if (departureCountry === 'FI') {
        return NC9043Validator.validateLocationOfGoodsRequirement(formData)
      }

      return 'OPTIONAL'
    }

    return 'REQUIRED'
  },
} as const

export default C0710Validator
