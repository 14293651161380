import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { removePrefix } from '../../../../common/utils/classifier-util'
import { CL112_CTC_COUNTRIES, CL178_PREVIOUS_DOCUMENT_UNION_GOODS } from '../../../../common/utils/ncts-constants'
import { ConsignmentItem } from '../../schemas/consignmentItemSchema'
import { excludeDeleted } from '../../../../common/utils/common-util'

const R0020Validator = {
  hasAtLeastOnePreviousDocumentValidForConsignmentItem: (formData: DeclarationForm, consignmentItem: ConsignmentItem): boolean => {
    if (formData.declarationType !== 'T2'
      && formData.declarationType !== 'T2F'
      && consignmentItem.declarationType !== 'T2'
      && consignmentItem.declarationType !== 'T2F') {
      return true
    }

    const departureCountry = removePrefix(formData.departureCustomsOffice, 'DEPARTURE_OFFICE_', 2) ?? ''
    if (!CL112_CTC_COUNTRIES.includes(departureCountry)) {
      return true
    }

    const hasRequiredDocumentWithinItem = consignmentItem.previousDocument
      .filter(excludeDeleted)
      .some((
        prevDoc,
      ) => CL178_PREVIOUS_DOCUMENT_UNION_GOODS.includes(removePrefix(prevDoc.documentType, 'PREVIOUS_DOCUMENT_TYPE_') ?? ''))

    if (hasRequiredDocumentWithinItem) {
      return true
    }

    const hasRequiredDocumentWithinConsignment = formData.previousDocument
      .filter(excludeDeleted)
      .some((
        prevDoc,
      ) => CL178_PREVIOUS_DOCUMENT_UNION_GOODS.includes(removePrefix(prevDoc.documentType, 'PREVIOUS_DOCUMENT_TYPE_') ?? ''))

    return hasRequiredDocumentWithinConsignment
  },
} as const

export default R0020Validator
