import React, { useContext, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'

import { Page, Pages } from 'routes/Pages'
import { Dropdown } from 'react-bootstrap'
import { useStore } from 'react-redux'
import { useTranslation } from 'react-i18next'
import AuthenticationService from '../services/auth.service'
import { UserContext } from '../context/UserContext'
import { tardekConfig } from '../config'

// eslint-disable-next-line react/require-default-props,react/no-unused-prop-types
function Header(props: { isHidden: boolean | undefined; notification: string }) {
  const { isHidden, notification } = props
  const { t, i18n } = useTranslation()
  const history = useNavigate()
  const store = useStore()

  const t1Locale = localStorage.getItem('t1_locale')
  const [currentLanguage, setCurrentLanguage] = useState<string>(t1Locale !== null ? t1Locale : 'EN')
  const [isMobileMenuOpen, toggleMobileMenu] = useState<boolean>(false)
  const [isMenuOpen, toggleMenu] = useState<boolean>(false)
  const [isLanguageDropdownOpen, setLanguageDropdownOpen] = useState<boolean>(false)
  const countries = process.env.REACT_APP_ENV === 'live' ? tardekConfig.phase5CountriesLive : tardekConfig.phase5CountriesTest

  const { user } = useContext(UserContext)
  const populateNav = Pages.map((item: Page) => (
    item.showInNav
      && (item.roles == null || item.roles.findIndex((role: string) => role === user?.role) !== -1)
      ? (
        <NavLink
          key={item.url}
          to={`/${item.url}`}
          className={({ isActive }) => (isActive ? 'active' : undefined)}
        >
          <i className={`${item.url}-icon mb-1`} />
          <span>{t('pages.page', { context: item.url })}</span>
        </NavLink>
      )
      : ''))

  const populateMobileNav = Pages.map((item: Page) => (
    item.showInNav
    && (item.roles == null || item.roles.findIndex((role: string) => role === user?.role) !== -1)
      ? (
        <NavLink
          key={item.url}
          to={`/${item.url}`}
          className={({ isActive }) => (isActive ? 'active' : undefined)}
          onClick={() => toggleMobileMenu(false)}
        >
          <i className={`${item.url}-icon mb-1`} />
          {item.url}
        </NavLink>
      )
      : ''))

  const languageDropdown = () => (
    <Dropdown onToggle={() => setLanguageDropdownOpen(!isLanguageDropdownOpen)}>
      <Dropdown.Toggle
        variant="dark"
        className="header__profile-button hide-caret px-3"
        id="dropdown-basic"
      >
        {
          currentLanguage
        }
        <i className={`ps-2 fal text-white ${isLanguageDropdownOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="shadow mw-20">
        <Dropdown.Item onClick={() => {
          setCurrentLanguage('EN')
          i18n.changeLanguage('en')
        }}
        >
          EN
        </Dropdown.Item>
        <Dropdown.Item onClick={() => {
          setCurrentLanguage('RU')
          i18n.changeLanguage('ru')
        }}
        >
          RU
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )

  return (
    <>
      <header className={(isHidden ? 'd-none' : '') + (isMobileMenuOpen ? ' bg-primary' : '')}>
        <div className="brand">
          {user ? (
            <Link
              to="/transits"
            >
              TD
            </Link>
          ) : (
            <Link
              to="/login"
            >
              TD
            </Link>
          )}

        </div>
        <nav className="header__nav d-none d-md-flex me-auto">
          {populateNav}
        </nav>
        <div className="d-none d-md-flex">
          {languageDropdown()}
          {localStorage.getItem('accessToken') !== null && (
            <Dropdown onToggle={() => toggleMenu(!isMenuOpen)}>
              <Dropdown.Toggle
                variant="dark"
                className="header__profile-button hide-caret"
                id="dropdown-basic"
              >
                {
                  user
                && (
                  <div className="header__user">
                    {`${user?.firstname} ${user?.lastname}`}
                    <span className="header__user-company">
                      {user?.customerName}
                    </span>
                  </div>
                )
                }
                <i className={`fal text-white ${isMenuOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow">
                <Dropdown.Item onClick={() => {
                  history('/profile')
                  store.dispatch({ type: 'RESET_DECLARATION' })
                }}
                >
                  {t('buttons.profile')}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => {
                  AuthenticationService.logout()
                    .then((res) => {
                      if (res && res.status === 204) {
                        if (localStorage.getItem('tardekSearch') !== null) {
                          localStorage.removeItem('tardekSearch')
                        }
                        if (localStorage.getItem('search') !== null) {
                          localStorage.removeItem('search')
                        }
                        store.dispatch({ type: 'RESET_DECLARATION' })
                        localStorage.removeItem('accessToken')
                        window.location.replace('/login')
                      }
                    })
                    .catch((err) => {
                      store.dispatch({ type: 'RESET_DECLARATION' })
                      localStorage.removeItem('accessToken')
                      window.location.replace('/login')
                      // eslint-disable-next-line no-console
                      console.error(err)
                    })
                }}
                >
                  {t('buttons.logOut')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
        <div className="d-flex d-md-none">
          <button
            type="button"
            className={`d-block d-md-none btn btn-link btn-lg me-2 ${isMobileMenuOpen ? 'text-dark' : 'text-primary'}`}
            onClick={() => toggleMobileMenu(!isMobileMenuOpen)}
          >
            <i className={`fal fa-2x ${isMobileMenuOpen ? 'fa-times' : 'fa-bars'}`} />
          </button>
        </div>
      </header>

      {window.location.pathname !== '/' && notification !== '' && notification !== null && (
        <div className="notification" style={{ top: `${isHidden ? '0px' : '60px'}` }}>
          <div className="alert" role="alert">
            <strong className="pe-3">
              {t('configuration.alert')}
              !
            </strong>
            { notification }
          </div>
        </div>
      )}
      {
        isMobileMenuOpen && (
          <div className={`header__mobile-menu pb-5 ${isMobileMenuOpen ? 'open' : ''}`}>
            <nav className="header__nav d-flex flex-column pb-5">
              <div className="header__nav-header">
                <div className="w-33 d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-link no-underline btn-lg w-100 border-0"
                  >
                    {
                      user
                      && (
                        <div className="header__user">
                          {`${user?.firstname} ${user?.lastname}`}
                          <span className="header__user-company">{user?.customerName}</span>
                        </div>
                      )
                    }
                  </button>
                </div>
                <div className="w-33 d-flex justify-content-center">
                  <button type="button" className="btn btn-dark me-2">
                    <i className="fas fa-exchange-alt" />
                  </button>
                  <button type="button" className="btn btn-dark">
                    <i className="fal fa-sign-out-alt" />
                  </button>
                </div>
              </div>
              <div className="row">
                {populateMobileNav}
              </div>
            </nav>
          </div>
        )
      }
    </>
  )
}

export default Header
