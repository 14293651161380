import { TransportEquipmentType } from '../../schemas/transportEquipmentSchema'
import { excludeDeleted } from '../../../../common/utils/common-util'
import { RuleResult } from '../../../../../../types/DeclarationP5'

const R0448Validator = {
  validateSealsRequirement: (transportEquipment: TransportEquipmentType): RuleResult => {
    const seals = transportEquipment.seals.filter(excludeDeleted)
    if (!transportEquipment.containerIdentificationNumber && seals.length === 0) {
      return 'REQUIRED'
    }

    return 'OPTIONAL'
  },
}

export default R0448Validator
