import { DeclarationForm } from '../schemas/declarationFormSchema'
import { excludeDeleted, nonNullConsignee, nonNullConsignorIgnoringContactPerson } from '../../../common/utils/common-util'

const CustomValidators = {
  hasAnyHouseConsignmentConsigneeNonNull: (formData: DeclarationForm) => formData.houseConsignment.filter(excludeDeleted)
    .some((house) => house.consignee !== null && nonNullConsignee(house.consignee)),

  hasAnyHouseConsignmentConsignorNonNull: (formData: DeclarationForm) => formData.houseConsignment.filter(excludeDeleted)
    .some((house) => house.consignor != null && nonNullConsignorIgnoringContactPerson(house.consignor)),

  hasAnyConsignmentItemConsigneeNonNull: (formData: DeclarationForm) => formData.houseConsignment
    .filter(excludeDeleted)
    .flatMap((house) => house.consignmentItem)
    .filter(excludeDeleted)
    .some((consignmentItem) => consignmentItem.consignee !== null && nonNullConsignee(consignmentItem.consignee)),
}

export default CustomValidators
