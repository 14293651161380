import { RuleResult } from 'types/DeclarationP5'
import { DeclarationForm } from '../schemas/declarationFormSchema'
import { removePrefix } from '../../../common/utils/classifier-util'
import { excludeDeleted } from '../../../common/utils/common-util'
import { isTransitionalPeriodActive } from '../../../common/utils/ncts-constants'
import B1891Validator from './transitional/B1891'

const C0826Validator = {
  validateDepartureTransportMeansForConsignmentRequirement: (formData: DeclarationForm): RuleResult => {
    if (isTransitionalPeriodActive()) {
      return B1891Validator.validateDepartureTransportMeansRequirement(formData.containerIndicator, formData.inlandModeOfTransport)
    }

    if (removePrefix(formData.inlandModeOfTransport, 'TRANSPORT_MODE_', 1) === '5') {
      return 'NOT_ALLOWED'
    }
    return 'OPTIONAL'
  },
  validateDepartureTransportMeansForHouseConsignmentRequirement: (formData: DeclarationForm): RuleResult => {
    if (removePrefix(formData.inlandModeOfTransport, 'TRANSPORT_MODE_', 1) === '5') {
      return 'NOT_ALLOWED'
    }
    if (formData.departureTransportMeans.filter(excludeDeleted).length > 0) {
      return 'NOT_ALLOWED'
    }

    return 'OPTIONAL'
  },

} as const

export default C0826Validator
