import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { excludeDeleted } from '../../../../common/utils/common-util'
import { removePrefix } from '../../../../common/utils/classifier-util'
import { CL010_CL553_EU_COUNTRY_CODES, CL112_CTC_COUNTRIES } from '../../../../common/utils/ncts-constants'
import B1813Validator from '../transitional/B1813'

const R0006Validator = {
  isDestinationCountryInTransitCountriesRequired: (formData: DeclarationForm) => {
    if (!B1813Validator.isValidationRequired()) return false

    const {
      destinationCustomsOffice,
      transitCustomsOffices,
    } = formData
    const destinationOfficeCountry = removePrefix(destinationCustomsOffice, 'DESTINATION_OFFICE_', 2)
    if (destinationOfficeCountry != null && !CL112_CTC_COUNTRIES.includes(destinationOfficeCountry)) {
      return false
    }

    return !transitCustomsOffices.filter(excludeDeleted)
      .some((transitCustomsOffice) => {
        const transitOfficeCountry = removePrefix(transitCustomsOffice.referenceNumber, 'TRANSIT_OFFICE_', 2)

        return transitOfficeCountry === destinationOfficeCountry
      })
  },
  isTransitCountryInCommunityCountriesRequired: (formData: DeclarationForm) => {
    if (!B1813Validator.isValidationRequired()) return false

    const {
      departureCustomsOffice,
      destinationCustomsOffice,
      transitCustomsOffices,
    } = formData
    const departureOfficeCountry = removePrefix(departureCustomsOffice, 'DEPARTURE_OFFICE_', 2)
    const destinationOfficeCountry = removePrefix(destinationCustomsOffice, 'DESTINATION_OFFICE_', 2)

    if (departureOfficeCountry == null || destinationOfficeCountry == null) return false

    if (CL112_CTC_COUNTRIES.includes(departureOfficeCountry) && CL010_CL553_EU_COUNTRY_CODES.includes(destinationOfficeCountry)) {
      return !transitCustomsOffices.filter(excludeDeleted)
        .some((transitCustomsOffice) => {
          const transitOfficeCountry = removePrefix(transitCustomsOffice.referenceNumber, 'TRANSIT_OFFICE_', 2)

          return transitOfficeCountry != null && CL010_CL553_EU_COUNTRY_CODES.includes(transitOfficeCountry)
        })
    }

    return false
  },
}

export default R0006Validator
