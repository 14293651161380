import React, {
  LegacyRef, useContext, useMemo, useState,
} from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useWatch } from 'react-hook-form'
import { DraggableProvidedDraggableProps, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd'
import { ConsignmentItem } from '../../form/schemas/consignmentItemSchema'
import PreviousDocuments from '../../components/PreviousDocuments'
import SupportingDocuments from '../../components/SupportingDocuments'
import GoodsData from './GoodsData'
import Packaging from './Packaging'
import AdditionalSupplyChainActors from '../transitParties/AdditionalSupplyChainActors'
import TransportDocuments from '../../components/TransportDocuments'
import FormField from '../../components/FormField'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import AdditionalInformations from '../../components/AdditionalInformations'
import AdditionalReferences from '../../components/AdditionalReferences'
import { isTransitionalPeriodActive } from '../../../common/utils/ncts-constants'
import DeclarationTabLayout from '../../layout/DeclarationTabLayout'
import Consignee from '../transitParties/Consignee'
import { TransitOperationContext } from '../../hooks/useTransitOperationContext'

interface ConsignmentItemProps {
  arrayKey: string
  index: number
  houseConsignmentIndex: number
  tableRowRemove: (index: number) => void
  tableRowDuplicate: (index: number) => void
  innerRef: LegacyRef<HTMLTableRowElement>
  draggableProps?: DraggableProvidedDraggableProps
  isDragging?: boolean
}

const getItemStyle = (isDragging: boolean | undefined, draggingStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
  display: isDragging ? 'table' : '',
  ...draggingStyle,
})

function GoodsTableRow({
  arrayKey, index, tableRowRemove, houseConsignmentIndex, tableRowDuplicate, innerRef, isDragging, draggableProps, ...rest
}: Readonly<ConsignmentItemProps>) {
  const { t } = useTranslation()
  const consignmentItemScope = `houseConsignment.${houseConsignmentIndex}.consignmentItem.${index}` as const
  const itemFields = [
    `${consignmentItemScope}.commodityHarmonizedSystemSubHeadingCode`,
    `${consignmentItemScope}.commodityDescriptionOfGoods`,
  ]
  const [isRowClosed, setIsRowClosed] = useState<boolean>(true)
  const consignmentItemValue: ConsignmentItem | null = useWatch({ name: consignmentItemScope, defaultValue: null })
  const { nctsErrors } = useContext(TransitOperationContext)

  const hasError = useMemo(() => {
    const fieldError = nctsErrors
      .find((error) => itemFields.includes(error.field))
    return fieldError ?? false
  }, [nctsErrors])

  return (
    <>
      <tr
        ref={innerRef}
        {...draggableProps}
        {...rest}
        style={getItemStyle(isDragging, draggableProps?.style)}
        className={hasError ? 'invalid-row' : ''}
      >
        <td className="sequence-nr">
          {consignmentItemValue !== null && consignmentItemValue.sequenceNumber + 1}
        </td>
        <td>
          {consignmentItemValue?.commodityDescriptionOfGoods?.substring(0, 110)}
        </td>
        <td>
          {consignmentItemValue?.commodityHarmonizedSystemSubHeadingCode}
        </td>
        <td>
          {consignmentItemValue?.commodityCombinedNomenclatureCode}
        </td>
        <td>
          {consignmentItemValue?.goodsMeasureGrossMass}
        </td>
        <td className="text-end">
          <Button
            type="button"
            className="btn-sm"
            variant="warning"
            onClick={() => tableRowDuplicate(index)}
          >
            <i className="fal fa-clone fa-flip-horizontal" />
          </Button>
          <Button
            type="button"
            className="btn-sm mx-2"
            data-bs-toggle="collapse"
            data-bs-target={`#row_${index}`}
            aria-expanded={isRowClosed}
            aria-controls={`row_${index}`}
            tabIndex={0}
            variant="dark"
            onClick={() => setIsRowClosed((prevState) => !prevState)}
            onKeyDown={() => setIsRowClosed((prevState) => !prevState)}
          >
            <i className="fa fa-pencil" />
          </Button>
          <Button
            type="button"
            className="btn-sm"
            variant="danger"
            onClick={() => tableRowRemove(index)}
          >
            <i className="fa fa-trash" />
          </Button>

        </td>
      </tr>
      <tr className={`collapse ${isRowClosed ? '' : 'show'}`} id={`row_${index}`} key={`consignmentItemRow_${arrayKey}`}>
        <td className="p-0" colSpan={6} key={`consignmentItemRow_${arrayKey}`} id={`row_${index}`}>
          <table className="table table-borderless mb-0 w-100">
            <tbody>
              <tr>
                <td colSpan={8}>
                  {!isRowClosed
                    ? (
                      <DeclarationTabLayout
                        start={(
                          <>
                            <section className="py-3">
                              <CollapsibleColumn
                                scope="houseConsignment"
                                columnId="declaration-house-consignment"
                                headingLabel={t('declaration.p5.houseConsignment')}
                              >
                                <FormField labelKey="declaration.p5.sequenceNumber">
                                  <span>{houseConsignmentIndex + 1}</span>
                                </FormField>
                              </CollapsibleColumn>
                            </section>
                            {!isRowClosed ? <GoodsData scope={`${consignmentItemScope}`} /> : null}
                          </>
                        )}
                        centerLeft={(
                          <>
                            {!isRowClosed ? <Packaging scope={`${consignmentItemScope}.packaging`} /> : null}
                            {isTransitionalPeriodActive() && !isRowClosed ? <Consignee scope={`${consignmentItemScope}.consignee`} /> : null}
                          </>
                        )}
                        centerRight={(
                          <>
                            {isTransitionalPeriodActive() && !isRowClosed ? (
                              <TransportDocuments scope={`${consignmentItemScope}.transportDocument`} />
                            )
                              : null}
                            {!isRowClosed ? <SupportingDocuments scope={`${consignmentItemScope}.supportingDocument`} /> : null}
                            {!isRowClosed ? <PreviousDocuments scope={`${consignmentItemScope}.previousDocument`} /> : null}
                          </>
                        )}
                        end={(
                          <>
                            {!isRowClosed
                              ? <AdditionalSupplyChainActors scope={`${consignmentItemScope}.additionalSupplyChainActor`} />
                              : null}
                            {!isRowClosed ? <AdditionalReferences scope={`${consignmentItemScope}.additionalReference`} /> : null}
                            {!isRowClosed ? <AdditionalInformations scope={`${consignmentItemScope}.additionalInformation`} /> : null}
                          </>
                        )}
                      />
                    ) : null}
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </>
  )
}

export default GoodsTableRow
