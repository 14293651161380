/* tslint:disable */
/* eslint-disable */
/**
 * Tardek API Endpoints
 * This Is a documentation for T1 Tardek API Endpoints. <br />
 *
 * OpenAPI spec version: 1.0.0
 * Contact: info@bitweb.ee
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

 /**
 *
 *
 * @export
 * @interface DocumentRequest
 */
export interface DocumentRequest {

    /**
     * Document ID
     *
     * @type {number}
     * @memberof DocumentRequest
     * @example 1
     */
    id: number | null;
    /**
     * Internal type of the document
     *
     * @type {string}
     * @memberof DocumentRequest
     * @example TRANSPORT
     */
    type: DocumentRequestTypeEnum;

    /**
     * Document Reference Number
     *
     * @type {string}
     * @memberof DocumentRequest
     */
    referenceNumber?: string;

    /**
     * Document Transport Document Reference Number
     *
     * @type {string}
     * @memberof DocumentRequest
     */
    transportDocumentReferenceNumber?: string;

    /**
     * Document Complement of information
     *
     * @type {string}
     * @memberof DocumentRequest
     */
    complementOfInformation?: string;

    /**
     * Document line number
     *
     * @type {string}
     * @memberof DocumentRequest
     */
    documentLineItemNumber?: string;

    /**
     * Document Type
     *
     * @type {string}
     * @memberof DocumentRequest
     * @example PREVIOUS_DOCUMENT_TYPE_EE11
     */
    documentType: string;

    /**
     * Consignment Item ID
     *
     * @type {number}
     * @memberof DocumentRequest
     * @example 1
     */
    consignmentItemId?: number | null;

    /**
     * Consignment ID
     *
     * @type {number}
     * @memberof DocumentRequest
     * @example 1
     */
    consignmentId?: number | null;

    /**
     * House consignment ID
     *
     * @type {number}
     * @memberof DocumentRequest
     * @example 1
     */
    houseConsignmentId?: number | null;

    /**
     * Transit Operation ID
     *
     * @type {number}
     * @memberof DocumentRequest
     * @example 1
     */
    transitOperationId: number;

    /**
     * Document Sequence Number
     *
     * @type {number}
     * @memberof DocumentRequest
     * @example 0
     */
    sequenceNumber: number;

    /**
     * Document Previous Procedure Type
     *
     * @type {string}
     * @memberof DocumentRequest
     */
    procedureType?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum DocumentRequestTypeEnum {
    PREVIOUS = 'PREVIOUS',
    SUPPORTING = 'SUPPORTING',
    TRANSPORT = 'TRANSPORT',
    NOTE = 'NOTE',
}

