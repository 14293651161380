import React, { useContext, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormScope } from 'types/DeclarationP5'
import { toast } from 'react-toastify'
import { TransitOperationContext } from '../hooks/useTransitOperationContext'
import { cyrillicRegex } from '../../common/utils/ncts-constants'

export interface InputProps<T extends FormScope>
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  field: T
  labelKey: string
}

function Input<T extends FormScope>({
  field,
  labelKey,
  type,
  ...rest
}: InputProps<T>) {
  const { register, getFieldState } = useFormContext()
  const { t } = useTranslation()
  const { fieldRules } = useContext(TransitOperationContext)
  const isCurrentPathAnItemInArray = useMemo(() => {
    const parentPath = field.slice(0, field.lastIndexOf('.'))
    return !Number.isNaN(Number(parentPath.at(parentPath.length - 1)))
  }, [field])

  const rule = useMemo(
    () => {
      const separatorBeforeParentPath = field.lastIndexOf('.')
      if (separatorBeforeParentPath > 0) {
        let parentPath = field.slice(0, separatorBeforeParentPath)
        if (isCurrentPathAnItemInArray) {
          parentPath = parentPath.slice(0, parentPath.lastIndexOf('.'))
        }
        const parentRule = fieldRules.find((item) => item.path === parentPath)?.ruleResult
        if (parentRule === 'NOT_ALLOWED') {
          return 'NOT_ALLOWED'
        }
      }

      return fieldRules.find((item) => item.path === field)?.ruleResult ?? 'OPTIONAL'
    },
    [fieldRules],
  )

  if (type === 'radio') {
    return (
      <React.Fragment key={`${field}_${type}`}>
        <div className="form-check form-check-inline mb-0" key={`${field}_${type}`}>
          <input
            id={`${field}_${rest.value}`}
            key={`${field}`}
            type={type}
            disabled={rule === 'NOT_ALLOWED' || rest.disabled}
            className={`form-check-input ${getFieldState(`${field}`).invalid ? 'is-invalid' : ''
            } ${
              rule === 'NOT_ALLOWED' ? 'text-muted cursor--not-allowed' : ''
            }`}
            {...register(`${field}` as const)}
            {...rest}
          />
          <label className="form-check-label" htmlFor={`${field}_${rest.value}`}>
            {t(labelKey)}
          </label>
        </div>
      </React.Fragment>
    )
  }
  if (type === 'textarea') {
    return (
      <React.Fragment key={`${field}_${type}`}>
        <textarea
          id={`${field}_${type}`}
          disabled={rule === 'NOT_ALLOWED' || rest.disabled}
          className={`form-control ${
            getFieldState(`${field}`).invalid ? 'is-invalid' : ''
          } ${
            rule === 'NOT_ALLOWED' ? 'text-muted cursor--not-allowed' : ''
          }`}
          {...register(`${field}` as const)}
        />
      </React.Fragment>
    )
  }
  if (type === 'datetime-local') {
    return (
      <React.Fragment key={`${field}_${type}`}>
        <input
          id={`${field}_${type}`}
          type={type}
          disabled={rule === 'NOT_ALLOWED' ? true : rest.disabled}
          className={`form-control ${getFieldState(`${field}`).invalid ? 'is-invalid' : ''
          } ${
            rule === 'NOT_ALLOWED' ? 'text-muted cursor--not-allowed' : ''
          }`}
          {...register(`${field}` as const)}
          {...rest}
        />
      </React.Fragment>
    )
  }

  const registerFormField = register(`${field}` as const, {
    onChange: (e: any) => {
      if (e.target.value !== '' && !cyrillicRegex.test(e.target.value)) {
        toast.error(t('messages.forbiddenCharacter', { 0: e.target.value }))
        e.target.value = ''
      }
    },
    valueAsNumber: type === 'number',
  })

  return (
    <React.Fragment key={`${field}_${type}`}>
      <input
        id={`${field}_${type}`}
        type={type}
        disabled={rule === 'NOT_ALLOWED' ? true : rest.disabled}
        className={`form-control ${getFieldState(`${field}`).invalid ? 'is-invalid' : ''
        } ${
          rule === 'NOT_ALLOWED' ? 'text-muted cursor--not-allowed' : ''
        }`}
        {...registerFormField}
        {...rest}
      />

    </React.Fragment>
  )
}

export default Input
