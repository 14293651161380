import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { removePrefix, TRANSIT_OFFICE_CODE_PREFIX } from '../../../../common/utils/classifier-util'
import { CustomsOfficeOfTransitDeclared } from '../../schemas/commonConsignmentSchemas'

const R0906Validator = {
  isCustomsOfficeOfTransitValid: (
    formData: DeclarationForm,
    transitCustomsOffice: CustomsOfficeOfTransitDeclared,
  ): boolean => {
    const destinationCountry = removePrefix(formData.destinationCustomsOffice, 'DESTINATION_OFFICE_', 2) ?? ''
    if (destinationCountry !== 'AD') {
      return true
    }

    return removePrefix(transitCustomsOffice.referenceNumber, TRANSIT_OFFICE_CODE_PREFIX, 2) === 'AD'
  },
}

export default R0906Validator
