import { excludeDeleted, hasText } from '../../../../common/utils/common-util'
import { HouseConsignmentType } from '../../schemas/houseConsignmentSchema'
import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { ConsignmentItem } from '../../schemas/consignmentItemSchema'
import { CL234_PREVIOUS_DOCUMENT_EXCISE, isTransitionalPeriodActive } from '../../../../common/utils/ncts-constants'
import { removePrefix } from '../../../../common/utils/classifier-util'
import B1922Validator from '../transitional/B1922'

const R0601Validator = {
  hasCorrectDeclarationTypeForProvidedDocument: (
    consignmentItem: ConsignmentItem,
    houseConsignment: HouseConsignmentType,
    formData: DeclarationForm,
  ): boolean => {
    if (isTransitionalPeriodActive()) {
      return B1922Validator.isDeclarationTypeCorrectDuringTransitionPeriod(formData, consignmentItem)
    }

    const isAdditionalReferencePresent = consignmentItem.additionalReference
      .filter(excludeDeleted)
      .some((ref) => CL234_PREVIOUS_DOCUMENT_EXCISE
        .includes(removePrefix(ref.type, 'ADDITIONAL_REFERENCE_TYPE_') ?? ''))

    if (isAdditionalReferencePresent && houseConsignment.previousDocument
      .filter(excludeDeleted)
      .some((doc) => removePrefix(doc.documentType, 'PREVIOUS_DOCUMENT_TYPE_') === 'N830')
    ) {
      if (hasText(consignmentItem.declarationType)) {
        return consignmentItem.declarationType === 'T1'
      }
      return formData.declarationType === 'T1' || consignmentItem.declarationType === 'TIR'
    }

    if (consignmentItem.supportingDocument
      .filter(excludeDeleted)
      .some((doc) => CL234_PREVIOUS_DOCUMENT_EXCISE
        .includes(removePrefix(doc.documentType, 'SUPPORTING_DOCUMENT_TYPE_') ?? ''))
    ) {
      if (hasText(consignmentItem.declarationType)) {
        return consignmentItem.declarationType === 'T2'
      }
      return formData.declarationType === 'T2' || formData.declarationType === 'TIR'
    }

    return true
  },
} as const

export default R0601Validator
