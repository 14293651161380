import React, {
  useEffect, useMemo, useRef, useState,
} from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ArrayScope } from 'types/DeclarationP5'
import { useFormContext } from 'react-hook-form'
import {
  DragDropContext, Draggable, Droppable, DropResult,
} from 'react-beautiful-dnd'
import { ConsignmentItem, DangerousGoodsType, PackagingType } from '../../form/schemas/consignmentItemSchema'
import GoodsTableRow from './GoodsTableRow'
import useFieldArrayActionHelper from '../../services/useFieldArrayActionHelper'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'

import { blankConsignmentItem } from '../../form'
import { excludeDeleted } from '../../../common/utils/common-util'
import { AdditionalInformation, AdditionalReference, AdditionalSupplyChainActor } from '../../form/schemas/commonConsignmentSchemas'
import { PreviousDocument, SupportingDocument, TransportDocument } from '../../form/schemas/documentSchemas'

function GoodsTable({
  houseConsignmentIndex,
}: {
  houseConsignmentIndex: number
}) {
  const { t } = useTranslation()
  const { getValues } = useFormContext<DeclarationForm>()

  const scope: ArrayScope = `houseConsignment.${houseConsignmentIndex}.consignmentItem`
  const {
    addAction,
    removeAction,
    getFields,
    moveAction,
  } = useFieldArrayActionHelper<ConsignmentItem>({
    blankItem: blankConsignmentItem,
    name: scope,
  })

  function calculateNextGoodsItemNumber() {
    const form = getValues()
    let declarationGoodsItemNumberSequence = 0

    // TODO handle case when using multiple house consignments and item is added to middle, remaining house items should update
    form.houseConsignment.filter(excludeDeleted).forEach((house) => {
      house.consignmentItem.filter(excludeDeleted).forEach(() => {
        declarationGoodsItemNumberSequence += 1
      })
    })

    return declarationGoodsItemNumberSequence
  }

  const duplicateItem = (index: number) => {
    const consignmentItemScope = `${scope}.${index}` as const
    const cloneItem: ConsignmentItem = structuredClone(getValues(consignmentItemScope))
    const declarationGoodsItemNumber = calculateNextGoodsItemNumber()

    addAction({
      ...cloneItem,
      id: null,
      consignee: cloneItem.consignee !== null ? {
        ...cloneItem.consignee,
        id: null,
        address: {
          ...cloneItem.consignee.address,
          id: null,
          streetAndNumber: cloneItem.consignee?.address?.streetAndNumber ?? '',
          postcode: cloneItem.consignee?.address?.postcode ?? '',
          city: cloneItem.consignee?.address?.city ?? '',
          country: cloneItem.consignee?.address?.country ?? '',
        },
      } : null,
      declarationGoodsItemNumber,
      dangerousGoods: cloneItem.dangerousGoods
        .filter(excludeDeleted)
        .map((entry: DangerousGoodsType, entryIndex: number) => ({ ...entry, id: null, sequenceNumber: entryIndex })),
      additionalSupplyChainActor: cloneItem.additionalSupplyChainActor
        .filter(excludeDeleted)
        .map((entry: AdditionalSupplyChainActor, entryIndex: number) => ({ ...entry, id: null, sequenceNumber: entryIndex })),
      packaging: cloneItem.packaging
        .filter(excludeDeleted)
        .map((entry: PackagingType, entryIndex: number) => ({ ...entry, id: null, sequenceNumber: entryIndex })),
      previousDocument: cloneItem.previousDocument
        .filter(excludeDeleted)
        .map((entry: PreviousDocument, entryIndex: number) => ({ ...entry, id: null, sequenceNumber: entryIndex })),
      supportingDocument: cloneItem.supportingDocument
        .filter(excludeDeleted)
        .map((entry: SupportingDocument, entryIndex: number) => ({ ...entry, id: null, sequenceNumber: entryIndex })),
      transportDocument: cloneItem.transportDocument
        .filter(excludeDeleted)
        .map((entry: TransportDocument, entryIndex: number) => ({ ...entry, id: null, sequenceNumber: entryIndex })),
      additionalReference: cloneItem.additionalReference
        .filter(excludeDeleted)
        .map((entry: AdditionalReference, entryIndex: number) => ({ ...entry, id: null, sequenceNumber: entryIndex })),
      additionalInformation: cloneItem.additionalInformation
        .filter(excludeDeleted)
        .map((entry: AdditionalInformation, entryIndex: number) => ({ ...entry, id: null, sequenceNumber: entryIndex })),
    })
  }

  const handleDrop = (droppedItem: DropResult) => {
    const { source, destination } = droppedItem
    if (!destination) {
      return
    }
    moveAction(source.index, destination.index)
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="table-responsive">
            <table className="table table-responsive table-striped table-bordered table-declaration">
              <thead>
                <tr>
                  <th className="sequence-nr">{t('declaration.p5.sequenceNumber')}</th>
                  <th>{t('declaration.p5.descriptionOfGoods')}</th>
                  <th>{t('declaration.p5.commodityHarmonizedSystemSubHeadingCode')}</th>
                  <th>{t('declaration.p5.commodityCombinedNomenclatureCode')}</th>
                  <th>{t('declaration.p5.grossMass')}</th>
                  <th className="col-1">&nbsp;</th>
                </tr>
              </thead>
              <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="table-body">
                  {(provided, snapshot) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                      {getFields().map((rowData, index: number) => !rowData.deleted && (
                        <Draggable draggableId={rowData.id} index={index} key={rowData.id}>
                          {(draggableProvided, draggableSnapshot) => (
                            (
                              <GoodsTableRow
                                index={index}
                                houseConsignmentIndex={houseConsignmentIndex}
                                tableRowRemove={(deletedIndex) => {
                                  removeAction(deletedIndex, rowData)
                                }}
                                tableRowDuplicate={(duplicateIndex: number) => {
                                  duplicateItem(duplicateIndex)
                                }}
                                key={rowData.id}
                                arrayKey={rowData.id}
                                {...draggableProvided.dragHandleProps}
                                innerRef={draggableProvided.innerRef}
                                draggableProps={draggableProvided.draggableProps}
                                isDragging={draggableSnapshot.isDragging}
                              />
                            )
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </table>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-end">
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              const declarationGoodsItemNumber = calculateNextGoodsItemNumber()

              addAction({
                ...blankConsignmentItem,
                declarationGoodsItemNumber,
                sequenceNumber: declarationGoodsItemNumber,
              })
            }}
          >
            <i className="fa fa-plus me-2" />
            {t('declaration.p5.addRow')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default GoodsTable
