import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { IDENTIFICATION_TYPE_CODE_PREFIX, removePrefix, TRANSPORT_MODE_CODE_PREFIX } from '../../../../common/utils/classifier-util'
import { DepartureTransportMeansType } from '../../schemas/commonConsignmentSchemas'
import { ROAD_TRANSPORT_MODE } from '../../../../common/utils/ncts-constants'

const R0474Validator = {
  isFirstDepartureMeansTypeOfIdentificationCorrectForRoadTransport: (
    formData: DeclarationForm,
    departureTransportMeans: DepartureTransportMeansType,
    itemIndex: number,
  ): boolean => {
    const inlandTransportMode = removePrefix(formData.inlandModeOfTransport, TRANSPORT_MODE_CODE_PREFIX, 1) ?? ''
    if (inlandTransportMode !== ROAD_TRANSPORT_MODE) return true
    if (itemIndex !== 0) return true

    return removePrefix(departureTransportMeans.typeOfIdentification, IDENTIFICATION_TYPE_CODE_PREFIX, 2) === '30'
  },
}

export default R0474Validator
