import React, { useRef } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { useTranslation } from 'react-i18next'
import Button from 'react-bootstrap/Button'
import GoodsTable from '../goods/GoodsTable'
import GoodsImportModal from '../goods/GoodsImportModal'
import useConsignmentItemImport from '../../hooks/useConsignmentItemImport'

interface HouseConsignmentGoodsSidebarProps {
  isVisible: boolean
  houseConsignmentIndex: number
  toggleVisibility: () => void
}

function HouseConsignmentGoodsSidebar({
  isVisible,
  houseConsignmentIndex,
  toggleVisibility,
}: HouseConsignmentGoodsSidebarProps) {
  const { t } = useTranslation()

  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const hiddenAnchor = useRef<HTMLAnchorElement>(null)
  const {
    customColumnMap,
    setCustomColumnMap,
    toggleMappingModalVisibility,
    customHeaderMappingModalVisibility,
    parseImportFile,
    startImport,
  } = useConsignmentItemImport(houseConsignmentIndex)

  const handleClick = () => {
    hiddenFileInput.current!.click()
  }
  const downloadExample = () => {
    hiddenAnchor.current!.click()
  }

  const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    const { files } = event.target
    parseImportFile(files).then(() => toggleMappingModalVisibility())
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = ''
    }
  }

  return (
    <Offcanvas
      className="extra-wide goods-sidebar"
      show={isVisible}
      onHide={() => {
        toggleVisibility()
      }}
      placement="end"
    >
      <Offcanvas.Header
        className="border-bottom"
        closeButton
      >
        <div className="d-flex align-items-end ">
          <h1 className="text-dark me-3">
            {t('declaration.p5.goodsSidebar.title', { houseConsignmentSequenceNumber: houseConsignmentIndex + 1 })}
          </h1>
        </div>
        <div className="d-flex ms-auto">
          <Button
            className="me-3"
            variant="outline-dark"
            onClick={downloadExample}
          >
            <a
              className="d-none"
              ref={hiddenAnchor}
              target="_blank"
              href={`${process.env.PUBLIC_URL}/consignmentItems.csv`}
              download="consignmentItems.csv"
              rel="noreferrer"
            >
              {t('consignmentItemImport.example')}
            </a>
            <i className="fal fa-file-csv me-1" />
            <span>
              {t('consignmentItemImport.example')}
            </span>
          </Button>
          <Button
            className="me-3"
            variant="outline-dark"
            onClick={handleClick}
          >
            <input
              style={{ display: 'none' }}
              ref={hiddenFileInput}
              onChange={(event) => uploadFile(event)}
              type="file"
            />
            <i className="fal fa-md fa-file-csv me-1" />
            <span>
              {t('excel.importGoods')}
            </span>
          </Button>
        </div>
      </Offcanvas.Header>

      <Offcanvas.Body key="data-body">
        <div className="container-fluid py-3 goods-sidebar__body">
          <GoodsTable houseConsignmentIndex={houseConsignmentIndex} />
        </div>
        <GoodsImportModal
          customColumnMap={customColumnMap}
          setCustomColumnMap={setCustomColumnMap}
          isVisible={customHeaderMappingModalVisibility}
          toggleVisibility={toggleMappingModalVisibility}
          triggerImport={startImport}
        />
      </Offcanvas.Body>
    </Offcanvas>

  )
}

export default HouseConsignmentGoodsSidebar
