import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { DepartureTransportMeansType } from '../../schemas/commonConsignmentSchemas'
import { IDENTIFICATION_TYPE_CODE_PREFIX, removePrefix, TRANSPORT_MODE_CODE_PREFIX } from '../../../../common/utils/classifier-util'
import { ROAD_TRANSPORT_MODE } from '../../../../common/utils/ncts-constants'

function isSubsequentTransportMean(seqNumber: number): boolean {
  return seqNumber > 1 && seqNumber <= 3
}

const R0476Validator = {
  isOtherDepartureMeansTypeOfIdentificationCorrectForRoadTransport: (
    formData: DeclarationForm,
    departureTransportMeans: DepartureTransportMeansType,
    itemIndex: number,
  ): boolean => {
    const inlandTransportMode = removePrefix(formData.inlandModeOfTransport, TRANSPORT_MODE_CODE_PREFIX, 1) ?? ''
    if (inlandTransportMode !== ROAD_TRANSPORT_MODE) return true
    if (itemIndex === 0) return true
    if (!isSubsequentTransportMean(departureTransportMeans.sequenceNumber)) return true

    return removePrefix(departureTransportMeans.typeOfIdentification, IDENTIFICATION_TYPE_CODE_PREFIX, 2) === '31'
  },
}

export default R0476Validator
