import { z } from 'zod'
import { ConsigneeSchema, ConsignorSchema } from './tradersSchema'
import {
  AdditionalInformationSchema,
  AdditionalReferenceSchema,
  AdditionalSupplyChainActorSchema,
  DepartureTransportMeansSchema,
} from './commonConsignmentSchemas'
import { PreviousDocumentSchema, SupportingDocumentSchema, TransportDocumentSchema } from './documentSchemas'
import ConsignmentItemSchema from './consignmentItemSchema'

const HouseConsignmentSchema = z.object(
  {
    id: z.number().nullable(),
    deleted: z.boolean().optional(),
    sequenceNumber: z.number().nonnegative(),
    grossMass: z.number().min(0).refine((arg) => /^\d{1,11}(\.\d{1,3})?$/.exec(String(arg))),
    referenceNumberUCR: z.string(),
    securityIndicatorFromExportDeclaration: z.string(),
    transportChargesMethodOfPayment: z.string().nullable(),
    consignor: ConsignorSchema.nullable(),
    consignee: ConsigneeSchema.nullable(),

    countryOfDestination: z.string(),
    countryOfDispatch: z.string(),

    additionalSupplyChainActor: z.array(AdditionalSupplyChainActorSchema),
    departureTransportMeans: z.array(DepartureTransportMeansSchema),
    previousDocument: z.array(PreviousDocumentSchema),
    supportingDocument: z.array(SupportingDocumentSchema),
    transportDocument: z.array(TransportDocumentSchema),
    additionalReference: z.array(AdditionalReferenceSchema),
    additionalInformation: z.array(AdditionalInformationSchema),
    consignmentItem: z.array(ConsignmentItemSchema),
  },
)
export type HouseConsignmentType = z.infer<typeof HouseConsignmentSchema>

export default HouseConsignmentSchema
