import React, { useRef } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import GoodsTable from './GoodsTable'
import GoodsImportModal from './GoodsImportModal'
import useConsignmentItemImport from '../../hooks/useConsignmentItemImport'

function DeclarationGoodsTab() {
  const { t } = useTranslation()

  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const hiddenAnchor = useRef<HTMLAnchorElement>(null)
  const houseConsignmentIndex = 0
  const {
    customColumnMap,
    setCustomColumnMap,
    toggleMappingModalVisibility,
    customHeaderMappingModalVisibility,
    parseImportFile,
    startImport,
  } = useConsignmentItemImport(houseConsignmentIndex)

  const handleClick = () => {
    hiddenFileInput.current!.click()
  }
  const downloadExample = () => {
    hiddenAnchor.current!.click()
  }

  const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    const { files } = event.target
    parseImportFile(files).then(() => toggleMappingModalVisibility())
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = ''
    }
  }

  return (
    <div className="container-fluid py-3">
      <GoodsTable houseConsignmentIndex={houseConsignmentIndex} />
      <div className="row pt-1">
        <div className="col-12 text-end">
          <Button
            variant="primary"
            size="sm"
            onClick={handleClick}
          >
            <input
              style={{ display: 'none' }}
              ref={hiddenFileInput}
              onChange={(event) => uploadFile(event)}
              type="file"
            />
            <i className="fal fa-md fa-file-csv me-1" />
            <span>
              {t('excel.importGoods')}
            </span>
          </Button>
        </div>
      </div>
      <div className="row pt-1">
        <div className="col-12 text-end">
          <Button
            variant="primary"
            size="sm"
            onClick={downloadExample}
          >
            <a
              className="d-none"
              ref={hiddenAnchor}
              target="_blank"
              href={`${process.env.PUBLIC_URL}/consignmentItems.csv`}
              download="consignmentItems.csv"
              rel="noreferrer"
            >
              {t('consignmentItemImport.example')}
            </a>
            <i className="fal fa-file-csv me-1" />
            <span>
              {t('consignmentItemImport.example')}
            </span>
          </Button>
        </div>
      </div>
      <GoodsImportModal
        customColumnMap={customColumnMap}
        setCustomColumnMap={setCustomColumnMap}
        isVisible={customHeaderMappingModalVisibility}
        toggleVisibility={toggleMappingModalVisibility}
        triggerImport={startImport}
      />
    </div>
  )
}

export default DeclarationGoodsTab
