import { RuleResult } from 'types/DeclarationP5'
import { DeclarationForm } from '../schemas/declarationFormSchema'
import { isTransitionalPeriodActive } from '../../../common/utils/ncts-constants'
import B1889Validator from './transitional/B1889'

const C0599Validator = {
  validateModeOfTransportAtBorderRequirement: (formData: DeclarationForm): RuleResult => {
    const {
      security,
      additionalDeclarationType,
    } = formData

    if (isTransitionalPeriodActive()) {
      return B1889Validator.validateModeOfTransportAtBorderRequirement(formData)
    }

    if (['1', '2', '3'].includes(security) && additionalDeclarationType === 'A') {
      return 'REQUIRED'
    }

    return 'OPTIONAL'
  },
} as const

export default C0599Validator
