import { RuleResult } from 'types/DeclarationP5'
import { removePrefix } from '../../../../common/utils/classifier-util'
import { CL010_CL553_EU_COUNTRY_CODES } from '../../../../common/utils/ncts-constants'
import { DeclarationForm } from '../../schemas/declarationFormSchema'

const B1889Validator = {
  validateModeOfTransportAtBorderRequirement: (
    formData: DeclarationForm,
  ): RuleResult => {
    const {
      security,
      departureCustomsOffice,
    } = formData

    const departureCustomsOfficeReferenceNumber = removePrefix(departureCustomsOffice, 'DEPARTURE_OFFICE_', 2) ?? ''

    if (['1', '2', '3'].includes(security)
      && departureCustomsOfficeReferenceNumber !== null
      && departureCustomsOfficeReferenceNumber !== ''
      && !CL010_CL553_EU_COUNTRY_CODES.includes(departureCustomsOfficeReferenceNumber)) {
      return 'REQUIRED'
    }

    return 'OPTIONAL'
  },
} as const

export default B1889Validator
