import { RuleResult } from 'types/DeclarationP5'
import { DeclarationForm } from '../schemas/declarationFormSchema'
import { isTransitionalPeriodActive } from '../../../common/utils/ncts-constants'
import B1848Validator from './transitional/B1848'

const C0586Validator = {
  validateCountryOfRoutingRequirement: (formData: DeclarationForm): RuleResult => {
    if (isTransitionalPeriodActive()) {
      return B1848Validator.validateCountryOfRoutingRequirement(formData.security)
    }

    if (formData.bindingItinerary === 'true') {
      return 'REQUIRED'
    }
    if (formData.security === '1' || formData.security === '2' || formData.security === '3') {
      return 'REQUIRED'
    }

    return 'OPTIONAL'
  },
} as const

export default C0586Validator
