import { useEffect } from 'react'
import { UseDeclarationFormReturn } from '../../form'
import useAuthorisationApi from './api'
import { parseAuthorisationsResponse, toCreateOrUpdateAuthorisationRequest } from './mapper'
import { Authorisation } from '../../form/schemas/commonConsignmentSchemas'
import { AuthorisationResponse, CreateOrUpdateAuthorisationRequest } from '../../../common/models'

function useAuthorisation(form: UseDeclarationFormReturn) {
  const {
    trigger,
    getValues, setValue,
    formState: { isValid, isSubmitting }, reset,
    watch,
  } = form

  const transitOperationId: number | null = watch('id')

  const {
    postAuthorisation,
    fetchAuthorisations,
    putAuthorisation,
    deleteAuthorisation,
  } = useAuthorisationApi(transitOperationId, isSubmitting)

  const populateFormAuthorisations = () => {
    if (fetchAuthorisations.isFetching || isSubmitting) {
      return
    }

    const response = fetchAuthorisations.data?.map(parseAuthorisationsResponse)

    reset({
      ...structuredClone(getValues()),
      authorisations: response,
    })
  }

  useEffect(() => {
    populateFormAuthorisations()
  }, [fetchAuthorisations.data])

  function refreshSavedIds(authorisations: Authorisation[], response: AuthorisationResponse[]) {
    authorisations.forEach((item, index) => {
      const savedItem = response.find((responseItem) => responseItem.sequenceNumber === item.sequenceNumber)
      if (savedItem) setValue(`authorisations.${index}.id`, savedItem.id)
    })
  }

  const updateOrCreateAuthorisations = async (isDraft: boolean) => {
    await trigger()

    if (!isDraft && !isValid) return
    const authorisations = getValues('authorisations')
    const updateRequests: CreateOrUpdateAuthorisationRequest[] = []
    const createRequests: CreateOrUpdateAuthorisationRequest[] = []
    if (authorisations.length === 0) return

    authorisations.forEach((authorisation, index) => {
      if (authorisation.id === null) {
        createRequests.push(toCreateOrUpdateAuthorisationRequest(authorisation, transitOperationId!, index))
      } else {
        updateRequests.push(toCreateOrUpdateAuthorisationRequest(authorisation, transitOperationId!, index))
      }
    })
    const responses: AuthorisationResponse[] = []
    if (createRequests.length !== 0) {
      responses.push(...await postAuthorisation.mutateAsync(createRequests))
    }
    if (updateRequests.length !== 0) {
      responses.push(...await putAuthorisation.mutateAsync(updateRequests))
    }

    refreshSavedIds(authorisations, responses)
  }

  const deleteAuthorisations = async () => {
    const promises = getValues('authorisations')
      .filter((item) => item.id !== null && item.id !== undefined && !!item.deleted)
      .map((item) => deleteAuthorisation.mutateAsync(item.id!))

    await Promise.all(promises)
  }

  return {
    updateOrCreateAuthorisations,
    deleteAuthorisations,
  }
}

export default useAuthorisation
