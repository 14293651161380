import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { removePrefix } from '../../../../common/utils/classifier-util'
import { CL010_CL553_EU_COUNTRY_CODES } from '../../../../common/utils/ncts-constants'

const R0904Validator = {
  isDestinationCountryValid: (
    formData: DeclarationForm,
  ) => {
    const departureCountry = removePrefix(formData.departureCustomsOffice, 'DEPARTURE_OFFICE_', 2) ?? ''
    if (departureCountry !== 'AD' && departureCountry !== 'SM') {
      return true
    }

    const destinationCountry = removePrefix(formData.destinationCustomsOffice, 'DESTINATION_OFFICE_', 2) ?? ''

    return CL010_CL553_EU_COUNTRY_CODES.includes(destinationCountry)
  },
}

export default R0904Validator
