import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ArrayScope, FormScope, NestedFieldScope, ObjectScope, ScopeProps,
} from 'types/DeclarationP5'
import CollapsibleColumn from './CollapsibleColumn'
import FormField from './FormField'
import FormFieldArray from './FormFieldArray'
import FormSelect from './FormSelect'
import Input from './Input'
import TransitDocumentFileNames from './TransitDocumentFileNames'
import DocumentDropdownArea from './DocumentDropdownArea'
import { SupportingDocument } from '../form/schemas/documentSchemas'
import useFieldArrayActionHelper from '../services/useFieldArrayActionHelper'
import useCodelist from '../../common/hooks/useCodelist'

import { blankSupportingDocument } from '../form'
// eslint-disable-next-line max-len
const expectedScope = /supportingDocument|(houseConsignment\.\d+\.supportingDocument)|(houseConsignment\.\d+\.consignmentItem\.\d+\.supportingDocument\.\d+)/g
type SupportingDocumentItemScope = NestedFieldScope<`houseConsignment.${number}.consignmentItem.${number}.supportingDocument.${number}`>
const isSupportingDocumentItemScope = (scope: FormScope): scope is 'supportingDocument'
| `houseConsignment.${number}.consignmentItem.${number}.supportingDocument.${number}`
| `houseConsignment.${number}.supportingDocument` => (
  Array.from(scope.matchAll(expectedScope))?.length ?? 0) > 0

function SupportingDocuments({ scope }: ScopeProps<ArrayScope>) {
  const { t } = useTranslation()
  const {
    addAction, removeAction, getFields, arrayRule,
  } = useFieldArrayActionHelper<SupportingDocument>({
    blankItem: blankSupportingDocument,
    name: scope,
  })
  const [, supportingDocumentsOptions] = useCodelist('SUPPORTING_DOCUMENTS')

  return (
    <section className="py-3" key="supportingDocumentsSection">
      <CollapsibleColumn
        key={scope}
        scope={scope}
        columnId="declaration-previous-documents"
        headingLabel={t('declaration.p5.supportingDocuments')}
      >
        {getFields().map((supportingDocument, index) => {
          const innerItemScope: ObjectScope = `${scope}.${index}` as const
          if (!isSupportingDocumentItemScope(innerItemScope)) throw Error('Unable to narrow, invalid scope')

          return (
            <FormFieldArray
              key={`supportingDocument_${supportingDocument.id}`}
              titleKey="declaration.p5.sequenceNumber"
              removeItem={() => removeAction(index, supportingDocument)}
              sequenceNumber={supportingDocument.sequenceNumber + 1}
              hidden={supportingDocument.deleted}
            >
              <FormField labelKey="declaration.p5.supportingDocumentType">
                <FormSelect
                  <SupportingDocumentItemScope>
                  field={`${innerItemScope}.documentType`}
                  labelKey="declaration.p5.supportingDocumentType"
                  options={supportingDocumentsOptions}
                  type="sync"
                />
              </FormField>
              <FormField labelKey="declaration.p5.supportingDocumentReferenceNumber">
                <Input
                  <SupportingDocumentItemScope>
                  type="text"
                  field={`${innerItemScope}.referenceNumber`}
                  labelKey="declaration.p5.supportingDocumentReferenceNumber"
                />
              </FormField>
              <FormField labelKey="declaration.p5.complementOfInformation">
                <Input
                  <SupportingDocumentItemScope>
                  type="text"
                  field={`${innerItemScope}.complementOfInformation`}
                  labelKey="declaration.p5.complementOfInformation"
                />
              </FormField>
              <FormField labelKey="declaration.p5.itemNumber">
                <Input
                  <SupportingDocumentItemScope>
                  type="number"
                  min={0}
                  field={`${innerItemScope}.documentLineNumber`}
                  labelKey="declaration.p5.itemNumber"
                />
              </FormField>
              <TransitDocumentFileNames
                key={`documentFileNames${supportingDocument.id + supportingDocument.sequenceNumber}`}
                documentFilesScope={`${innerItemScope}.files`}
              />
              <DocumentDropdownArea
                key={`documents${supportingDocument.sequenceNumber + supportingDocument.id}`}
                documentScope={`${innerItemScope}`}
                documentFilesScope={`${innerItemScope}.files`}
              />
            </FormFieldArray>
          )
        })}
        <div className="row">
          <div className={`col-12 text-end ${arrayRule === 'NOT_ALLOWED' ? 'cursor--not-allowed' : ''}`}>
            <button
              type="button"
              className="btn btn-sm btn-primary mt-2"
              onClick={() => addAction()}
              disabled={arrayRule === 'NOT_ALLOWED'}
            >
              <i className="fa fa-plus me-2" />
              {t('declaration.p5.addNewDocument')}
            </button>
          </div>
        </div>
      </CollapsibleColumn>
    </section>
  )
}

export default SupportingDocuments
