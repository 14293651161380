import { PackagingType } from '../../schemas/consignmentItemSchema'
import { CL182_KING_OF_PACKAGES_UNPACKED, isTransitionalPeriodActive } from '../../../../common/utils/ncts-constants'

const R0220Validator = {
  isTypeOfPackagesValid: (packaging: PackagingType): boolean => {
    if (isTransitionalPeriodActive()) {
      return true
    }

    return !(packaging.numberOfPackages === 0
      && CL182_KING_OF_PACKAGES_UNPACKED.includes(packaging.typeOfPackages))
  },
}

export default R0220Validator
