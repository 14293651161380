import { RuleResult } from '../../../../../../types/DeclarationP5'
import { excludeDeleted, hasText } from '../../../../common/utils/common-util'
import { HouseConsignmentType } from '../../schemas/houseConsignmentSchema'
import { DeclarationForm } from '../../schemas/declarationFormSchema'

const B1965Validator = {
  getCountryOfDispatchForHouseConsignmentRequirement: (formData: DeclarationForm, houseConsignment: HouseConsignmentType): RuleResult => {
    const {
      declarationType,
      countryOfDispatch,
    } = formData

    if (declarationType !== 'TIR') return 'NOT_ALLOWED'
    if (hasText(countryOfDispatch)) return 'NOT_ALLOWED'
    if (houseConsignment.consignmentItem.filter(excludeDeleted)
      .some((item) => hasText(item.countryOfDispatch))) {
      return 'OPTIONAL'
    }

    return 'REQUIRED'
  },
  getCountryOfDispatchForConsignmentItemRequirement: (
    formData: DeclarationForm,
    houseConsignment: HouseConsignmentType,
  ): RuleResult => {
    const {
      declarationType,
      countryOfDispatch,
    } = formData

    if (declarationType !== 'TIR') return 'NOT_ALLOWED'
    if (hasText(countryOfDispatch)) return 'NOT_ALLOWED'
    if (hasText(houseConsignment.countryOfDispatch)) return 'NOT_ALLOWED'

    return 'REQUIRED'
  },

}

export default B1965Validator
