import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { excludeDeleted, hasText } from '../../../../common/utils/common-util'
import B1813Validator from '../transitional/B1813'

const R0003Validator = {
  hasUniqueTransitCustomOfficeReferenceNumbers(formData: DeclarationForm): boolean {
    if (!B1813Validator.isValidationRequired()) {
      return true
    }
    const uniqueReferenceNumbers: string[] = []
    const transitCustomsOffices = formData.transitCustomsOffices.filter(excludeDeleted)

    transitCustomsOffices.forEach((transitCustomsOffice) => {
      if (hasText(transitCustomsOffice.referenceNumber) && !uniqueReferenceNumbers.includes(transitCustomsOffice.referenceNumber)) {
        uniqueReferenceNumbers.push(transitCustomsOffice.referenceNumber)
      }
    })

    return transitCustomsOffices.length === uniqueReferenceNumbers.length
  },
}

export default R0003Validator
