import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NestedFieldScope, ObjectScope, ScopeProps } from 'types/DeclarationP5'
import { useFormContext } from 'react-hook-form'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import FormField from '../../components/FormField'
import Input from '../../components/Input'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'
import FormSelect from '../../components/FormSelect'
import useCodelist from '../../../common/hooks/useCodelist'
import R0983Validator from '../../form/validators/final-rules/R0983'

const expectedScope = /(houseConsignment\.\d+)/g
const isHouseConsignmentScope = (scope: ObjectScope): scope is `houseConsignment.${number}` => (
  Array.from(scope.matchAll(expectedScope))?.length ?? 0) > 0
type HouseConsignmentScope = NestedFieldScope<`houseConsignment.${number}`>

function HouseConsignment({ scope }: ScopeProps<ObjectScope>) {
  if (!isHouseConsignmentScope(scope)) throw Error('Unable to narrow, invalid scope')

  const { t } = useTranslation()
  const [, countriesOptions] = useCodelist('COUNTRIES')
  const { getValues } = useFormContext<DeclarationForm>()
  const sequenceNumber = getValues(`${scope}.sequenceNumber`) ?? 0
  const items = useMemo(() => getValues(`${scope}.consignmentItem`), [getValues(`${scope}.consignmentItem`)])
  // TODO: autofocus ei tööta, kui rida peidetud, kommenteerib välja, sest muidu ei saa vormile submitti teha ja kasutajal segadus, miks midagi ei juhtu
  const minimumHouseConsignmentGrossMass = R0983Validator.getMinimumHouseConsignmentGrossMass(items)

  return (
    <section className="py-3">
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-house-consignment"
        headingLabel={t('declaration.p5.houseConsignment')}
      >
        <FormField labelKey="declaration.p5.sequenceNumber">
          <span>{sequenceNumber + 1}</span>
        </FormField>
        <FormField labelKey="declaration.p5.grossMass">
          <Input
            <HouseConsignmentScope>
            field={`${scope}.grossMass`}
            labelKey="declaration.p5.grossMass"
            type="number"
            inputMode="decimal"
            step={0.001}
            placeholder={t('declaration.p5.grossWeight')}
          />
        </FormField>
        <FormField labelKey="declaration.p5.referenceNumberUCR">
          <Input
            <HouseConsignmentScope>
            field={`${scope}.referenceNumberUCR`}
            labelKey="declaration.p5.referenceNumberUCR"
            type="text"
            placeholder={t('declaration.p5.referenceNumberUCR')}
          />
        </FormField>
        <FormField labelKey="declaration.p5.countryOfDestination">
          <FormSelect
            <HouseConsignmentScope>
            field={`${scope}.countryOfDestination`}
            labelKey="declaration.p5.countryOfDestination"
            options={countriesOptions}
            type="sync"
          />
        </FormField>
        <FormField labelKey="declaration.p5.countryOfDispatch">
          <FormSelect
            <HouseConsignmentScope>
            field={`${scope}.countryOfDispatch`}
            labelKey="declaration.p5.countryOfDispatch"
            options={countriesOptions}
            type="sync"
          />
        </FormField>
      </CollapsibleColumn>
    </section>
  )
}

export default HouseConsignment
