import { useEffect } from 'react'
import { UseDeclarationFormReturn } from '../../form'
import useGuaranteeApi from './api'
import { CreateOrUpdateGuaranteeRequest } from '../../../common/models'
import { parseGuaranteeResponse, toCreateOrUpdateGuaranteeRequest } from './mapper'
import { excludeDeleted } from '../../../common/utils/common-util'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'

function useGuarantee(form: UseDeclarationFormReturn) {
  const {
    reset,
    getValues,
    watch,
    formState: {
      isValid,
      isSubmitting,
    },
    trigger,
  } = form

  const transitOperationId: number | null = watch('id')

  const {
    fetchGuarantee,
    postGuarantee,
    putGuarantee,
  } = useGuaranteeApi(transitOperationId, isSubmitting)

  const populateFormGuarantee = () => {
    if (fetchGuarantee.isFetching || isSubmitting || fetchGuarantee.data === undefined) {
      return
    }

    if (fetchGuarantee.data.length > 0) {
      const formClone: DeclarationForm = structuredClone(getValues())
      reset({
        ...formClone,
        guarantees: fetchGuarantee.data.map(parseGuaranteeResponse),
      })
    } else if (fetchGuarantee.data.length === 0) {
      const formClone: DeclarationForm = structuredClone(getValues())
      reset({
        ...formClone,
        guarantees: [
          {
            id: null,
            deleted: false,
            profileName: null,
            amount: 0,
            sequenceNumber: 0,
          },
        ],
      })
    }
  }

  useEffect(() => {
    populateFormGuarantee()
  }, [fetchGuarantee.data])

  const createOrUpdateGuarantee = async (isDraft: boolean) => {
    await trigger()
    if (!isDraft && !isValid) return
    const currentTransitOperationId = getValues('id')
    if (currentTransitOperationId === null) throw Error('Missing required transit operation id for guarantee saving')

    const guarantees = getValues('guarantees')
    const createGuaranteeRequests: CreateOrUpdateGuaranteeRequest[] = guarantees
      .filter(excludeDeleted)
      .filter((item) => item.id === null)
      .map((item) => toCreateOrUpdateGuaranteeRequest(item, currentTransitOperationId))
    const updateGuaranteeRequests: CreateOrUpdateGuaranteeRequest[] = guarantees
      .filter(excludeDeleted)
      .filter((item) => item.id !== null)
      .map((item) => toCreateOrUpdateGuaranteeRequest(item, currentTransitOperationId))

    if (createGuaranteeRequests.length > 0) await postGuarantee.mutateAsync(createGuaranteeRequests)
    if (updateGuaranteeRequests.length > 0) await putGuarantee.mutateAsync(updateGuaranteeRequests)
  }

  return {
    createOrUpdateGuarantee,
  }
}

export default useGuarantee
