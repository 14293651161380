import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { excludeDeleted } from '../../../../common/utils/common-util'
import { Authorisation } from '../../schemas/commonConsignmentSchemas'
import { REDUCED_DATASET_AUTHORISATION_TYPE } from '../../../../common/utils/classifier-util'

function hasReducedDatasetAuthorisationType(authorisations: Authorisation[]) {
  return authorisations?.filter(excludeDeleted)
    .some((authorisation) => authorisation.type === REDUCED_DATASET_AUTHORISATION_TYPE)
}

const R0859Validator = {
  isReducedDatasetAuthorisationTypeRequired: (formData: DeclarationForm): boolean => {
    const {
      reducedDatasetIndicator,
      authorisations,
    } = formData

    return reducedDatasetIndicator === 'true' && !hasReducedDatasetAuthorisationType(authorisations)
  },
  isReducedDatasetAuthorisationTypeNotAllowed: (formData: DeclarationForm): boolean => {
    const {
      reducedDatasetIndicator,
      authorisations,
    } = formData

    return reducedDatasetIndicator !== 'true' && hasReducedDatasetAuthorisationType(authorisations)
  },
}

export default R0859Validator
