import { RuleResult } from 'types/DeclarationP5'
import { DeclarationForm } from '../schemas/declarationFormSchema'
import { removePrefix } from '../../../common/utils/classifier-util'
import { isTransitionalPeriodActive } from '../../../common/utils/ncts-constants'
import B1806Validator from './transitional/B1806'

const C0806Validator = {
  validateBorderTransportMeansRequirement: ({
    borderModeOfTransport,
    security,
    additionalDeclarationType,
  }: DeclarationForm): RuleResult => {
    if (isTransitionalPeriodActive()) {
      return B1806Validator.validateBorderTransportMeansRequirement(borderModeOfTransport)
    }
    const mode = removePrefix(borderModeOfTransport, 'TRANSPORT_MODE_', 1)
    if (mode === '5') {
      return 'NOT_ALLOWED'
    }
    if ((security === '1' || security === '2' || security === '3') && additionalDeclarationType === 'A') {
      return 'REQUIRED'
    }
    return 'OPTIONAL'
  },
} as const

export default C0806Validator
