import { DeclarationForm } from '../../schemas/declarationFormSchema'
import {
  excludeDeleted, hasText, nonNullConsignee, nonNullConsignor, tradersEqual,
} from '../../../../common/utils/common-util'
import { Consignee, Consignor } from '../../schemas/tradersSchema'
import { DepartureTransportMeansType } from '../../schemas/commonConsignmentSchemas'

function nonNullDepartureMeans(means: DepartureTransportMeansType) {
  return hasText(means.typeOfIdentification)
    && hasText(means.identificationNumber)
    && hasText(means.nationality)
}
const R0506Validator = {
  hasMultipleUniqueConsignorForHouseConsignment: (formData: DeclarationForm): boolean => {
    const houses = formData.houseConsignment.filter(excludeDeleted)
    if (houses.length !== houses
      .filter((houseConsignment) => houseConsignment.consignor !== null && !nonNullConsignor(houseConsignment.consignor)).length) {
      return true
    }

    let previousConsignor: Consignor | null = null

    return formData.houseConsignment.filter(excludeDeleted).some((houseConsignment, index) => {
      if (index === 0) {
        previousConsignor = houseConsignment.consignor
        return false
      }
      if (!tradersEqual(previousConsignor, houseConsignment.consignor)) {
        return true
      }

      previousConsignor = houseConsignment.consignor
      return false
    })
  },
  hasMultipleUniqueConsigneeForHouseConsignment: (formData: DeclarationForm): boolean => {
    const houses = formData.houseConsignment.filter(excludeDeleted)
    if (houses.length === 1) {
      return false
    }

    if (houses.every((house) => house.consignee === null || !nonNullConsignee(house.consignee))) {
      return false
    }

    let previousConsignee: Consignee | null = null
    return houses.some((houseConsignment, index) => {
      if (index === 0) {
        previousConsignee = houseConsignment.consignee
        return false
      }

      if (!tradersEqual(previousConsignee, houseConsignment.consignee)) {
        return true
      }
      previousConsignee = houseConsignment.consignee
      return false
    })
  },
  hasMultipleUniqueDepartureTransportMeansForHouseConsignment: (formData: DeclarationForm): boolean => {
    if (formData.houseConsignment
      .filter(excludeDeleted)
      .flatMap((houseConsignment) => houseConsignment.departureTransportMeans)
      .filter(excludeDeleted)
      .every((departureTransportMeans) => !nonNullDepartureMeans(departureTransportMeans))) {
      return true
    }

    let previousMeans: DepartureTransportMeansType[] = []
    return formData.houseConsignment.filter(excludeDeleted).some((houseConsignment, index) => {
      if (index === 0) {
        previousMeans = houseConsignment.departureTransportMeans.filter(excludeDeleted)
        return false
      }

      const hasDistinctMeans = houseConsignment.departureTransportMeans
        .filter(excludeDeleted)
        .some((means) => {
          const previousHouseContainsCurrentMeans = previousMeans
            .some((prevMeans) => prevMeans.typeOfIdentification === means.typeOfIdentification
              && prevMeans.identificationNumber === means.identificationNumber
              && prevMeans.nationality === means.nationality)

          return !previousHouseContainsCurrentMeans
        })

      previousMeans = houseConsignment.departureTransportMeans.filter(excludeDeleted)
      return hasDistinctMeans
    })
  },
  hasMultipleUniqueReferenceNumberUcrForHouseConsignment: (formData: DeclarationForm): boolean => {
    const houses = formData.houseConsignment.filter(excludeDeleted)
    if (houses.length !== houses.filter((houseConsignment) => !hasText(houseConsignment.referenceNumberUCR)).length) {
      return true
    }

    let previousReferenceNumber = ''
    return formData.houseConsignment
      .filter(excludeDeleted).some((houseConsignment, index) => {
        if (index === 0) {
          previousReferenceNumber = houseConsignment.referenceNumberUCR
          return false
        }

        if (previousReferenceNumber !== houseConsignment.referenceNumberUCR) {
          return true
        }

        previousReferenceNumber = houseConsignment.referenceNumberUCR
        return false
      })
  },
  hasMultipleUniqueCountryOfDestinationForHouseConsignment: (formData: DeclarationForm): boolean => {
    const items = formData.houseConsignment.filter(excludeDeleted)
    if (items.length !== items.filter((house) => hasText(house.countryOfDestination)).length) {
      return true
    }

    let previous = ''
    return formData.houseConsignment.filter(excludeDeleted).some((house, index) => {
      if (index === 0) {
        previous = house.countryOfDestination
        return false
      }

      if (previous !== house.countryOfDestination) {
        return true
      }

      previous = house.countryOfDestination
      return false
    })
  },
  hasMultipleUniqueCountryOfDispatchForHouseConsignment: (formData: DeclarationForm): boolean => {
    const items = formData.houseConsignment.filter(excludeDeleted)
    if (items.length !== items.filter((house) => hasText(house.countryOfDispatch)).length) {
      return true
    }

    let previous = ''
    return formData.houseConsignment.filter(excludeDeleted).some((house, index) => {
      if (index === 0) {
        previous = house.countryOfDispatch
        return false
      }

      if (previous !== house.countryOfDispatch) {
        return true
      }

      previous = house.countryOfDispatch
      return false
    })
  },
} as const

export default R0506Validator
