import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import HouseConsignmentsTableRow from './HouseConsignmentsTableRow'

import { blankHouseConsignmentType } from '../../form'
import HouseConsignmentGoodsSidebar from './HouseConsignmentGoodsSidebar'
import useFieldArrayActionHelper from '../../services/useFieldArrayActionHelper'
import { HouseConsignmentType } from '../../form/schemas/houseConsignmentSchema'
import { ArrayScope } from '../../../../../types/DeclarationP5'

function HouseConsignmentsTable() {
  const { t } = useTranslation()

  const scope: ArrayScope = 'houseConsignment'
  const {
    addAction,
    removeAction,
    getFields,
  } = useFieldArrayActionHelper<HouseConsignmentType>({
    blankItem: blankHouseConsignmentType,
    name: scope,
  })

  const [houseConsignmentIndexForGoodsModal, setHouseConsignmentIndexForGoodsModal] = useState<number | null>(null)
  const [isTransitSidebarOpen, setIsTransitSidebarOpen] = useState(false)

  const appendRow = () => {
    addAction({
      ...blankHouseConsignmentType,
      sequenceNumber: getFields().length,
    })
  }

  const toggleSidebarOpen = () => {
    setIsTransitSidebarOpen(!isTransitSidebarOpen)
  }

  const showGoodsSidebar = (targetId: number | null) => {
    setHouseConsignmentIndexForGoodsModal(targetId)
    toggleSidebarOpen()
  }

  return (
    <>
      <div className="container-fluid py-3">
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-responsive table-striped table-bordered table-declaration">
                <thead>
                  <tr>
                    <th>{t('declaration.p5.sequenceNumber')}</th>
                    <th>{t('declaration.p5.consigneeName')}</th>
                    <th>{t('declaration.p5.consigneeCountry')}</th>
                    <th>{t('declaration.p5.consignorName')}</th>
                    <th>{t('declaration.p5.consignorCountry')}</th>
                    <th className="col-1">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    getFields().map((rowData, index: number) => !rowData.deleted && (
                      <HouseConsignmentsTableRow
                        asideAction={showGoodsSidebar}
                        index={index}
                        tableRowRemove={(id) => removeAction(id, rowData)}
                        key={rowData.id}
                      />
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-end">
            <Button
              className="btn btn-sm btn-primary"
              onClick={() => appendRow()}
            >
              <i className="fa fa-plus me-2" />
              {t('declaration.p5.addRow')}
            </Button>
          </div>
        </div>
      </div>
      {
        houseConsignmentIndexForGoodsModal !== null && (
          <HouseConsignmentGoodsSidebar
            houseConsignmentIndex={houseConsignmentIndexForGoodsModal}
            isVisible={isTransitSidebarOpen}
            toggleVisibility={toggleSidebarOpen}
          />
        )
      }
    </>
  )
}

export default HouseConsignmentsTable
