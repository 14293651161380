import { DepartureTransportMeansType } from '../../schemas/commonConsignmentSchemas'

const departureMeans = new Set<string>([
  'TYPE_OF_IDENTIFICATION_10',
  'TYPE_OF_IDENTIFICATION_20',
  'TYPE_OF_IDENTIFICATION_21',
  'TYPE_OF_IDENTIFICATION_30',
  'TYPE_OF_IDENTIFICATION_31',
  'TYPE_OF_IDENTIFICATION_40',
  'TYPE_OF_IDENTIFICATION_41',
  'TYPE_OF_IDENTIFICATION_80',
])

const R0473Validator = {
  doesIdentificationNumberMustBeUpperCase:
    (departureMeansItem: DepartureTransportMeansType) => departureMeans.has(departureMeansItem.typeOfIdentification),
}

export default R0473Validator
