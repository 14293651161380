import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { IDENTIFICATION_TYPE_CODE_PREFIX, removePrefix } from '../../../../common/utils/classifier-util'
import { DepartureTransportMeansType } from '../../schemas/commonConsignmentSchemas'

const R0472Validator = {
  isDepartureTransportMeansTypeOfIdentificationValid: (
    formData: DeclarationForm,
    departureTranportMeans: DepartureTransportMeansType,
  ): boolean => {
    const transportMode = removePrefix(formData.inlandModeOfTransport, 'TRANSPORT_MODE_', 1) ?? ''
    if (!['1', '2', '3', '4', '8'].includes(transportMode)) {
      return true
    }

    const typeOfIdentification = removePrefix(departureTranportMeans.typeOfIdentification, IDENTIFICATION_TYPE_CODE_PREFIX, 1)

    return typeOfIdentification?.substring(0, 2) === transportMode
  },
}

export default R0472Validator
