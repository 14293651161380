import { TransportEquipmentType } from '../../schemas/transportEquipmentSchema'
import { excludeDeleted, hasText } from '../../../../common/utils/common-util'

const R0107Validator = {
  hasUniqueSealIdentifiers: (transportEquipments: TransportEquipmentType[]): boolean => {
    const uniqueIdentifiers: string[] = []
    let sealsCount = 0

    transportEquipments.filter(excludeDeleted)
      .flatMap((transportEquipment) => transportEquipment.seals.filter(excludeDeleted))
      .forEach((seal) => {
        if (hasText(seal.identifier) && !uniqueIdentifiers.includes(seal.identifier)) {
          uniqueIdentifiers.push(seal.identifier)
        }
        sealsCount += 1
      })

    return uniqueIdentifiers.length === sealsCount
  },
}

export default R0107Validator
