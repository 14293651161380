import React, {
  FormEvent, useContext, useEffect, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../context/UserContext'
import { isNullOrBlank } from '../helpers'
import AuthenticationService from '../services/auth.service'
import UserService from './Customer/services/user.service'
import PrivacyService, { PrivacyPolicy } from './Settings/service/privacy.service'
import PrivacyModal from './Register/PrivacyModal'
import ROUTES from '../config/routes'
import { NotificationContext } from '../context/NotificationContext'

interface LoginForm {
  username: string
  password: string
}
const blankForm: LoginForm = {
  username: '',
  password: '',
}

function Login() {
  const { t } = useTranslation()
  const history = useNavigate()
  const { user } = useContext(UserContext)
  const [loginForm, setLoginForm] = useState<LoginForm>(blankForm)
  const [recoveryForm, setRecoveryForm] = useState<{ email: string }>({ email: '' })
  const [isAcceptanceModalOpen, setIsAcceptanceModalOpen] = useState<boolean>(false)
  const [otp, setOtp] = useState<string>('')
  const [privacyPolicy, setPrivacyPolicy] = useState<PrivacyPolicy>({
    id: 0,
    content: '',
  })
  const [view, setView] = useState<'login' | 'recovery' | 'otp'>('login')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    PrivacyService.getPrivacyTerms(localStorage.getItem('t1_locale') || 'en').then((response) => {
      setPrivacyPolicy(response)
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    })
  }, [])

  function clientRequirements() {
    UserService.getUserRequirements().then((response) => {
      if (response.requiresPrivacyConfirmation) {
        setIsAcceptanceModalOpen(true)
      } else if (!response.hasProfile && user?.role === 'CUSTOMER_ADMIN') {
        window.location.replace('/company')
      } else {
        window.location.replace('/transit-operations')
      }
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    })
  }

  const submitLogin = (event: FormEvent<HTMLFormElement>) => {
    if (isNullOrBlank(loginForm.username) || isNullOrBlank(loginForm.password)) {
      return
    }
    setLoading(true)
    AuthenticationService.loginUser(loginForm).then((token) => {
      if (token === 302) {
        toast.warn('Please check your e-mail for one-time-password')
        setView('otp')
      } else if (token !== undefined) {
        localStorage.setItem('accessToken', token)
        clientRequirements()
      }
      setLoading(false)
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
      setLoading(false)
    })
    event.preventDefault()
    event.stopPropagation()
  }
  const submitRecovery = (event: FormEvent<HTMLFormElement>) => {
    if (isNullOrBlank(recoveryForm.email)) {
      return
    }
    setLoading(true)
    UserService
      .sendForgotPasswordEmail(recoveryForm)
      .then(() => {
        toast.success(t('messages.recoverySent'))
        setLoading(false)
        setLoginForm(blankForm)
        setView('login')
      })
      .catch(() => {
        toast.error(t('messages.recoverySendingFailed'))
        setLoading(false)
      })
    event.preventDefault()
    event.stopPropagation()
  }

  const submitOtp = (event: FormEvent) => {
    if (isNullOrBlank(otp)) {
      return
    }

    setLoading(true)
    AuthenticationService.submitOtpRequest(loginForm.username, otp).then((jwt) => {
      if (jwt === 302) {
        setView('otp')
      } else if (jwt !== undefined) {
        localStorage.setItem('accessToken', jwt)
        clientRequirements()
      }
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
    event.preventDefault()
    event.stopPropagation()
  }

  const loginView = () => (
    <form onSubmit={submitLogin}>
      <div className="input-group input-group-lg mb-2">
        <span className="input-group-text py-3" id="basic-addon1">
          <i className="fal fa-envelope fa-lg" />
        </span>
        <input
          id="email"
          type="email"
          name="email"
          className="form-control px-1 py-3"
          placeholder={t('common.email')}
          autoComplete="email"
          pattern="^([a-zA-Z0-9_\-\.\+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
          required
          value={loginForm.username}
          onChange={(event) => {
            setLoginForm({
              ...loginForm,
              username: event.target.value,
            })
          }}
        />
      </div>
      <div className="input-group input-group-lg mb-2">
        <span className="input-group-text py-3" id="basic-addon1">
          <i className="fal fa-lock-alt fa-lg" />
        </span>
        <input
          id="password"
          type="password"
          className="form-control px-1 py-3"
          autoComplete="password"
          placeholder={t('common.password')}
          required
          value={loginForm.password}
          onChange={(event) => {
            setLoginForm({
              ...loginForm,
              password: event.target.value,
            })
          }}
        />
      </div>
      <button
        className="btn btn-primary btn-lg mb-3 p-3 w-100"
        type="submit"
        disabled={loading}
      >
        {t('buttons.logIn')}
      </button>
      <div className="d-flex flex-column">
        <h2 className="register"><span>OR</span></h2>
        <button
          onClick={() => {
            history(ROUTES.registration)
          }}
          className="btn btn-outline-gray btn-lg mb-3 p-3 w-100"
          type="button"
          disabled={loading}
        >
          <span>{t('buttons.register')}</span>
        </button>
      </div>
      <div className="d-flex justify-content-center align-items-center mb-2">
        <span className="cant-access">
          {t('buttons.startPasswordRecovery')}
        </span>
        <button
          className="btn btn-link py-0"
          type="button"
          onClick={() => {
            setView('recovery')
          }}
        >
          {t('buttons.resetPassword')}
        </button>
      </div>
    </form>
  )
  const recoveryView = () => (
    <form onSubmit={submitRecovery}>
      <div className="input-group input-group-lg mb-2">
        <span className="input-group-text py-3" id="basic-addon1">
          <i className="fal fa-envelope fa-md" />
        </span>
        <input
          id="email-recovery"
          type="email"
          name="email"
          className="form-control px-1 py-3"
          placeholder={t('common.email')}
          autoComplete="email"
          required
          pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
          value={recoveryForm.email}
          onChange={(event) => {
            setRecoveryForm({
              ...recoveryForm,
              email: event.target.value,
            })
          }}
        />
      </div>
      <button
        className="btn btn-primary btn-lg mb-3 p-3 w-100"
        type="submit"
        disabled={loading}
      >
        {t('buttons.recoveryEmail')}
      </button>

      <div className="d-flex justify-content-end align-items-center mb-2">
        <button
          className="btn btn-link py-0"
          type="button"
          disabled={loading}
          onClick={() => {
            setView('login')
          }}
        >
          {t('buttons.backToLogin')}
        </button>
      </div>
    </form>
  )

  const otpView = () => (
    <form onSubmit={submitOtp}>
      <div className="input-group input-group-lg mb-2">
        <span className="input-group-text py-3" id="basic-addon1">
          <i className="fal fa-lock-alt fa-md" />
        </span>
        <input
          id="otp"
          type="text"
          className="form-control px-1 py-3"
          placeholder={t('user.otp')}
          autoComplete="one-time-code"
          required
          pattern="^[0-9]+$"
          value={otp}
          onChange={(event) => {
            setOtp(event.target.value)
          }}
        />
      </div>
      <button
        className="btn btn-primary btn-lg mb-3 p-3 w-100"
        type="submit"
        disabled={loading}
      >
        {t('buttons.submit')}
      </button>
    </form>
  )

  const { contextHeaderNotificationMessage } = useContext(NotificationContext)

  return (
    <>
      <div
        className="login__container"
        style={{ height: contextHeaderNotificationMessage !== '' ? 'calc(100vh - 60px)' : '100vh' }}
      >
        <div className="login__card">
          <h1 className="d-flex justify-content-center w-100 rounded-top mb-3 p-3 text-uppercase">Tardek T1</h1>
          {
            view === 'login'
          && loginView()
          }
          {
            view === 'recovery'
          && recoveryView()
          }
          {view === 'otp'
            && otpView()}
        </div>
      </div>
      <PrivacyModal
        isModalOpen={isAcceptanceModalOpen}
        toggleModal={setIsAcceptanceModalOpen}
        privacyTerms={privacyPolicy.content}
        page="login"
      />
    </>
  )
}

export default Login
