import React, {
  ChangeEvent, Dispatch, SetStateAction, useContext, useEffect, useRef, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { UserContext } from 'context/UserContext'
import {
  isMimeTypeExcel, isMimeTypeImage, isMimeTypePdf, isMimeTypeWord,
} from 'helpers'
import LoadingSpinner from 'components/LoadingSpinner'
import { FileType } from '../../../TransitOperationEditor/form/schemas/fileSchemas'
import { NotesDocument } from '../../../TransitOperationEditor/form/schemas/documentSchemas'
import { blankNotesDocument } from '../../../TransitOperationEditor/form/blankItems'
import useTransitOperationInfo from '../../../TransitOperationEditor/hooks/useTransitOperationInfo'
import useNotes from '../../../TransitOperationEditor/hooks/useNotes'
import { DocumentRequestTypeEnum } from '../../models'
import useFileApi from '../../../TransitOperationEditor/hooks/useFile/api'
import { toNoteDocumentRequest } from '../../../TransitOperationEditor/hooks/useDocument/mapper'
import { FilePreviewModal, saveBlob } from '../../../TransitOperationEditor/components/FilePreviewModal'

/* eslint-disable  @typescript-eslint/no-explicit-any */
interface NotesModalProps {
  transitOperationId: number
  isNotesModalOpen: boolean
  setIsNotesModalOpen: Dispatch<SetStateAction<boolean>>
  originalRow: any
}

function NotesModal(props: NotesModalProps) {
  const {
    transitOperationId, isNotesModalOpen, setIsNotesModalOpen, originalRow,
  } = props
  const { t } = useTranslation()
  const [notes, setNotes] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [isPreviewModalVisible, setPreviewModalVisibility] = useState(false)
  const [previewModalFile, setPreviewModalFile] = useState<FileType>()

  const { user } = useContext(UserContext)

  const [document, setDocument] = useState<NotesDocument>(blankNotesDocument)

  const { createTransitOperationInfo, createOrUpdateNotesOrFeedback, createOrUpdateCompanyNotes } = useTransitOperationInfo(transitOperationId, user?.role === 'ADMIN')

  const {
    postDocument,
    putDocument,
    postFiles,
    getDocuments,
    deleteFile,
  } = useNotes(transitOperationId, DocumentRequestTypeEnum.NOTE)

  const { getSignedUrlByFolderAndUUID } = useFileApi()

  useEffect(() => {
    setDocument(blankNotesDocument)
    const loadDocuments = async () => {
      setLoading(true)
      const documentsResponse = await getDocuments.mutateAsync()

      if (documentsResponse.length !== 0) {
        const documentFiles: Array<Promise<FileType>> = []
        documentFiles.push(
          ...documentsResponse[0].files.map(async (file) => {
            const url = await getSignedUrlByFolderAndUUID.mutateAsync({
              folder: 'NOTE',
              fileUuid: file.uuid!.split('/')[1],
            })

            return {
              id: file.id!,
              deleted: false,
              sequenceNumber: file.sortOrder!,
              uuid: file.uuid,
              documentId: documentsResponse[0].id,
              mimeType: file.mimeType!,
              fileName: file.filename,
              file: null,
              fileBlob: null,
              url,
            } as FileType
          }),
        )
        Promise.all(documentFiles).then((allFiles) => {
          setDocument({
            id: documentsResponse[0].id,
            documentType: documentsResponse[0].type,
            complementOfInformation: '',
            deleted: false,
            referenceNumber: '',
            sequenceNumber: documentsResponse[0].sequenceNumber!,
            transitOperationId,
            files: allFiles,
          })
          setLoading(false)
        }).catch(() => {
          toast.error(t('common.error'))
          setLoading(false)
        })
      } else {
        setLoading(false)
      }
    }
    if (transitOperationId && user?.role === 'ADMIN') {
      setNotes(originalRow?.notes ?? '')
    } else {
      setNotes(originalRow?.companyNotes ?? '')
    }
    loadDocuments()
  }, [transitOperationId])

  const uploadFile = (documentFile: FileType) => {
    if (documentFile.deleted) {
      if (documentFile.uuid !== null) {
        const splitUuid = documentFile.uuid.split('/')
        deleteFile.mutateAsync({
          folder: splitUuid[0],
          uuid: splitUuid[1],
        }).then(() => {
          // eslint-disable-next-line no-param-reassign
          documentFile.uuid = null
        })
      }
    } else {
      const formData = new FormData()
      formData.append('filename', documentFile.fileName!)
      formData.append('mimeType', documentFile.mimeType!)
      formData.append('sortOrder', `${documentFile.sequenceNumber}`)
      formData.append('documentId', `${document.id}`)

      documentFile.file?.arrayBuffer()
        .then((fileBytes) => {
          const fileBlob = new Blob([fileBytes], { type: documentFile.mimeType })
          formData.append('multipartFile', fileBlob, documentFile.fileName!)

          postFiles.mutateAsync(formData)
            .then((response) => {
              // eslint-disable-next-line no-param-reassign
              documentFile.uuid = response.uuid!
            })
        })
    }
  }

  const submitNotes = async () => {
    if (!transitOperationId && notes === '') {
      return
    }
    setLoading(true)
    if (user?.role !== 'ADMIN') {
      await createOrUpdateCompanyNotes(originalRow.id, notes)
        .then((resp) => {
          setLoading(false)
          originalRow.companyNotes = resp.companyNote!
          setIsNotesModalOpen(!isNotesModalOpen)
        }).catch((error) => {
          toast.error('Updating notes / feedback failed!')
          // eslint-disable-next-line no-console
          console.error(error)
        })
    }

    if (originalRow?.transitOperationInfoId === null && user?.role === 'ADMIN') {
      await createTransitOperationInfo(transitOperationId).then(async (response) => {
        if (response) {
          await createOrUpdateNotesOrFeedback(response.id!, notes)
            .then((resp) => {
              setLoading(false)
              originalRow.notes = resp.notes!
              setIsNotesModalOpen(!isNotesModalOpen)
            }).catch((error) => {
              toast.error('Updating notes / feedback failed!')
              // eslint-disable-next-line no-console
              console.error(error)
            })
        }
      }).catch((error) => {
        setLoading(false)
        toast.error('Updating notes / feedback failed!')
        // eslint-disable-next-line no-console
        console.error(error)
      })
    } else if (user?.role === 'ADMIN') {
      await createOrUpdateNotesOrFeedback(originalRow.transitOperationInfoId, notes)
        .then((resp) => {
          setLoading(false)
          originalRow.notes = resp.notes!
          setIsNotesModalOpen(!isNotesModalOpen)
        }).catch((error) => {
          toast.error('Updating notes / feedback failed!')
          // eslint-disable-next-line no-console
          console.error(error)
        })
    }
    const request = toNoteDocumentRequest(document, transitOperationId)
    if (document.id === null) {
      await postDocument.mutateAsync(Array.of(request))
        .then((resp) => {
          if (resp) {
            document.id = resp[0].id
            document.files.forEach((documentFile) => {
              uploadFile(documentFile)
            })
          }
        })
    } else {
      await putDocument.mutateAsync(Array.of(request))
        .then((resp) => {
          if (resp) {
            document.id = resp[0].id
            document.files.forEach((documentFile) => {
              uploadFile(documentFile)
            })
          }
        })
    }
  }

  const noteFileRef = useRef<HTMLInputElement>(null)

  async function addNewFiles(droppedFiles: FileList) {
    const changedFiles = document.files.length === 0 ? [] : [...document.files]
    for (let i = 0; i < droppedFiles.length; i += 1) {
      const file = droppedFiles.item(i)

      if (!file) {
        toast.error('Error accessing file')
      } else if (file.size > 5242880) {
        toast.error(`File '${file.name}' size is too large. Maximum allowed size - ${5242880 / 1024 / 1024} MiB`)
      } else {
        // eslint-disable-next-line no-await-in-loop
        const fileBytes = await file.arrayBuffer()
        const fileBlob = fileBytes !== undefined ? new Blob([fileBytes], { type: file.type }) : null
        const newFile: FileType = {
          id: null,
          sequenceNumber: changedFiles.length,
          deleted: false,
          fileName: file.name,
          uuid: null,
          mimeType: file.type,
          url: null,
          fileBlob,
          file,
          documentId: document.id,
        }
        changedFiles.push(newFile)
        setDocument({ ...document, files: [...changedFiles] })
      }
    }
  }

  const deleteDocument = (changedDocumentFile: FileType) => {
    const changeDoc = {
      ...document,
      files: [...document.files.map((documentFileInState) => {
        const newDocFile = {
          ...documentFileInState,
        }

        if (documentFileInState.fileName === changedDocumentFile.fileName) {
          newDocFile.deleted = true
        }

        return newDocFile
      })],
    }
    setDocument(changeDoc)
  }

  const getFilePreviewTypeByMimeType = (mimeType: string) => {
    if (isMimeTypeImage(mimeType)) {
      return 'IMAGE'
    }
    if (isMimeTypeExcel(mimeType)) {
      return 'EXCEL'
    }
    if (isMimeTypeWord(mimeType)) {
      return 'WORD'
    }

    return isMimeTypePdf(mimeType) ? 'PDF' : null
  }

  const renderFilePreviewModal = () => {
    if (!previewModalFile) {
      return null
    }

    const filePreviewType = getFilePreviewTypeByMimeType(previewModalFile.mimeType)
    if (!filePreviewType) {
      return null
    }

    return (
      <FilePreviewModal
        type={filePreviewType}
        key="filePreviewModal"
        isVisible={isPreviewModalVisible}
        toggleVisibility={() => setPreviewModalVisibility(!isPreviewModalVisible)}
        file={previewModalFile}
      />
    )
  }

  const downloadOrOpenFile = (file: FileType) => {
    if (file.url) {
      const filePreviewType = getFilePreviewTypeByMimeType(file.mimeType)
      switch (filePreviewType) {
        case 'IMAGE':
        case 'PDF':
        case 'EXCEL':
        case 'WORD':
          setPreviewModalFile(file)
          setPreviewModalVisibility(!isPreviewModalVisible)
          break
        default:
          saveBlob(file, file.url, 'url')
      }
    }
  }

  return (
    <>
      <Modal
        show={isNotesModalOpen}
        className="overflow-hidden"
        dialogClassName="modal"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => {
          if (!loading) {
            setIsNotesModalOpen(!isNotesModalOpen)
          }
        }}
        animation={false}
        backdropClassName="backdrop-with-modal"
        centered
      >
        <Modal.Header>
          <Modal.Title className="justify-content-between">
            <h2>{t('notes.title')}</h2>
            <button
              type="button"
              className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
              onClick={() => {
                if (!loading) {
                  setIsNotesModalOpen(!isNotesModalOpen)
                }
              }}
            >
              <span>{t('buttons.close')}</span>
              <i className="fal fa-times fa-2x ms-2" />
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-self-center w-100">
            <textarea
              value={notes}
              className="w-100 form-control-lg mb-2"
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setNotes(e.target.value)}
              maxLength={1000}
            />
          </div>

          <div className="row mb-1">
            <div className="d-none d-md-block col-12 col-md-4" />
            <div className="col-12 col-md-8 d-flex flex-column ">
              {document.files?.filter((doc) => !doc.deleted)
                .map((file) => (
                  <div className="d-flex" key={`document-file-${file.fileName}`}>
                    <button
                      className="btn btn-link d-flex overflow-auto"
                      type="button"
                      onClick={() => downloadOrOpenFile(file)}
                    >
                      <i className="fal fa-file fa-md me-2" />
                      <span className="overflow-ellipsis">
                        {file.fileName}
                      </span>
                    </button>
                    <button
                      className="btn btn-link text-decoration-none"
                      type="button"
                      onClick={() => {
                        deleteDocument(file)
                      }}
                    >
                      <i className="fal fa-times me-2 ms-1 ms-md-0" />
                    </button>
                  </div>
                ))}
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12">
              <div
                role="presentation"
                className="dropdown-area"
                onClick={() => {
                  if (noteFileRef.current) {
                    noteFileRef.current.click()
                  }
                }}
                onDragOver={(event) => {
                  event.currentTarget.classList.add('file-hover')
                  event.preventDefault()
                }}
                onDragLeave={(event) => {
                  event.currentTarget.classList.remove('file-hover')
                }}
                onDropCapture={(event) => {
                  event.currentTarget.classList.remove('file-hover')

                  addNewFiles(event.dataTransfer.files)

                  event.preventDefault()
                  event.stopPropagation()
                }}
              >
                <input
                  className="d-none"
                  type="file"
                  ref={noteFileRef}
                  onChange={(event) => {
                    if (event.target.files) {
                      addNewFiles(event.target.files)
                    }
                    event.preventDefault()
                    event.stopPropagation()
                    if (noteFileRef.current) {
                      noteFileRef.current.value = ''
                    }
                  }}
                />
                <i className="fal fa-cloud-upload me-2" />
                <span>
                  {t('buttons.addFiles')}
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex align-content-end justify-content-end mt-5">
            <button
              type="button"
              disabled={!transitOperationId && notes === ''}
              className="btn btn-lg btn-primary mx-1 text-secondary shadow-sm"
              onClick={submitNotes}
            >
              {
                loading && <LoadingSpinner />
              }
              {
                !loading && (<span>{t('notes.submit')}</span>)
              }
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {
        previewModalFile && renderFilePreviewModal()
      }
    </>
  )
}

export default NotesModal
