import { RuleResult } from 'types/DeclarationP5'
import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { extractCountryFromDepartureOffice } from '../../../../common/utils/classifier-util'

const NC9043Validator = {
  validateLocationOfGoodsRequirement: (formData: DeclarationForm): RuleResult => {
    const country = extractCountryFromDepartureOffice(formData.departureCustomsOffice)
    if (country === 'FI' && formData.additionalDeclarationType === 'A') {
      return 'REQUIRED'
    }

    return 'OPTIONAL'
  },

}

export default NC9043Validator
