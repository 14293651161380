import { removePrefix } from '../../../../common/utils/classifier-util'
import { RAIL_TRANSPORT_MODE, ROAD_TRANSPORT_MODE } from '../../../../common/utils/ncts-constants'

const R0855Validator = {
  getDepartureTransportMeansMultiplicity: (inlandModeOfTransport: string): number => {
    const mode = removePrefix(inlandModeOfTransport, 'TRANSPORT_MODE_', 1)

    switch (mode) {
      case ROAD_TRANSPORT_MODE:
        return 3
      case RAIL_TRANSPORT_MODE:
        return 999
      default:
        return 1
    }
  },
} as const

export default R0855Validator
