import { RuleResult } from 'types/DeclarationP5'
import { DeclarationForm } from '../schemas/declarationFormSchema'
import { Consignor } from '../schemas/tradersSchema'

function nonNull(consignor: Consignor) {
  return !(consignor.identificationNumber === ''
    && consignor.name === ''
    && consignor.target === 'CONSIGNOR'
    && consignor.address
    && consignor.address.city === ''
    && consignor.address.country === ''
    && consignor.address.postcode === ''
    && consignor.address.streetAndNumber === ''
    && (
      consignor.contactPerson === null
      || (consignor.contactPerson?.name === ''
        && consignor.contactPerson?.email === ''
        && consignor.contactPerson?.telephone === '')))
}

const C0542Validator = {
  validateConsignmentConsignorRequirement: (formData: DeclarationForm): RuleResult => {
    if (formData.security === '0' && formData.reducedDatasetIndicator === 'true') {
      return 'NOT_ALLOWED'
    }

    return 'OPTIONAL'
  },
  validateHouseConsignmentConsignorRequirement: (formData: DeclarationForm): RuleResult => {
    if (formData.security === '0' && formData.reducedDatasetIndicator === 'true') {
      return 'NOT_ALLOWED'
    }

    if (nonNull(formData.consignor)) {
      return 'NOT_ALLOWED'
    }

    return 'OPTIONAL'
  },
} as const

export default C0542Validator
