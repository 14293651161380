import React, { useContext, useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { DeclarationStatusEnum } from 'types/IDeclaration'
import { UserContext } from 'context/UserContext'
import { TransitOperationInfoResponse, TransitOperationResponse } from '../../models'
import useTransitOperationForceStatus from '../../../TransitOperationTable/hooks/useTransitOperationForceStatus'
import useTransitOperationInfo from '../../../TransitOperationEditor/hooks/useTransitOperationInfo'
import NotesModal from '../NotesModal/NotesModal'
import FeedbackModal from '../../../TransitOperationEditor/components/FeedbackModal'

interface TransitSidebarActionsProps {
  currentView: string
  transitOperation: TransitOperationResponse | undefined
  originalRow: any
  transitOperationId: number
  transitOperationInfo: TransitOperationInfoResponse | undefined
}

function TransitSidebarActions(props: Readonly<TransitSidebarActionsProps>) {
  const {
    currentView, transitOperation, originalRow, transitOperationId, transitOperationInfo,
  } = props

  const [isReviewed, setIsReviewed] = useState(transitOperationInfo?.isReviewed)
  const [isNotesOpen, setIsNotesOpen] = useState<boolean>(false)
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState<boolean>(false)

  const { user } = useContext(UserContext)
  const { t } = useTranslation()
  const { createTransitOperationInfo, updateReviewStatus } = useTransitOperationInfo(transitOperationId, user?.role === 'ADMIN')

  useEffect(() => {
    setIsReviewed(originalRow.isReviewed)
  }, [transitOperationId])

  const toggleDeclarationReview = async () => {
    if (transitOperation && originalRow) {
      if (originalRow?.transitOperationInfoId === null) {
        await createTransitOperationInfo(transitOperationId).then(async (response) => {
          if (response) {
            await updateReviewStatus(transitOperationId, !originalRow.isReviewed)
              .then((resp) => {
                originalRow.isReviewed = resp.isReviewed
                setIsReviewed(resp.isReviewed)
              })
              .catch((error) => {
                toast.error('Updating Review Status failed!')
                // eslint-disable-next-line no-console
                console.error(error)
              })
          }
        })
      } else {
        await updateReviewStatus(transitOperationId, !originalRow.isReviewed)
          .then((resp) => {
            originalRow.isReviewed = resp.isReviewed
            setIsReviewed(resp.isReviewed)
          })
          .catch((error) => {
            toast.error('Updating Review Status failed!')
            // eslint-disable-next-line no-console
            console.error(error)
          })
      }
    }
  }

  const { forceStatusForTransitOperation } = useTransitOperationForceStatus()

  const updateStatusForTransitOperation = async () => {
    await forceStatusForTransitOperation(transitOperationId)
      .then(() => {
        toast.success('Transit Successfully closed!')
      })
      .catch(() => {
        toast.error('Closing Transit failed!')
      })
  }

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle variant="link" className="p-0 hide-caret" id="dropdown-basic">
          <i className="fas fa-ellipsis-v fa-md" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="shadow-sm">
          {
            (user?.role === 'ADMIN' && currentView !== 'logs' && (
              <>
                <Dropdown.Item
                  type="button"
                  className="btn-lg"
                  onClick={toggleDeclarationReview}
                  style={{
                    color: !isReviewed ? 'green' : 'red',
                    borderColor: !isReviewed ? 'green' : 'red',
                  }}
                >
                  <i className={`${!isReviewed ? 'fa-check' : 'fa-times'} fas me-1`} />
                  <span>{t('buttons.review', { context: !isReviewed ? 'approved' : 'decline' })}</span>
                </Dropdown.Item>
                <Dropdown.Item
                  className="btn-lg"
                  style={{ color: '#1a1b1e' }}
                  onClick={() => setIsNotesOpen(true)}
                >
                  <i className="fal fa-envelope me-2" />
                  <span>{t('buttons.notes')}</span>
                </Dropdown.Item>
                {originalRow?.status !== DeclarationStatusEnum.T1_TRANSIT_CLOSED && (
                  <Dropdown.Item
                    className="btn-lg"
                    onClick={() => updateStatusForTransitOperation()}
                  >
                    <i className="fal fa-check me-2" />
                    <span>{t('buttons.t1Closed')}</span>
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  className="btn-lg"
                  onClick={() => {
                    setIsFeedbackModalOpen(true)
                  }}
                >
                  <i className="fal fa-info me-2" />
                  <span>
                    {t('buttons.feedback')}
                  </span>
                </Dropdown.Item>
              </>
            ))
          }

        </Dropdown.Menu>
      </Dropdown>

      <NotesModal
        transitOperationId={transitOperationId}
        isNotesModalOpen={isNotesOpen}
        setIsNotesModalOpen={setIsNotesOpen}
        originalRow={originalRow}
      />
      <FeedbackModal
        transitFeedback={transitOperationInfo?.feedback ?? ''}
        transitOperation={transitOperation}
        transitOperationId={transitOperationId}
        isFeedbackModalOpen={isFeedbackModalOpen}
        setIsFeedbackModalOpen={setIsFeedbackModalOpen}
      />
    </>
  )
}

export default TransitSidebarActions
