import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { TabType } from '../index'
import FormRow from '../../../../components/FormRow'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import ConfirmationModal from '../ConfirmationModal'
import NotificationService from '../../service/notification.service'

interface CustomsTabProps {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  // eslint-disable-next-line react/no-unused-prop-types
  readonly tabType: TabType
}

function CustomsTab(props: CustomsTabProps) {
  const { t } = useTranslation()
  const {
    loading,
    setLoading,
  } = props

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)
  const [isConsentConfirmationModalOpen, setIsConsentConfirmationModalOpen] = useState<boolean>(false)
  const [isBackupChannelEnabled, setIsBackupChannelEnabled] = useState(false)

  useEffect(() => {
    setLoading(true)
    NotificationService.getPolishBackupChannelBoolean().then((value) => {
      setIsBackupChannelEnabled(value.data)
    }).finally(
      () => setLoading(false),
    )
  }, [])

  return (
    <>
      <div className="title-bar">
        <div className="d-flex justify-content-end align-items-center ps-3 w-100">
          <button
            type="button"
            disabled={loading}
            className="btn btn-secondary btn-lg d-flex align-items-center text-primary shadow-sm d-block"
            onClick={() => {
              setIsConfirmationModalOpen(true)
            }}
          >
            {loading ? <LoadingSpinner /> : t('buttons.save')}
          </button>

        </div>

      </div>
      <div className="seo-tags__container overflow-auto">
        <div className="pt-4 px-4 col-12 col-xl-4 mb-5 mb-md-0">
          <div className="heading">
            <h3>
              Customs integration settings
            </h3>
          </div>
          <FormRow title="Poland new declarations use backup channel">
            <div className="form-row-special-input">
              <div className="form-check d-flex align-items-center me-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="isBackupChannelEnabled"
                  id="backupChannelDisabled"
                  value="false"
                  disabled={loading}
                  checked={!isBackupChannelEnabled}
                  onChange={() => {
                    setIsBackupChannelEnabled(false)
                  }}
                />
                <label className="form-check-label text-nowrap ps-1" htmlFor="backupChannelDisabled">
                  {`${t('common.no')} (0)`}
                </label>
              </div>
              <div className="form-check d-flex align-items-center">
                <input
                  className="form-check-input"
                  type="radio"
                  name="isBackupChannelEnabled"
                  id="backupChannelEnabled"
                  disabled={loading}
                  value="true"
                  checked={isBackupChannelEnabled}
                  onChange={() => {
                    setIsBackupChannelEnabled(true)
                  }}
                />
                <label className="form-check-label text-nowrap ps-1" htmlFor="backupChannelEnabled">
                  {`${t('common.yes')} (1)`}
                </label>
              </div>
            </div>
          </FormRow>
          <FormRow title="Consent for Borica B-Trust (BG) automatic signing">
            <button
              type="button"
              disabled={loading}
              className="btn btn-secondary btn-lg d-flex align-items-center text-primary shadow-sm d-block"
              onClick={() => {
                setIsConsentConfirmationModalOpen(true)
              }}
            >
              {loading ? <LoadingSpinner /> : t('buttons.send')}
            </button>
          </FormRow>
        </div>
      </div>
      <ConfirmationModal
        loading={loading}
        title={t('common.confirmAction')}
        isConfirmationModalOpen={isConfirmationModalOpen}
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
        submissionConfirmed={() => {
          setLoading(true)
          NotificationService.updatePolishBackupChannelBoolean(isBackupChannelEnabled).then(() => {
            setIsConfirmationModalOpen(false)
            toast.success(t('messages.savingSuccess'))
          })
            .finally(() => setLoading(false))
        }}
        modalBody={(<p>{t('messages.confirmSaving')}</p>)}
      />
      <ConfirmationModal
        loading={loading}
        title={t('common.confirmAction')}
        isConfirmationModalOpen={isConsentConfirmationModalOpen}
        setIsConfirmationModalOpen={setIsConsentConfirmationModalOpen}
        submissionConfirmed={() => {
          setLoading(true)
          NotificationService.sendConsent().then(() => {
            setIsConsentConfirmationModalOpen(false)
            toast.success(t('messages.savingSuccess'))
          }).finally(() => setLoading(false))
        }}
        modalBody={(<p>{t('messages.confirmConsentUpdate')}</p>)}
      />

    </>
  )
}

export default CustomsTab
