import { NctsCountries, NctsCountry } from 'config'

export const AUTHORISATION_TYPE_CODE_PREFIX = 'AUTHORISATION_TYPE_' as const
export const GUARANTEE_TYPE_CODE_PREFIX = 'GUARANTEE_TYPE_' as const
export const SECURITY_PREFIX = 'SECURITY_' as const
export const COUNTRY_CODE_PREFIX = 'COUNTRY_' as const
export const TRANSPORT_MODE_CODE_PREFIX = 'TRANSPORT_MODE_' as const
export const IDENTIFICATION_TYPE_CODE_PREFIX = 'TYPE_OF_IDENTIFICATION_' as const
export const CUSTOMS_OFFICE_CODE_PREFIX = 'CUSTOMS_OFFICE_' as const
export const DEPARTURE_OFFICE_CODE_PREFIX = 'DEPARTURE_OFFICE_' as const
export const DESTINATION_OFFICE_CODE_PREFIX = 'DESTINATION_OFFICE_' as const
export const TRANSIT_OFFICE_CODE_PREFIX = 'TRANSIT_OFFICE_' as const
export const TRANSIT_EXIT_OFFICE_CODE_PREFIX = 'TRANSIT_EXIT_OFFICE_' as const
export const PACKAGE_TYPE_CODE_PREFIX = 'PACKAGE_TYPE_' as const
export const UNIT_CODE_PREFIX = 'UNIT_TYPE_' as const
export const METHOD_OF_PAYMENT_CODE_PREFIX = 'METHOD_OF_PAYMENT_' as const
export const PROCEDURE_TYPE_CODE_PREFIX = 'PROCEDURE_TYPE_' as const
export const DOCUMENT_TYPE_CODE_PREFIX = 'DOCUMENT_TYPE_' as const
export const PREVIOUS_DOCUMENT_TYPE_CODE_PREFIX = 'PREVIOUS_DOCUMENT_TYPE_' as const
export const SUPPORTING_DOCUMENT_TYPE_CODE_PREFIX = 'SUPPORTING_DOCUMENT_TYPE_' as const
export const TRANSPORT_DOCUMENT_TYPE_CODE_PREFIX = 'TRANSPORT_DOCUMENT_TYPE_' as const
export const ADDITIONAL_SUPPLY_CHAIN_ACTOR_CODE_PREFIX = 'ADDITIONAL_SUPPLY_CHAIN_ACTOR_' as const
export const ADDITIONAL_REFERENCE_CODE_PREFIX = 'ADDITIONAL_REFERENCE_TYPE_' as const
export const ADDITIONAL_INFORMATION_CODE_PREFIX = 'ADDITIONAL_INFORMATION_TYPE_' as const
export const QUALIFIER_OF_IDENTIFICATION_CODE_PREFIX = 'QUALIFIER_OF_IDENTIFICATION_' as const
export const COUNTRY = 'COUNTRY' as const
export const CUSTOMS_OFFICES = 'CUSTOMS_OFFICES' as const
export const BULK_PACKAGE_TYPES = ['PACKAGE_TYPE_VQ', 'PACKAGE_TYPE_VG', 'PACKAGE_TYPE_VL', 'PACKAGE_TYPE_VY', 'PACKAGE_TYPE_VR', 'PACKAGE_TYPE_VS', 'PACKAGE_TYPE_VO'] as const
export const UNPACKED_PACKAGE_TYPES = ['PACKAGE_TYPE_NE', 'PACKAGE_TYPE_NF', 'PACKAGE_TYPE_NG'] as const

export const REDUCED_DATASET_AUTHORISATION_TYPE = `${AUTHORISATION_TYPE_CODE_PREFIX}C524`

const isSupportedNctsCountry = (extractedCountry: string | null): extractedCountry is NctsCountry => {
  if (extractedCountry === null) return true
  return NctsCountries.includes(extractedCountry as NctsCountry)
}

export type CodelistPrefix = typeof AUTHORISATION_TYPE_CODE_PREFIX | typeof GUARANTEE_TYPE_CODE_PREFIX | typeof SECURITY_PREFIX |
  typeof COUNTRY_CODE_PREFIX | typeof TRANSPORT_MODE_CODE_PREFIX | typeof IDENTIFICATION_TYPE_CODE_PREFIX |
  typeof CUSTOMS_OFFICE_CODE_PREFIX | typeof DEPARTURE_OFFICE_CODE_PREFIX | typeof DESTINATION_OFFICE_CODE_PREFIX |
  typeof TRANSIT_OFFICE_CODE_PREFIX | typeof TRANSIT_EXIT_OFFICE_CODE_PREFIX | typeof PACKAGE_TYPE_CODE_PREFIX |
  typeof UNIT_CODE_PREFIX | typeof METHOD_OF_PAYMENT_CODE_PREFIX | typeof PROCEDURE_TYPE_CODE_PREFIX |
  typeof DOCUMENT_TYPE_CODE_PREFIX | typeof PREVIOUS_DOCUMENT_TYPE_CODE_PREFIX | typeof SUPPORTING_DOCUMENT_TYPE_CODE_PREFIX |
  typeof TRANSPORT_DOCUMENT_TYPE_CODE_PREFIX | typeof ADDITIONAL_REFERENCE_CODE_PREFIX | typeof ADDITIONAL_INFORMATION_CODE_PREFIX |
  typeof QUALIFIER_OF_IDENTIFICATION_CODE_PREFIX | typeof ADDITIONAL_SUPPLY_CHAIN_ACTOR_CODE_PREFIX

export const removePrefix = (value: string | null, prefix: CodelistPrefix, codelistCodeSliceLength: number = value?.length ?? 0) => {
  if (value === null || value === undefined || !value.startsWith(prefix)) return null

  return value.slice(prefix.length, prefix.length + codelistCodeSliceLength)
}

export const extractCountryFromDepartureOffice = (office: string | null): NctsCountry | null => {
  const countryPartOfOfficeCode = removePrefix(office, DEPARTURE_OFFICE_CODE_PREFIX, 2)

  if (isSupportedNctsCountry(countryPartOfOfficeCode)) return countryPartOfOfficeCode

  return null
}

