import { RuleResult } from 'types/DeclarationP5'
import { PreviousDocument } from '../../schemas/documentSchemas'
import { hasText } from '../../../../common/utils/common-util'

const NA62Validator = {
  validatePreviousDocumentReferenceNumberRequirement: (previousDocument: PreviousDocument): RuleResult => {
    if (!hasText(previousDocument.documentType) && !hasText(previousDocument.procedureType)) {
      return 'NOT_ALLOWED'
    }
    if (previousDocument.procedureType === 'PIIR') {
      return 'NOT_ALLOWED'
    }

    return 'OPTIONAL'
  },
} as const

export default NA62Validator
