import ROUTES from 'config/routes'
import React, { useContext, useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import TableRowStatus from 'routes/Transits/Table/TableRowStatus'
import { UserContext } from 'context/UserContext'
import PdfPreviewModal from 'components/PdfPreviewModal'
import LoadingSpinner from 'components/LoadingSpinner'
import { hasText } from '../../utils/common-util'
import { TransitOperationInfoResponse, TransitOperationResponse } from '../../models'
import TransitSidebarActions from './TransitSidebarActions'
import useTransitOperationInfo from '../../../TransitOperationEditor/hooks/useTransitOperationInfo'

export type SidebarView = 'data' | 'logs'

export interface TransitSidebarHeaderProps {
  transitOperationId: number
  transitStatus: string
  transitOperation: TransitOperationResponse | undefined
  currentView: SidebarView
  changeView: (view: SidebarView) => void
  originalRow: any
  openAuditModal?: () => void
}

function TransitSidebarHeader(props: Readonly<TransitSidebarHeaderProps>) {
  const {
    transitOperationId,
    changeView,
    currentView,
    transitStatus,
    transitOperation,
    originalRow,
    openAuditModal,
  } = props

  const { t } = useTranslation()
  const history = useNavigate()
  const { user } = useContext(UserContext)
  const { pathname } = useLocation()
  const [transitOperationInfo, setTransitOperationInfo] = useState<TransitOperationInfoResponse>()
  const [isPdfPreviewModalVisible, setIsPdfPreviewModalVisible] = useState<boolean>(false)
  const isAuditView = pathname.includes(ROUTES.transit_audit)

  const { getTransitOperationInfo } = useTransitOperationInfo(transitOperationId, user?.role === 'ADMIN')

  useEffect(() => {
    setTransitOperationInfo(getTransitOperationInfo.data)
  }, [transitOperationId, getTransitOperationInfo.data])

  const toggleView = () => {
    if (currentView === 'logs') {
      changeView('data')
    } else {
      changeView('logs')
    }
  }
  const openTransit = () => {
    history(`${ROUTES.transitP5}/${transitOperationId}`)
  }

  return (
    <Offcanvas.Header
      className="border-bottom"
      closeButton
    >
      <div
        className="d-flex flex-grow-1 justify-content-between "
      >
        <div className="d-flex align-items-end ">

          <h1 className="text-dark me-3">{transitOperationId}</h1>
          <TableRowStatus value={transitStatus} />
          <div className="d-flex ms-2 ps-1 flex-column">
            {
              hasText(transitOperation?.mrn) && (
                <small>{transitOperation?.mrn}</small>
              )
            }
            {
              hasText(transitOperation?.lrn) && (
                <small>{transitOperation?.lrn}</small>
              )
            }
          </div>

        </div>
        <div className="d-flex align-items-center">
          {(user?.role === 'ADMIN' && isAuditView && ['IN_REVIEW', 'PROHIBITED_DESCRIPTION'].includes(originalRow.status)) && (
            <div className="me-1">
              <Button
                className="me-1"
                variant="light"
                size="lg"
                onClick={() => {
                  const auditModalFn = openAuditModal ?? (() => null)
                  auditModalFn()
                }}
                disabled={getTransitOperationInfo.isLoading}
                style={{
                  color: !transitOperationInfo?.isReviewed ? 'green' : 'red',
                  borderColor: !transitOperationInfo?.isReviewed ? 'green' : 'red',
                }}
              >
                {
                  getTransitOperationInfo.isLoading && <LoadingSpinner />
                }
                {
                  !getTransitOperationInfo.isLoading && <span>{t('buttons.decideAudit')}</span>
                }
              </Button>
            </div>
          )}
          <Button
            className="rounded-circle wh-34 border-secondary d-flex justify-content-center align-items-end me-2"
            variant="light"
            size="lg"
            onClick={() => setIsPdfPreviewModalVisible(true)}
          >
            <i className="fal fa-file-pdf" />
          </Button>
          <PdfPreviewModal
            isPdfPreviewModalVisible={isPdfPreviewModalVisible}
            setPdfPreviewModalVisible={setIsPdfPreviewModalVisible}
            declarationId={transitOperationId}
            isPhase5
          />
          <Button
            className="me-2"
            variant="outline-dark"
            size="lg"
            onClick={toggleView}
          >
            <i className={`${currentView === 'logs' ? 'fa-times' : 'fa-align-right'} fal me-2`} />
            <span>
              {currentView === 'logs' ? t('buttons.viewLogs', { context: 'close' }) : t('buttons.viewLogs')}
            </span>
          </Button>
          <Button
            className="me-3 text-primary"
            variant="dark"
            size="lg"
            onClick={openTransit}
          >
            <i className="fal me-2 fa-pen" />
            <span>
              {t('common.edit')}
            </span>
          </Button>
          {user?.role === 'ADMIN' && (
            <div className="me-3">
              <TransitSidebarActions
                currentView={currentView}
                transitOperationInfo={transitOperationInfo}
                transitOperation={transitOperation}
                originalRow={originalRow}
                transitOperationId={transitOperationId}
              />
            </div>
          )}
        </div>
      </div>
    </Offcanvas.Header>
  )
}

export default TransitSidebarHeader
