import { RuleResult } from 'types/DeclarationP5'
import { SecurityEnumT } from '../schemas/declarationFormSchema'
import { CL147_SECURITY_AGREEMENT_AREA_COUNTRIES, isTransitionalPeriodActive } from '../../../common/utils/ncts-constants'
import { removePrefix } from '../../../common/utils/classifier-util'
import { CustomsOfficeOfTransitDeclared } from '../schemas/commonConsignmentSchemas'
import B1831Validator from './transitional/B1831'

const C0598Validator = {
  validateTransitOfficeDateTimeRequirement: (
    departureCustomsOffice: string,
    security: SecurityEnumT,
    transitCustomsOffice: CustomsOfficeOfTransitDeclared,
  ): RuleResult => {
    if (isTransitionalPeriodActive()) {
      return B1831Validator.validateTransitOfficeDateTimeRequirement(departureCustomsOffice, transitCustomsOffice, security)
    }

    if (['1', '3'].includes(security) && CL147_SECURITY_AGREEMENT_AREA_COUNTRIES
      .includes(removePrefix(transitCustomsOffice.referenceNumber, 'TRANSIT_OFFICE_', 2) ?? '')) {
      return 'REQUIRED'
    }

    return 'OPTIONAL'
  },
} as const

export default C0598Validator
