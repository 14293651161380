/* tslint:disable */
/* eslint-disable */
/**
 * Tardek API Endpoints
 * This Is a documentation for T1 Tardek API Endpoints. <br />
 *
 * OpenAPI spec version: 1.0.0
 * Contact: info@bitweb.ee
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { FileResponse } from './file-response';
 /**
 *
 *
 * @export
 * @interface DocumentResponse
 */
export interface DocumentResponse {

    /**
     * Document ID
     *
     * @type {number}
     * @memberof DocumentResponse
     * @example 1
     */
    id: number | null;
    /**
     * Document type
     *
     * @type {string}
     * @memberof DocumentResponse
     * @example NOTE
     */
    type: DocumentResponseTypeEnum;

    /**
     * Document Reference Number
     *
     * @type {string}
     * @memberof DocumentResponse
     * @example EE1000
     */
    referenceNumber: string | null;
    /**
     * Document Transport Reference Number
     *
     * @type {string}
     * @memberof DocumentResponse
     * @example 1111111
     */
    transportDocumentReferenceNumber?: string;

    /**
     * Document Complement Of Information
     *
     * @type {string}
     * @memberof DocumentResponse
     * @example EE101
     */
    complementOfInformation?: string;

    /**
     * Document Type
     *
     * @type {string}
     * @memberof DocumentResponse
     * @example PREVIOUS_DOCUMENT_TYPE_EE11
     */
    documentType: string | null;

    /**
     * Document Line Item Number
     *
     * @type {string}
     * @memberof DocumentResponse
     * @example 1
     */
    documentLineItemNumber?: string;

    /**
     * Document Transit Operation ID
     *
     * @type {number}
     * @memberof DocumentResponse
     * @example 2
     */
    transitOperationId?: number;

    /**
     * Document Sequence Number
     *
     * @type {number}
     * @memberof DocumentResponse
     * @example 0
     */
    sequenceNumber: number | null;

    /**
     * Document Consignment ID
     *
     * @type {number}
     * @memberof DocumentResponse
     * @example 5
     */
    consignmentId: number | null;

    /**
     * Document Consignment Item ID
     *
     * @type {number}
     * @memberof DocumentResponse
     * @example 10
     */
    consignmentItemId: number | null;
    /**
     * Document House Consignment Item ID
     *
     * @type {number}
     * @memberof DocumentResponse
     * @example 11
     */
    houseConsignmentId: number | null;
    /**
     * Document Files
     *
     * @type {Array<FileResponse>}
     * @memberof DocumentResponse
     */
    files: Array<FileResponse>;

    /**
     * Previous Procedure type
     *
     * @type {string}
     * @memberof DocumentResponse
     */
    procedureType?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum DocumentResponseTypeEnum {
    PREVIOUS = 'PREVIOUS',
    SUPPORTING = 'SUPPORTING',
    TRANSPORT = 'TRANSPORT',
    NOTE = 'NOTE',
}

