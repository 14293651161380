import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import
{
  ArrayScope, NestedFieldScope, ObjectScope, ScopeProps,
}
  from 'types/DeclarationP5'
import { useFormContext } from 'react-hook-form'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import FormField from '../../components/FormField'
import Input from '../../components/Input'
import { DepartureTransportMeansType } from '../../form/schemas/commonConsignmentSchemas'
import useFieldArrayActionHelper from '../../services/useFieldArrayActionHelper'
import FormSelect from '../../components/FormSelect'
import useCodelist from '../../../common/hooks/useCodelist'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'

import { blankItemDepartureTransportMeans } from '../../form'
import R0855Validator from '../../form/validators/final-rules/R0855'
import { excludeDeleted } from '../../../common/utils/common-util'
import E1103Validator from '../../form/validators/E1103'
import FormFieldArray from '../../components/FormFieldArray'

const expectedScope = /departureTransportMeans\.\d+/g
type DepartureTransportMeansItemScope = NestedFieldScope<`departureTransportMeans.${number}`>
const isDepartureTransportMeansItemScope = (unknownScope: ObjectScope): unknownScope is `departureTransportMeans.${number}` => (
  Array.from(unknownScope.matchAll(expectedScope))?.length ?? 0) > 0

function DepartureTransportMeans({ scope }: Readonly<ScopeProps<ArrayScope>>) {
  const { t } = useTranslation()
  const {
    addAction,
    removeAction,
    getFields,
    arrayRule,
  } = useFieldArrayActionHelper<DepartureTransportMeansType>({
    blankItem: blankItemDepartureTransportMeans,
    name: scope,
  })
  const {
    watch,
    getValues,
    setValue,
  } = useFormContext<DeclarationForm>()
  const inlandModeOfTransport = watch('inlandModeOfTransport')

  const [, countriesOptions] = useCodelist('COUNTRIES')
  const [, inlandTransportIdentificationsOptions] = useCodelist('TRANSPORT_IDENTIFICATIONS', inlandModeOfTransport)

  useEffect(() => {
    getValues('departureTransportMeans')?.forEach((item, index) => {
      if (!inlandModeOfTransport || !inlandTransportIdentificationsOptions
        .find((option) => option.value === item.typeOfIdentification)) {
        setValue(`departureTransportMeans.${index}.typeOfIdentification`, '', {
          shouldDirty: true,
          shouldTouch: true,
        })
      }
    })
  }, [inlandModeOfTransport])

  const departureTransportMeansMultiplicity = useMemo(() => R0855Validator
    .getDepartureTransportMeansMultiplicity(inlandModeOfTransport), [inlandModeOfTransport])
  const isAddButtonDisabled = arrayRule === 'NOT_ALLOWED'
    || getFields().filter(excludeDeleted).length >= departureTransportMeansMultiplicity

  return (
    <section className="py-3">
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-deprture-transport"
        headingLabel={t('declaration.p5.departureTransportMeans')}
      >
        {getFields().map((departureTransportMeans, index) => {
          const innerItemScope: ObjectScope = `${scope}.${index}` as const
          if (!isDepartureTransportMeansItemScope(innerItemScope)) throw Error('Unable to narrow, invalid scope')

          return (
            <FormFieldArray
              key={`departureTransportMeans_${departureTransportMeans.id}`}
              titleKey="declaration.p5.sequenceNumber"
              removeItem={() => removeAction(index, departureTransportMeans)}
              sequenceNumber={departureTransportMeans.sequenceNumber + 1}
              hidden={departureTransportMeans.deleted}
            >
              <FormField labelKey="declaration.p5.typeOfIdentification">
                <FormSelect
                  <DepartureTransportMeansItemScope>
                  field={`${innerItemScope}.typeOfIdentification`}
                  labelKey="declaration.p5.typeOfIdentification"
                  options={inlandTransportIdentificationsOptions}
                  isDisabled={!inlandModeOfTransport}
                  type="sync"
                />
              </FormField>

              <FormField labelKey="declaration.p5.identificationNumber">
                <Input
                  <DepartureTransportMeansItemScope>
                  field={`${innerItemScope}.identificationNumber`}
                  labelKey="declaration.p5.identificationNumber"
                  type="text"
                  maxLength={E1103Validator.getIdentificationNumberMaxLength()}
                  placeholder={t('declaration.p5.identificationNumber')}
                />
              </FormField>

              <FormField labelKey="declaration.p5.nationality">
                <FormSelect
                  <DepartureTransportMeansItemScope>
                  field={`${innerItemScope}.nationality`}
                  labelKey="declaration.p5.nationality"
                  options={countriesOptions}
                  type="sync"
                />
              </FormField>
            </FormFieldArray>
          )
        })}
        <div className="row">
          <div className={`col-12 text-end ${isAddButtonDisabled ? 'cursor--not-allowed' : ''}`}>
            <button
              type="button"
              className="btn btn-sm btn-primary mt-2"
              onClick={() => addAction()}
              disabled={isAddButtonDisabled}
            >
              <i className="fa fa-plus me-2" />
              {t('declaration.p5.addNewTransportMeans')}
            </button>
          </div>
        </div>
      </CollapsibleColumn>
    </section>
  )
}

export default DepartureTransportMeans
