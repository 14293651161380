import React, { useContext, useEffect } from 'react'
import Nav from 'react-bootstrap/Nav'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { UserContext } from 'context/UserContext'
import { isTransitionalPeriodActive } from '../../common/utils/ncts-constants'
import { hasText } from '../../common/utils/common-util'

const allTabs: ReadonlyArray<string> = ['general', 'transit-parties', 'consignment', 'goods', 'summary', 'directo', 'logs'] as const
export type Tab = typeof allTabs[number]

function isTab(key: string | null) {
  return key != null && allTabs.includes(key)
}

interface NavigationBarTabsProps {
  onTabChange: (tab: Tab) => void
  currentTab: Tab
}

function NavigationBarTabs({ onTabChange, currentTab }: NavigationBarTabsProps) {
  const { t } = useTranslation()
  const location = useLocation()

  const { user } = useContext(UserContext)
  const onSelect = (key: string | null) => {
    if (isTab(key)) {
      if (key === 'directo' && user?.role !== 'ADMIN') return
      onTabChange(key as Tab)
    } else {
      throw new Error(`Unknown tab: ${key}`)
    }
  }
  useEffect(() => {
    if (hasText(location.hash)) {
      onSelect(location.hash.substring(1))
    }
  }, [location.hash])

  return (
    <div className="d-grid pt-2">
      <Nav
        justify
        variant="tabs"
        defaultActiveKey="general"
        activeKey={location.hash.substring(1).length > 0 ? location.hash.substring(1) : currentTab}
      >
        <Nav.Item>
          <Nav.Link eventKey="general" className="text-uppercase" href="#general">
            {t('declaration.navbar.general')}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="transit-parties" className="text-uppercase" href="#transit-parties">
            {t('declaration.navbar.transitParties')}
          </Nav.Link>
        </Nav.Item>
        {!isTransitionalPeriodActive() && (
          <Nav.Item>
            <Nav.Link eventKey="consignment" className="text-uppercase" href="#consignment">
              {t('declaration.navbar.consignment')}
            </Nav.Link>
          </Nav.Item>
        )}
        {isTransitionalPeriodActive() && (
          <Nav.Item>
            <Nav.Link eventKey="goods" className="text-uppercase" href="#goods">
              {t('declaration.headers.goods')}
            </Nav.Link>
          </Nav.Item>
        )}
        <Nav.Item>
          <Nav.Link eventKey="summary" className="text-uppercase" href="#summary">
            {t('declaration.navbar.summary')}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          {user?.role === 'ADMIN' ? (
            <Nav.Link eventKey="directo" className="text-uppercase" href="#directo">
              {t('declaration.navbar.directo')}
            </Nav.Link>
          ) : null }
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="logs" className="text-uppercase" href="#logs">
            {t('declaration.headers.logs')}
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </div>
  )
}

export default NavigationBarTabs
