import { ConsignmentItem } from '../../schemas/consignmentItemSchema'

const R0223Validator = {
  isNetMassValid: (consignmentItem: ConsignmentItem) => {
    const netMass = consignmentItem.goodsMeasureNetMass ?? 0
    if (consignmentItem.goodsMeasureGrossMass > 0) {
      return consignmentItem.goodsMeasureGrossMass >= netMass
    }

    return true
  },
}

export default R0223Validator
