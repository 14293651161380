import { ZodIssue } from 'zod'
import { FormScope, RuleResult } from '../../../../../types/DeclarationP5'
import { excludeDeleted, hasText } from '../../../common/utils/common-util'
import R3062Validator from './final-rules/R3062'
import C0001Validator from './C0001'
import C0909Validator from './C0909'
import C0250Validator from './C0250'
import C0505Validator from './C0505'
import R0472Validator from './final-rules/R0472'
import C0837Validator from './C0837'
import C0343Validator from './C0343'
import R0507Validator from './final-rules/R0507'
import C0502Validator from './C0502'
import C0153Validator from './C0153'
import NA59Validator from './EE/NA59'
import NA60Validator from './EE/NA60'
import NA61Validator from './EE/NA61'
import NA62Validator from './EE/NA62'
import { AmountUnit } from '../schemas/consignmentItemSchema'
import R0223Validator from './final-rules/R0223'
import R0601Validator from './final-rules/R0601'
import R0909Validator from './final-rules/R0909'
import R0911Validator from './final-rules/R0911'
import R0020Validator from './final-rules/R0020'
import RP01Validator from './PL/RP01'
import C0035Validator from './C0035'
import B1820Validator from './transitional/B1820'
import B2400Validator from './transitional/B2400'
import B1896Validator from './transitional/B1896'
import C0015Validator from './C0015'
import R3061Validator from './final-rules/R3061'
import { removePrefix } from '../../../common/utils/classifier-util'
import C0060Validator from './C0060'
import R0220Validator from './final-rules/R0220'
import R0364Validator from './final-rules/R0364'
import { DeclarationForm } from '../schemas/declarationFormSchema'
import { HouseConsignmentType } from '../schemas/houseConsignmentSchema'
import R0221Validator from './final-rules/R0221'

interface InvokeHouseConsignmentValidatorsProps {
  formData: DeclarationForm
  country: string | null
  houseIndex: number
  houseConsignment: HouseConsignmentType
  updatePathResult: (path: FormScope, result: RuleResult) => void
  addPathIssue: (path: FormScope, issue: ZodIssue) => void
  isHouseConsigneeAllowed: boolean
  houseConsignmentUcrRequirement: RuleResult
  departureMeansForHouseRequirement: RuleResult
  nomenclatureCodeRequirement: RuleResult
  isHouseDepartureMeansEnabled: boolean
  houseConsignmentConsignorRequirement: RuleResult
  previousDocumentRequirementForAllItems: RuleResult
  hasUniqueHouseUCRs: boolean
  hasUniqueHouseConsignees: boolean
  hasUniqueHouseConsignors: boolean
  hasUniqueHouseDepartureMeans: boolean
  hasUniqueHouseConsignmentDestinationCountries: boolean
  hasUniqueHouseConsignmentDispatchCountries: boolean
  hasUniqueItemConsignees: boolean
}

const invokeHouseConsignmentValidators = (props: InvokeHouseConsignmentValidatorsProps): void => {
  const {
    country,
    houseIndex,
    houseConsignment,
    formData,
    updatePathResult,
    addPathIssue,
    isHouseConsigneeAllowed,
    houseConsignmentUcrRequirement,
    departureMeansForHouseRequirement,
    isHouseDepartureMeansEnabled,
    houseConsignmentConsignorRequirement,
    nomenclatureCodeRequirement,
    previousDocumentRequirementForAllItems,
    hasUniqueHouseUCRs,
    hasUniqueHouseConsignees,
    hasUniqueHouseConsignors,
    hasUniqueHouseDepartureMeans,
    hasUniqueHouseConsignmentDestinationCountries,
    hasUniqueHouseConsignmentDispatchCountries,
    hasUniqueItemConsignees,
  } = props
  const houseScope: FormScope = `houseConsignment.${houseIndex}` as const
  const isConsignmentItemConsigneeAllowed = B2400Validator.isDataGroupAllowed()
  // TODO: E1301
  const houseConsignmentAdditionalInformationRequirement = 'NOT_ALLOWED' /* isTransitionalPeriodActive() ? 'NOT_ALLOWED' : 'OPTIONAL' */

  updatePathResult(`${houseScope}.additionalSupplyChainActor`, 'NOT_ALLOWED') // TODO: E1301
  updatePathResult(`${houseScope}.additionalInformation`, houseConsignmentAdditionalInformationRequirement)
  updatePathResult(`${houseScope}.additionalReference`, 'NOT_ALLOWED') // TODO: E1301

  if (houseConsignmentAdditionalInformationRequirement !== 'NOT_ALLOWED') {
    houseConsignment.additionalInformation.filter(excludeDeleted).forEach((item, index) => {
      if (!R3062Validator.isAdditionalInformationCodeValid(formData, item.code)) {
        addPathIssue(`${houseScope}.additionalInformation.${index}.code`, {
          code: 'custom', path: `${houseScope}.additionalInformation.${index}.code`.split('.'), fatal: true, message: 'R3062',
        })
      }
    })
  }

  let houseConsignmentConsigneeRequirement: RuleResult = 'NOT_ALLOWED'
  if (isHouseConsigneeAllowed) {
    houseConsignmentConsigneeRequirement = C0001Validator
      .validateConsigneeRequirementForHouseConsignment(formData, houseConsignment)
  }

  if (houseConsignmentUcrRequirement !== 'NOT_ALLOWED' && !hasUniqueHouseUCRs) {
    addPathIssue(`${houseScope}.referenceNumberUCR`, {
      code: 'custom', path: `${houseScope}.referenceNumberUCR`.split('.'), fatal: true, message: 'R0506',
    })
  }
  if (houseConsignmentConsigneeRequirement !== 'NOT_ALLOWED' && !hasUniqueHouseConsignees) {
    addPathIssue(`${houseScope}.consignee`, {
      code: 'custom', path: `${houseScope}.consignee`.split('.'), fatal: true, message: 'R0506',
    })
  }
  if (houseConsignmentConsignorRequirement !== 'NOT_ALLOWED' && !hasUniqueHouseConsignors) {
    addPathIssue(`${houseScope}.consignor`, {
      code: 'custom', path: `${houseScope}.consignor`.split('.'), fatal: true, message: 'R0506',
    })
  }
  if (isHouseDepartureMeansEnabled && !hasUniqueHouseDepartureMeans) {
    addPathIssue(`${houseScope}.departureTransportMeans`, {
      code: 'custom', path: `${houseScope}.departureTransportMeans`.split('.'), fatal: true, message: 'R0506',
    })
  }

  let houseConsignmentCountryOfDestinationRequirement = C0343Validator
    .validateHouseConsignmentCountryOfDestinationRequirement(formData, houseConsignment)
  if (country === 'EE') {
    // TODO: countryOfDestination andmeväli ei ole hetkel toetatud EMTA-s houseConsignment küljes
    houseConsignmentCountryOfDestinationRequirement = 'NOT_ALLOWED'
  }

  if (houseConsignmentCountryOfDestinationRequirement !== 'NOT_ALLOWED' && !hasUniqueHouseConsignmentDestinationCountries) {
    addPathIssue(`${houseScope}.countryOfDestination`, {
      code: 'custom', path: `${houseScope}.countryOfDestination`.split('.'), fatal: true, message: 'R0506',
    })
  }

  const houseConsignmentCountryOfDispatchRequirement = C0909Validator
    .validateCountryOfDispatchForHouseConsignmentRequirement(formData, houseConsignment)

  if (houseConsignmentCountryOfDispatchRequirement !== 'NOT_ALLOWED' && !hasUniqueHouseConsignmentDispatchCountries) {
    addPathIssue(`${houseScope}.countryOfDispatch`, {
      code: 'custom', path: `${houseScope}.countryOfDispatch`.split('.'), fatal: true, message: 'R0506',
    })
  }

  updatePathResult(`${houseScope}.referenceNumberUCR`, houseConsignmentUcrRequirement)
  updatePathResult(`${houseScope}.departureTransportMeans`, departureMeansForHouseRequirement)
  updatePathResult(`${houseScope}.countryOfDispatch`, houseConsignmentCountryOfDispatchRequirement)
  updatePathResult(`${houseScope}.countryOfDestination`, houseConsignmentCountryOfDestinationRequirement)
  updatePathResult(`${houseScope}.consignee`, houseConsignmentConsigneeRequirement)
  updatePathResult(`${houseScope}.consignee.address`, houseConsignmentConsigneeRequirement)

  if (houseConsignmentConsigneeRequirement !== 'NOT_ALLOWED') {
    const houseConsigneeDetailsRequirement: RuleResult = country === 'RO'
      ? C0250Validator.validateNameAndAddressRequirement(houseConsignment.consignee?.identificationNumber)
      : 'REQUIRED'

    updatePathResult(`${houseScope}.consignee.name`, houseConsigneeDetailsRequirement)
    updatePathResult(`${houseScope}.consignee.address`, houseConsigneeDetailsRequirement)
    if (houseConsigneeDetailsRequirement !== 'NOT_ALLOWED') {
      updatePathResult(`${houseScope}.consignee.address.country`, houseConsigneeDetailsRequirement)
      updatePathResult(`${houseScope}.consignee.address.streetAndNumber`, houseConsigneeDetailsRequirement)
      updatePathResult(`${houseScope}.consignee.address.city`, houseConsigneeDetailsRequirement)
      const houseConsigneePostcodeRequirement = C0505Validator.validatePostcodeRequirement(houseConsignment.consignee?.address?.country)
      updatePathResult(`${houseScope}.consignee.address.postcode`, houseConsigneePostcodeRequirement)
    }
  }

  if (departureMeansForHouseRequirement !== 'NOT_ALLOWED') {
    houseConsignment.departureTransportMeans.filter(excludeDeleted).forEach((item, index) => {
      if (!R0472Validator.isDepartureTransportMeansTypeOfIdentificationValid(formData, item)) {
        addPathIssue(`${houseScope}.departureTransportMeans.${index}.typeOfIdentification`, {
          code: 'custom', path: [`${houseScope}.departureTransportMeans.${index}.typeOfIdentification`], fatal: true, message: 'R0472',
        })
      }
    })
  }

  updatePathResult(`${houseScope}.consignor`, houseConsignmentConsignorRequirement)
  updatePathResult(`${houseScope}.consignor.address`, houseConsignmentConsignorRequirement)
  if (houseConsignmentConsignorRequirement !== 'NOT_ALLOWED') {
    const houseConsignorDetailsRequirement = country === 'RO'
      ? C0250Validator.validateNameAndAddressRequirement(houseConsignment.consignor?.identificationNumber)
      : 'REQUIRED'

    updatePathResult(`${houseScope}.consignor.name`, houseConsignorDetailsRequirement)
    updatePathResult(`${houseScope}.consignor.address`, houseConsignorDetailsRequirement)
    if (houseConsignorDetailsRequirement !== 'NOT_ALLOWED') {
      updatePathResult(`${houseScope}.consignor.address.country`, houseConsignorDetailsRequirement)
      updatePathResult(`${houseScope}.consignor.address.streetAndNumber`, houseConsignorDetailsRequirement)
      updatePathResult(`${houseScope}.consignor.address.city`, houseConsignorDetailsRequirement)
      const houseConsignorPostcodeRequirement = C0505Validator.validatePostcodeRequirement(houseConsignment.consignor?.address?.country)
      updatePathResult(`${houseScope}.consignor.address.postcode`, houseConsignorPostcodeRequirement)
    }
  }
  const goodsItemsNetMassRequirement = C0837Validator.validateNetMassRequirement(houseConsignment, formData.reducedDatasetIndicator)

  if (country === 'EE') {
    houseConsignment.previousDocument.filter(excludeDeleted).forEach((housePreviousDocument, previousDocumentIndex) => {
      const previousDocumentDocumentTypeRequirement = NA60Validator
        .validatePreviousDocumentDocumentTypeRequirement(housePreviousDocument)

      // avoid lock
      if (previousDocumentDocumentTypeRequirement !== 'NOT_ALLOWED') {
        const previousDocumentProcedureTypeRequirement = NA60Validator
          .validatePreviousDocumentProcedureTypeRequirement(housePreviousDocument)

        updatePathResult(
          `${houseScope}.previousDocument.${previousDocumentIndex}.documentType`,
          previousDocumentProcedureTypeRequirement,
        )
      }

      updatePathResult(
        `${houseScope}.previousDocument.${previousDocumentIndex}.documentType`,
        previousDocumentDocumentTypeRequirement,
      )

      let referenceNumberRequirement = NA61Validator
        .validatePreviousDocumentRefNumberRequirement(housePreviousDocument)

      if (referenceNumberRequirement === 'OPTIONAL') {
        referenceNumberRequirement = NA62Validator
          .validatePreviousDocumentReferenceNumberRequirement(housePreviousDocument)

        if (referenceNumberRequirement === 'NOT_ALLOWED' && hasText(housePreviousDocument.referenceNumber)) {
          referenceNumberRequirement = 'OPTIONAL'
          addPathIssue(`${houseScope}.previousDocument.${previousDocumentIndex}.referenceNumber`, {
            code: 'custom', path: `${houseScope}.previousDocument.${previousDocumentIndex}.referenceNumber`.split('.'), fatal: true, message: 'NA62 (Forbidden field filled)',
          })
        }
      }

      updatePathResult(
        `${houseScope}.previousDocument.${previousDocumentIndex}.referenceNumber`,
        referenceNumberRequirement,
      )
    })
  }

  // All house array types
  const {
    consignmentItem,
  } = houseConsignment

  const consignmentItemCountryOfDispatchRequirement = C0909Validator
    .validateCountryOfDispatchForConsignmentItemRequirement(formData, houseConsignment)

  const consignmentItemCountryOfDestinationRequirement = C0343Validator
    .validateItemCountryOfDestinationRequirement(formData, houseConsignment)

  const isItemDestinationCountryEnabled = consignmentItemCountryOfDestinationRequirement !== 'NOT_ALLOWED'

  const hasUniqueItemDestinationCountries = isItemDestinationCountryEnabled
    ? R0507Validator.hasMultipleUniqueCountryOfDestinationForConsignmentItem(houseConsignment) : true

  const hasUniqueItemDispatchCountries = consignmentItemCountryOfDispatchRequirement !== 'NOT_ALLOWED'
    ? R0507Validator.hasMultipleUniqueCountryOfDispatchForConsignmentItem(houseConsignment) : true

  const consignmentItemUcrRequirement = C0502Validator.validateConsignmentItemUcrRequirement(formData, houseConsignment)
  const isItemUcrEnabled = (consignmentItemUcrRequirement !== 'NOT_ALLOWED')
  const hasUniqueItemReferenceUcr = isItemUcrEnabled
    ? R0507Validator.hasMultipleUniqueReferenceNumberUcrForConsignmentItem(houseConsignment) : true

  const commodityCodeRequirement: RuleResult = C0153Validator.validateCommodityCodeRequirement(formData, houseConsignment)

  consignmentItem.forEach((item, consignmentItemIndex) => {
    if (item.deleted) {
      return
    }
    const itemScope: FormScope = `${houseScope}.consignmentItem.${consignmentItemIndex}` as const

    if (country === 'EE') {
      if (!NA59Validator.hasConsignmentItemPreviousDocumentsWithTypes(item.previousDocument)) {
        addPathIssue(`${itemScope}.previousDocument`, {
          code: 'custom', path: `${itemScope}.previousDocument`.split('.'), fatal: true, message: 'NA59',
        })
      } else {
        item.previousDocument.forEach((previousDocument, index) => {
          if (previousDocument.deleted) {
            return
          }
          // eslint-disable-next-line max-len
          const previousDocumentScope: FormScope = `${houseScope}.consignmentItem.${consignmentItemIndex}.previousDocument.${index}` as const
          const documentTypeRequirement = NA60Validator.validatePreviousDocumentDocumentTypeRequirement(previousDocument)
          updatePathResult(`${previousDocumentScope}.documentType`, documentTypeRequirement)

          // Checks to avoid lock loop
          if (documentTypeRequirement !== 'NOT_ALLOWED') {
            const procedureRequirement = NA60Validator.validatePreviousDocumentProcedureTypeRequirement(previousDocument)
            updatePathResult(`${previousDocumentScope}.procedureType`, procedureRequirement)
          }

          let referenceNumberRequirement = NA61Validator.validatePreviousDocumentRefNumberRequirement(previousDocument)

          if (referenceNumberRequirement === 'OPTIONAL') {
            referenceNumberRequirement = NA62Validator.validatePreviousDocumentReferenceNumberRequirement(previousDocument)

            if (referenceNumberRequirement === 'NOT_ALLOWED' && hasText(previousDocument.referenceNumber)) {
              referenceNumberRequirement = 'OPTIONAL'
              addPathIssue(`${previousDocumentScope}.referenceNumber`, {
                code: 'custom', path: `${previousDocumentScope}.referenceNumber`.split('.'), fatal: true, message: 'NA62 (Forbidden field filled)',
              })
            }
          }
          updatePathResult(`${previousDocumentScope}.referenceNumber`, referenceNumberRequirement)
        })
      }
    }
    if (country === 'RO') {
      if (item.sequenceNumber === 0) {
        if (item.dutyRateUnit === AmountUnit.AMOUNT) {
          updatePathResult(`${itemScope}.dutyAmount`, 'REQUIRED')
        } else {
          updatePathResult(`${itemScope}.dutyRate`, 'REQUIRED')
        }
        updatePathResult(`${itemScope}.price`, 'REQUIRED')
        updatePathResult(`${itemScope}.vatRate`, 'REQUIRED')
      }
    }

    updatePathResult(`${houseScope}.consignmentItem.${consignmentItemIndex}.packaging`, 'REQUIRED')
    item.packaging.forEach((packaging, packagingIndex) => {
      updatePathResult(`${houseScope}.consignmentItem.${consignmentItemIndex}.packaging.${packagingIndex}.typeOfPackages`, 'REQUIRED')
    })

    if (consignmentItemCountryOfDispatchRequirement !== 'NOT_ALLOWED' && !hasUniqueItemDispatchCountries) {
      addPathIssue(`${itemScope}.countryOfDispatch`, {
        code: 'custom', path: `${itemScope}.countryOfDispatch`.split('.'), fatal: true, message: 'R0507',
      })
    }

    if (isItemDestinationCountryEnabled && !hasUniqueItemDestinationCountries) {
      addPathIssue(`${itemScope}.countryOfDestination`, {
        code: 'custom', path: `${itemScope}.countryOfDestination`.split('.'), fatal: true, message: 'R0507',
      })
    }

    if (!hasUniqueItemReferenceUcr) {
      addPathIssue(`${itemScope}.referenceNumberUCR`, {
        code: 'custom', path: `${itemScope}.referenceNumberUCR`.split('.'), fatal: true, message: 'R0507',
      })
    }

    updatePathResult(`${itemScope}.commodityHarmonizedSystemSubHeadingCode`, commodityCodeRequirement)
    updatePathResult(`${itemScope}.commodityCombinedNomenclatureCode`, nomenclatureCodeRequirement)
    updatePathResult(`${itemScope}.referenceNumberUCR`, consignmentItemUcrRequirement)
    updatePathResult(`${itemScope}.countryOfDestination`, consignmentItemCountryOfDestinationRequirement)
    updatePathResult(`${itemScope}.countryOfDispatch`, consignmentItemCountryOfDispatchRequirement)
    updatePathResult(`${itemScope}.goodsMeasureNetMass`, goodsItemsNetMassRequirement)

    if (goodsItemsNetMassRequirement !== 'NOT_ALLOWED') {
      if (!R0223Validator.isNetMassValid(item)) {
        addPathIssue(`${itemScope}.goodsMeasureNetMass`, {
          code: 'custom', path: `${itemScope}.goodsMeasureNetMass`.split('.'), fatal: true, message: 'R0223 : Net mass must be less than or equal to gross mass',
        })
      }
    }

    if (item.commodityCodeArchived) {
      addPathIssue(`${itemScope}.commodityHarmonizedSystemSubHeadingCode`, {
        code: 'custom', path: `${itemScope}.commodityHarmonizedSystemSubHeadingCode`.split('.'), fatal: true, message: 'ARCHIVED',
      })
    }

    const hasValidDeclarationType = R0601Validator.hasCorrectDeclarationTypeForProvidedDocument(item, houseConsignment, formData)
    if (!hasValidDeclarationType) {
      addPathIssue(`${itemScope}.declarationType`, {
        code: 'custom', path: `${itemScope}.declarationType`.split('.'), fatal: true, message: 'R0601',
      })
      addPathIssue('declarationType', {
        code: 'custom', path: ['declarationType'], fatal: true, message: 'R0601',
      })
    }

    if (!R0909Validator.areDeclarationTypeAndCustomsOfficeOfDepartureCountryCorrect(formData, item)) {
      if (!hasValidDeclarationType) {
        addPathIssue(`${itemScope}.declarationType`, {
          code: 'custom', path: `${itemScope}.declarationType`.split('.'), fatal: true, message: 'R0909',
        })
        addPathIssue('declarationType', {
          code: 'custom', path: ['declarationType'], fatal: true, message: 'R0909',
        })
      }
    }

    const isDeclarationTypeValid = R0911Validator.isDeclarationTypeValidForCustomsOfficeOfDepartureAndDestination(formData)
    if (!isDeclarationTypeValid) {
      addPathIssue(`${itemScope}.declarationType`, {
        code: 'custom', path: `${itemScope}.declarationType`.split('.'), fatal: true, message: 'R0911',
      })
      addPathIssue('declarationType', {
        code: 'custom', path: ['declarationType'], fatal: true, message: 'R0911',
      })
    }
    const hasRequiredPreviousDoc = R0020Validator.hasAtLeastOnePreviousDocumentValidForConsignmentItem(formData, item)
    if (!hasRequiredPreviousDoc) {
      addPathIssue(`${itemScope}.previousDocument`, {
        code: 'custom', path: `${itemScope}.previousDocument`.split('.'), fatal: true, message: 'R0020',
      })
    }

    let previousDocumentForConsignmentItemRequired = previousDocumentRequirementForAllItems
    if (country === 'PL') {
      previousDocumentForConsignmentItemRequired = RP01Validator
        .validatePreviousDocumentRequirementForHouseAndItem(formData.houseConsignment) === 'REQUIRED'
      && formData.security === '0'
      && formData.declarationType === 'T1' ? 'REQUIRED' : 'OPTIONAL'
    } else if (previousDocumentForConsignmentItemRequired !== 'REQUIRED') {
      previousDocumentForConsignmentItemRequired = C0035Validator.isPreviousDocumentForConsignmentItemRequired(formData, item)
    }

    if (previousDocumentForConsignmentItemRequired) {
      updatePathResult(`${itemScope}.previousDocument`, previousDocumentForConsignmentItemRequired)
    }
    let consigneeOnConsignmentItemRequirement = isConsignmentItemConsigneeAllowed
      ? B1820Validator.validateConsigneeOnConsignmentItemRequirement(formData, item)
      : 'NOT_ALLOWED'
    if (consigneeOnConsignmentItemRequirement !== 'NOT_ALLOWED') {
      consigneeOnConsignmentItemRequirement = 'REQUIRED'
    }
    updatePathResult(`${itemScope}.consignee`, consigneeOnConsignmentItemRequirement)
    // ToDo: Check with UK and / or other countries
    // updatePathResult(`${itemScope}.consignee.identificationNumber`, consigneeOnConsignmentItemRequirement)
    updatePathResult(`${itemScope}.consignee.address`, consigneeOnConsignmentItemRequirement)

    if (consigneeOnConsignmentItemRequirement !== 'NOT_ALLOWED') {
      const itemConsigneeDetailsRequirement: RuleResult = country === 'RO' ? C0250Validator.validateNameAndAddressRequirement(item.consignee?.identificationNumber) : 'REQUIRED'
      updatePathResult(`${itemScope}.consignee.name`, itemConsigneeDetailsRequirement)
      updatePathResult(`${itemScope}.consignee.address`, itemConsigneeDetailsRequirement)
      // @ts-ignore
      if (itemConsigneeDetailsRequirement !== 'NOT_ALLOWED') {
        updatePathResult(`${itemScope}.consignee.address.country`, itemConsigneeDetailsRequirement)
        updatePathResult(`${itemScope}.consignee.address.streetAndNumber`, itemConsigneeDetailsRequirement)
        updatePathResult(`${itemScope}.consignee.address.city`, itemConsigneeDetailsRequirement)
        const itemConsigneePostcodeRequirement = C0505Validator.validatePostcodeRequirement(item.consignee?.address?.country)
        updatePathResult(`${itemScope}.consignee.address.postcode`, itemConsigneePostcodeRequirement)
      }
      if (!hasUniqueItemConsignees) {
        addPathIssue(`${itemScope}.consignee`, {
          code: 'custom', path: `${itemScope}.consignee`.split('.'), fatal: true, message: 'B1877',
        })
      }
    }

    if (B2400Validator.isDataGroupAllowed() && country !== 'FI') {
      const itemTransportDocumentRequirement = B1896Validator.validateConsignmentItemTransportDocumentRequirement(formData, item)
      updatePathResult(`${itemScope}.transportDocument`, itemTransportDocumentRequirement)
    }

    // All item array types
    const {
      packaging,
      additionalReference,
      additionalInformation,
    } = item

    updatePathResult(`${itemScope}.additionalSupplyChainActor`, 'NOT_ALLOWED')
    updatePathResult(`${itemScope}.additionalReference`, 'NOT_ALLOWED') // TODO: E1301

    additionalReference.forEach((additionalRef, additionalReferenceIndex) => {
      if (additionalRef.deleted) {
        return
      }

      const additionalReferenceReferenceNumberRequirement = C0015Validator
        .validateConsignmentItemAdditionalReferenceRefNumberRequirement(additionalRef)
      updatePathResult(
        `${itemScope}.additionalReference.${additionalReferenceIndex}.referenceNumber`,
        additionalReferenceReferenceNumberRequirement,
      )
    })

    updatePathResult(`${itemScope}.additionalInformation`, 'NOT_ALLOWED') // TODO: E1301

    additionalInformation.forEach((additionalInfo, additionalInformationIndex) => {
      const isValidCode = R3061Validator.hasValidCodeForAdditionalInformation(additionalInfo)
      // TODO disable when transitional
      if (!isValidCode) {
        addPathIssue(`${itemScope}.additionalInformation.${additionalInformationIndex}.code`, {
          code: 'custom',
          path: `${itemScope}.additionalInformation.${additionalInformationIndex}.code`.split('.'),
          fatal: true,
          message: 'R3061',
        })
      }
    })
    packaging.forEach((packages, packageIndex) => {
      if (packages.deleted) {
        return
      }

      const {
        typeOfPackages,
      } = packages

      const packageType = removePrefix(typeOfPackages, 'PACKAGE_TYPE_')

      const marksRequirement = C0060Validator.validatePackagingShippingMarksRequirement(packageType)
      updatePathResult(
        `${itemScope}.packaging.${packageIndex}.shippingMarks`,
        marksRequirement,
      )

      const isPackagingValid = R0220Validator.isTypeOfPackagesValid(packages)

      if (!isPackagingValid) {
        addPathIssue(`${itemScope}.packaging.${packageIndex}.typeOfPackages`, {
          code: 'custom', path: `${itemScope}.packaging.${packageIndex}.typeOfPackages`.split('.'), fatal: true, message: 'R0220',
        })
      }

      const numberOfPackagesRequirement = C0060Validator.validatePackagingNumberOfPackagesRequirement(packageType)
      updatePathResult(
        `${itemScope}.packaging.${packageIndex}.numberOfPackages`,
        numberOfPackagesRequirement,
      )

      if (numberOfPackagesRequirement !== 'NOT_ALLOWED' && !R0364Validator.validateNumberOfPackages(houseConsignment, item, packages)) {
        addPathIssue(`${itemScope}.packaging.${packageIndex}.numberOfPackages`, {
          code: 'custom', path: `${itemScope}.packaging.${packageIndex}.numberOfPackages`.split('.'), fatal: true, message: 'R0364',
        })
      }

      if (!R0221Validator.isConsignmentGrossMassValid(houseConsignment, item)) {
        addPathIssue(`${itemScope}.goodsMeasureGrossMass`, {
          code: 'custom', path: `${itemScope}.goodsMeasureGrossMass`.split('.'), fatal: true, message: 'R0221 : Gross mass is invalid',
        })
      }
    })
  })
}

export default invokeHouseConsignmentValidators
