import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { removePrefix } from '../../../../common/utils/classifier-util'
import { CL010_CL553_EU_COUNTRY_CODES } from '../../../../common/utils/ncts-constants'

const R0911Validator = {
  isDeclarationTypeValidForCustomsOfficeOfDepartureAndDestination: (formData: DeclarationForm): boolean => {
    const departureOfficeCountry = removePrefix(formData.departureCustomsOffice, 'DEPARTURE_OFFICE_', 2)
    const destinationOfficeCountry = removePrefix(formData.destinationCustomsOffice, 'DESTINATION_OFFICE_', 2)

    if (departureOfficeCountry === 'SM'
      && (destinationOfficeCountry !== null && CL010_CL553_EU_COUNTRY_CODES.includes(destinationOfficeCountry))) {
      return formData.declarationType === 'T2' || formData.declarationType === 'T2F'
    }

    return true
  },
}

export default R0911Validator
