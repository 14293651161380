import { HouseConsignmentType } from '../../schemas/houseConsignmentSchema'
import { ConsignmentItem, PackagingType } from '../../schemas/consignmentItemSchema'
import { excludeDeleted } from '../../../../common/utils/common-util'
import { CL181_BULK_KIND_OF_PACKAGES, CL182_UNPACKED_KIND_OF_PACKAGES } from '../../../../common/utils/ncts-constants'
import { removePrefix } from '../../../../common/utils/classifier-util'

const hasOtherConsignmentItemPackagingWithNonZeroPackages = (consignmentItem: ConsignmentItem, packagingItem: PackagingType) => {
  const {
    packaging,
  } = consignmentItem

  return packaging.filter(excludeDeleted)
    .some((currentPackagingItem) => {
      const numberOfPackages = currentPackagingItem.numberOfPackages ?? 0
      const packageType = removePrefix(currentPackagingItem.typeOfPackages, 'PACKAGE_TYPE_') ?? ''

      return currentPackagingItem.shippingMarks === packagingItem.shippingMarks
        && numberOfPackages > 0
        && !CL181_BULK_KIND_OF_PACKAGES.includes(packageType)
        && !CL182_UNPACKED_KIND_OF_PACKAGES.includes(packageType)
    })
}

const R0364Validator = {
  validateNumberOfPackages: (
    houseConsignment: HouseConsignmentType,
    consignmentItem: ConsignmentItem,
    packagingItem: PackagingType,
  ) => {
    const numberOfPackages = packagingItem.numberOfPackages ?? 0
    if (numberOfPackages > 0) {
      return true
    }

    return houseConsignment.consignmentItem.filter(excludeDeleted)
      .some((otherConsignmentItem) => consignmentItem !== otherConsignmentItem
        && hasOtherConsignmentItemPackagingWithNonZeroPackages(otherConsignmentItem, packagingItem))
  },
}

export default R0364Validator
